var m = require('mithril')
var utils = require('../helpers/utils')
var eventHelper = require('../helpers/EventHelper')
var user = require('../models/User')
var i18n = require('../models/TranslationModel')

var model = {
    current : {},
    _backendErrorMessages : [],
    getAllBackendError : function(){
        var messages = model._backendErrorMessages
        if(messages !== [] && messages !== undefined){
            return m("div", {class: "backend-errors-container"},[
                messages.map(x => 
                    m('span', {class:"error"}, x))
            ])
        }
        return ''
    },
    _formStates : {// state -1 = initial / state 0 = error / state 1 = valid
        titlePicture : {state : -1},
        credit : {state : -1},
        description : {state : -1},
        //file : {state : -1}
    },
    checkAllInput : function(e){ //TODO
        var form = utils.findAncestor(e.target, "send-edit-form")
        //model.check.file(form.getElementsByClassName('input_edit_pictures')[0].files, form)
        model.check.titlePicture(form.getElementsByClassName('input_title_edit_picture')[0].value)
        model.check.credit(form.getElementsByClassName('input_edit_credit')[0].value)
        model.check.description(form.getElementsByClassName('input_edit_description')[0].value)
        return !model.registerHaveError()
        

    },
    registerHaveError(){
        var errorUccured = false

        if(/*model._formStates.file.state === 1 ||*/
            model._formStates.titlePicture.state === 1 ||
            model._formStates.credit.state === 1 ||
            model._formStates.description.state === 1)
        {
            errorUccured = false
        }
        if(/*model._formStates.file.state === -1 &&*/
            model._formStates.titlePicture.state === -1 &&
            model._formStates.credit.state === -1 &&
            model._formStates.description.state === -1)
        {
            errorUccured = true
            model._backendErrorMessages = []
            model._backendErrorMessages.push(i18n.cur.home_no_data_change)
        }

        return errorUccured
    },
    getStates : function (){
        return model._formStates
    },
    setFormStates : function (newFormStates){
        model._formStates = newFormStates
    },
    post : function(e, jwt){
        return new Promise(function(resolve, reject){
            if(model.checkAllInput(e)){
                // TODO  : SEND TO SERVER
                var credit = model.current.credits
                var description = model.current.description
                var title = model.current.titlePicture

                var data = {}

                if(credit !== undefined){
                    data.credit = credit
                }
                if(description !== undefined){
                    data.description = description
                }
                if(title !== undefined){
                    data.title = title
                }

                console.log(data)
                m.request({
                    method: 'POST',
                    url: "api/requests/photos/" + model.baseImage.id,
                    body: data,
                    headers : {
                        'Content-Type' : 'application/json', 
                        Authorization: "Bearer " + jwt,
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                })
                .then(function(res) {
                    var pictureIndex = 0
                    user.current.artiste.photos.map(function(item, i){
                        if(item == model.baseImage){
                            pictureIndex = i
                        }
                    })

                    if(model.current.credits !== undefined){
                        user.current.artiste.photos[pictureIndex].credits = model.current.credits
                    }
                    if(model.current.description !== undefined){
                        user.current.artiste.photos[pictureIndex].description = model.current.description
                    }
                    if(model.current.titlePicture !== undefined){
                        user.current.artiste.photos[pictureIndex].title = model.current.titlePicture
                    }
                    model._backendErrorMessages = []
                    resolve(res)
                })
                .catch(function(err) {
                    reject(err)
                })
            }else{
                reject()
            }
        })
    },
    getInputErrorMessage : function(stringEnum){
        var message = model.getInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getInputStatesIndicator : function(stringEnum){
        var state = model.getInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getInputStates : function(stringEnum){
        var o
        switch (stringEnum) {
            case 'titlePicture':
                o = model._formStates.titlePicture
            break;
            case 'credit':
                o = model._formStates.credit
            break;
            case 'description':
                o = model._formStates.description
            break;
            case 'file':
                o = model._formStates.file
            break;
            default:
                o = null
        }
        return o
    },
    clearFileInput(input){
        input.value = ""
        var element = input.parentNode.getElementsByClassName('files-name')[0]
        element.innerHTML = model.defaultImageInputFileTitle
    },
    cleanAllForm: function(){

        model._formStates = { // state -1 = initial / state 0 = error / state 1 = valid
            titlePicture : {state : -1},
            description : {state : -1},
            credit : {state : -1},
            file : {state : -1}
        }
        model.current = {}
    },
    check : {
        titlePicture : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 0, 250)){// min-mac carac
                State.titlePicture.state = 0
                State.titlePicture.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "1", MaxCharac : "255"})
            }else if(e === model.titleText){
                State.titlePicture.state = -1
            }else{
                State.titlePicture.message = null
                model.current.titlePicture = e
                State.titlePicture.state = 1
            }
            model.setFormStates(State)
        },
        credit : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 0, 255)){// min-mac carac
                State.credit.state = 0
                State.credit.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "1", MaxCharac : "255"})
            }else if(e === model.creditText){
                State.credit.state = -1
            }
            else{
                State.credit.message = null
                model.current.credits = "© " + e.replace("©", "").replace("@", "")
                State.credit.state = 1
            }
            model.setFormStates(State)
        },
        description : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 0, 255)){// min-mac carac
                State.description.state = 0
                State.description.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "30", MaxCharac : "255"})
            }else if(e.length === 0 || e === model.descriptionText){
                State.description.state = -1
            }else{
                State.description.message = null
                model.current.description = e
                State.description.state = 1
            }
            model.setFormStates(State)
        },
        validateLengthCarac: function(e, minCarac, maxCarac){
            var length = e.length
            return !(length >= minCarac && length <= maxCarac)
        },
        file: function(e, form){
            var state = model.getStates()
            var files = e
            if(files.length === 1){
                if(utils.checkLimitFileSize(files)){
                    model.clearFileInput(form.getElementsByClassName("input_edit_pictures")[0])
                    state.file.state = 0
                    state.file.message = i18n.cur.error_ic_too_big.formatUnicorn([{n : "15"}])
                }else if(utils.checkIfFormatIsAuthorize(files)){
                    model.clearFileInput(form.getElementsByClassName("input_edit_pictures")[0])
                    state.file.state = 0
                    state.file.message = i18n.cur.error_wrong_files_type.formatUnicorn({filesType : "JPG,PNG"})
                }else{
                    if(files.length === 1){
                        var element = form.getElementsByClassName('edit-picture-text')[0]
                        element.innerHTML = files[0].name
                    }
                    state.file.state = 1
                    state.file.message = null
                }
            }else if(files.length === 0){
                state.file.state = -1
            }else{
                model.clearFileInput(form.getElementsByClassName("input_edit_pictures")[0])
                state.file.state = 0
                state.file.message = i18n.cur.error_too_many_picture
            }
            model.setFormStates(state)
        }
    }
}

module.exports = model
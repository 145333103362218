let Global = {
    // HERE THE GLOBAL VAR
    refreshTokenInMinutes : 55,
}

Global.url = {
    olyrix : 'https://www.olyrix.com/', // don't forgot the '/' at the end
    prolyrix : window.location.hostname,
    assets : 'assets/prolyrix/'
}

Global.api = {
    _url : '/api/',
    youtube : "AIzaSyAy3shieJf849D0UafpaWQVBeEpQ096ioY",
    translation : '/api/translations'
}

Global.api.user = {
    disconnect : Global.api._url + "disconnect",
    show : Global.api._url + "users/", // id as parameter
    showPersonne : Global.api._url + "artiste/",
    update : Global.api._url + "users/", // id as parameter
    photo : Global.api._url + "artiste/",
}
Global.api.request = {
    photoTag : Global.api._url + "requests/photos/{id}/tags",
    videoTag : Global.api._url + "requests/videos/{id}/tags",
    photoAdd : Global.api._url + "requests/photos",
    videoAdd : Global.api._url + "requests/videos",
    photoEdit : Global.api._url + "requests/photos", // {id}
    videoEdit : Global.api._url + "requests/videos" // {id}
}
Global.api.data = {
    nation : Global.api._url + "nationalities",
    language : Global.api._url + "languages",
    oeuvre : Global.api._url + "oeuvres",
    personneTag : Global.api._url + "personnes/tags",
    oeuvreTag : Global.api._url + "oeuvres/tags",
    productionTag : Global.api._url + "productions/tags",
    repriseTag : Global.api._url + "reprises/tags",
    lieuTag : Global.api._url + "lieux/tags",
    distributionTag : Global.api._url + "distributions/tags",
}


Global.api.auth = {
    refreshToken : Global.api._url + "auth/refresh", // post
    signin : Global.api._url + "auth", // post return 
    signup : Global.api._url + "users", // post
    olyrixSignup : Global.api._url + "users/olyrix", // post
    getUserId : Global.api._url + "auth/id",
    emailAlreadyExist : Global.api._url + "auth/email"
}


module.exports = Global
var m = require('mithril')
var model = require('../models/Home')
var eventHelper = require('../helpers/EventHelper')
var updateNotif = require('../components/UpdateWidgetNotif')
var user = require('../models/User')
var multiSelect = require('../components/MultiSelect')
var utils = require ('../helpers/Utils')
var recorder = require('../components/Recorder')
var infoDiv = require('../components/InfoDiv')
var myPhotosPictures = require('../components/MyPhotosPictures')
var myVideos = require('../components/MyVideos')
var addPicturePopup = require('../components/AddPicturePopup')
var editPicturePopup = require('../components/EditPicturePopup')
var addVideoPopup = require('../components/AddVideoPopup')
var editVideoPopup = require('../components/EditVideoPopup')
var tagBuilderPicture = require('../components/TagBuilderPopup')
var tagBuilderVideo = require('../components/TagBuilderVideoPopup')
var extendLyricographie = require('../components/ExtendLyricographie')
var avatarCropper = require('../components/AvatarCropper')
var avatarCreditPopup = require('../components/AddCreditToAvatarPopup')
var popupConfirm = require('../components/SimpleConfirmPopup')
var editLyricographiePopup = require('../components/EditLyricographiePopup')
var entity = require('../models/EntityModel')
var i18n = require('../models/TranslationModel')
var auth = require('../services/Auth')


let Home = {

    // *** GLOBAL VAR *** //
    
    _avatarToSmall : false, // this displayed an error message if set to true
    _pictureToShow : 2*3, // 2 is column 3 is row, the only editable is column. For change the number of row you need to edit the css
    _currentStartIndexPictureShow : 0, // the start index of range pictures displayed
    _videoToShow : 2*3, // 2 is column 3 is row, the only editable is column else edit the css 
    _currentStartIndexVideoShow : 0, // the start index of range video displayed
    _pictureAvatarSelected : undefined, // set to type #files if the user pick a new profil picture
    _filesPictureSelected : null, // set to type #files if the user drop or add a new picture to the "my pictures" section

    // *** EVENT VAR *** //

    _showPopupAvatarCropper : false,
    _showPopupAvatarCredit : false,
    _showPopupAddPicture : false,
    _showPopupEditPicture : false,
    _showPopupAddVideo : false,
    _showPopupEditVideo : false,
    _showPopupTagBuilderPicture : false,
    _showPopupTagBuilderVideo : false,
    _showMyPicturePhotos : true,
    _showMyPictureVideos : true,
    _seeDateOfBirthCanTriggered : false,


    oncreate: function(){
        utils.scrollToTop(500, 0)
        setTimeout(function(){
            Home._seeDateOfBirthCanTriggered = false
            if(user.current.personne.see_date_of_birth == 0){
                document.getElementsByClassName('input_checkbox')[0].click()
            }
            Home._seeDateOfBirthCanTriggered = true
        }, 0)
        user.loadAndBindLyrico(auth.getJWT())
    },
    getUpdateNotif : function(){
        return eventHelper.updateWidgetNotif.get() ? m(updateNotif,{isAuto : true}) : ''
    },
    activeUpdateNotif : function(){
        eventHelper.updateWidgetNotif.set(true)
    },
    getMyInfoView : function(){
        let that = this
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step))
        let endYear = parseInt(new Date().getFullYear()) - 10
        let startYear = parseInt(new Date().getFullYear()) - 175
        var birthdate = undefined
        if(user.current.hasOwnProperty('personne')){
            birthdate = user.current.personne.birthdate !== null ? user.current.personne.birthdate.split("-") : null
        }
        return m
        ("div", {class: "box-shadow widget my-info"},[
            Home.getUpdateNotif(),
            m("div", {class:"header-home-my-info"},[
                m("h3", {class: "center-title"}, [
                    m("span", i18n.cur.home_myinfo_title),
                ]),
                /*m("div", {class:"fill-data-percentage-container"},[
                    m("div", {class:"top-section"},[
                        m("span", {class:"fill-text"}, i18n.cur.home_myinfo_percent_fill),
                        m("div", {id:"fill-myinfo-percentage"}, "82%"),
                    ]),
                    m("div", {class:"fill-progress-bar"},[
                        m("span", {
                            class:"c-progress-bar",
                            style:"width:82%"
                        }, [
                            m("span", {class:"progress-view", id:"fill-myInfo-progress-bar"}),
                        ]),
                    ]),
                ])*/
            ]),
            m("div", {class:"myinfo-form"},[
                m("div", {class:"left-section"},[
                    m("div", {class:"profil-picture-container"}, [
                        m("div", {class:"img-container box-shadow"}, [
                            m("img", {
                                alt: user.current.personne.fullname,
                                src: user.current.personne.photoIdentity,
                            }),
                            m('form', {
                                onsubmit: function(e){e.preventDefault()}
                            },[
                                m('label', {class:'hidden-label', for:'avatar_home_input_file'}, "Select your new avatar picture"),
                                m('input', {
                                    type : 'file',
                                    class : 'input_new_avatar custom_file_input',
                                    id : 'avatar_home_input_file',
                                    name : 'avatar_home_input_file',
                                    onchange: function(e) {
                                        var input = this
                                        that._pictureAvatarSelected = e.target.files[0]
                                        var _URL = window.URL || window.webkitURL
                                        var img = new Image();
                                        img.onload = function () {
                                            if(this.width >= 500 && this.height >= 500){
                                                Home.setShowPopupAvatarCropper(true)
                                                input.value = ""
                                                that._avatarToSmall = false
                                            }else{
                                                that._avatarToSmall = true
                                                setTimeout(() => {
                                                    that._avatarToSmall = false
                                                    m.redraw()
                                                }, 20000)
                                            }
                                            m.redraw()
                                        }
                                        img.src = _URL.createObjectURL(that._pictureAvatarSelected);
                                        //_avatarToSmall
                                    }
                                }),
                                m('label', {for:'input_new_avatar'},[
                                    m("span", {
                                        class:"ico-upload-container",
                                        onclick: function(e){
                                            document.getElementsByClassName("custom_file_input")[0].click()
                                        }
                                    }, [
                                        m("i", {class:"fas fa-exchange-alt"})
                                    ])
                                ]),
                            ]),
                        ]),
                        m("div", {class:"img-recommandation"}, i18n.cur.home_myinfo_avatar_required),
                        this._avatarToSmall ? m("div", {class:"img-recommandation danger-color"}, i18n.cur.error_file_size_too_small.formatUnicorn({minSize: "500 x 500"})) : null,
                    ]),
                    m("div", {class:"user-data-container"},[
                        m("div", {class:"col-50 text-center"},[
                            m("div", {class:"text-small-bold"}, i18n.cur.home_myinfo_firstname),
                            m("div", {class:"text-content"}, user.current.personne.lastname),
                        ]),
                        m("div", {class:"col-50 text-center"},[
                            m("div", {class:"text-small-bold"}, i18n.cur.home_myinfo_lastname),
                            m("div", {class:"text-content"}, user.current.personne.firstname),
                        ]),
                    ]),
                ]),

                // WITH COMPONENTS

                m('form', {
                    class:"user-data-form",
                    autocomplete : "off",
                    onsubmit : function(e){
                        e.preventDefault()
                    }
                },[
                    m("div", {class:"more-data-container"}, [
                        m(multiSelect, {
                            uniq : false,
                            research : (x, jwt) => user.nation.research(x, i18n.getList),
                            remove : (x, jwt) => user.nation.removeById(x, jwt),
                            add : (x, jwt) => user.nation.add(x, jwt),
                            all : () => user.nation.getAll(),
                            minCarac : 0,
                            inputTitle: i18n.cur.home_myinfo_nation
                            
                        }),
                        m(multiSelect, {
                            uniq : false,
                            research : (x) => user.language.research(x, i18n.getList),
                            remove : (x, jwt) => user.language.removeById(x, jwt),
                            add : (x, jwt) => user.language.add(x, jwt),
                            all : () => user.language.getAll(),
                            minCarac : 0,
                            inputTitle : i18n.cur.home_myinfo_language
                        }),
                        m('div', {class:"input-section"},[
                            m('div', {class:"title home"},[
                                m('span', i18n.cur.home_myinfo_birthday)
                            ]),
                            m('div', {class: 'input-item'},[
                                m('span', {class: 'select-input-container'} ,[
                                    m('label', {class:"hidden-label", for:"selectDay-1"}, "Select your birth day"),
                                    m('select', {name : 'selectDay-1', id: 'selectDay-1', class : 'select-birthday-day',
                                    onchange : function(e){
                                        var newPartialDate = ['','',e.target.value]
                                        model.myInfo.update.birthdate(e, newPartialDate).then(function(){
                                            e.target.parentNode.classList.add("select-saved")
                                            utils.waitBeforeElementUpdate(e.target.parentNode).then(function(){
                                                e.target.parentNode.classList.remove("select-saved")
                                            })
                                        })
                                    }},[
                                        m('option', {value : 'JJ'}, i18n.cur.home_myinfo_day),
                                        Array.from({length: 31}, function(e, i){
                                            i++
                                            if(birthdate !== null && typeof birthdate[2] !== 'undefined' && birthdate[2] == i){
                                                return m('option', {value : i, selected : 'selected'}, i)
                                            }else{
                                                return m('option', {value : i}, i)
                                            }
                                        })
                                    ]),
                                    m('span', {class:"heavy-text"}, "/"),
                                    m('label', {class:"hidden-label", for:"selectMonth-1"}, "Select your birth month"),
                                    m('select', {name : 'selectMonth-1', id: 'selectMonth-1', class : 'select-birthday-month',
                                    onchange : function(e){
                                        var newPartialDate = ['',e.target.value,'']
                                        model.myInfo.update.birthdate(e, newPartialDate).then(function(){
                                            e.target.parentNode.classList.add("select-saved")
                                            utils.waitBeforeElementUpdate(e.target.parentNode).then(function(){
                                                e.target.parentNode.classList.remove("select-saved")
                                            })
                                        })
                                    }},[
                                        m('option', {value : 'MM'}, i18n.cur.home_myinfo_month),
                                        Array.from({length: 12}, function(e, i){
                                            i++
                                            if(birthdate !== null && typeof birthdate[1] !== 'undefined' && birthdate[1] == i){
                                                return m('option', {value : i, selected : 'selected'}, i)
                                            }else{
                                                return m('option', {value : i}, i)
                                            }
                                        })
                                    ]),
                                    m('span', {class:"heavy-text"}, "/"),
                                    m('label', {class:"hidden-label", for:"selectYear-1"}, "Select your birth year"),
                                    m('select', {name : 'selectYear-1', id: 'selectYear-1', class : 'select-birthday-year',
                                    onchange : function(e){
                                        var newPartialDate = [e.target.value,'','']
                                        model.myInfo.update.birthdate(e, newPartialDate).then(function(){
                                            e.target.parentNode.classList.add("select-saved")
                                            utils.waitBeforeElementUpdate(e.target.parentNode).then(function(){
                                                e.target.parentNode.classList.remove("select-saved")
                                            })
                                        })
                                    }},[
                                        m('option', {value : 'AAAA'}, i18n.cur.home_myinfo_year),
                                        range(startYear, endYear, 1).reverse().map(function(i){
                                            i++
                                            if(birthdate !== null && typeof birthdate[0] !== 'undefined' && birthdate[0] == i){
                                                return m('option', {value : i, selected : 'selected'}, i)
                                            }else{
                                                return m('option', {value : i}, i)
                                            }
                                        })
                                    ]),
                                ]),
                                /*user.current.personne.see_date_of_birth ? m("span", 
                                {class:"ico-right-container basic-scale"},[
                                    m("i", {class:"fas fa-eye-slash"})
                                ]) : ''*/
                            ])
                        ]),
                        m('div', {class:"input-section"},[
                            m('div', {class:'cgu-checkbox-container'},[
                                m('label', {for:'checkbox', class: 'checkbox-container'}, [
                                    m('input', {
                                        type: 'checkbox',
                                        name:'checkbox',
                                        class : 'input_checkbox',
                                        onchange : function(e){
                                            if(Home._seeDateOfBirthCanTriggered){
                                                model.myInfo.update.hideMyBirthdate(e.target.checked)
                                            }
                                        }
                                    }),
                                    m('span', {
                                        class : 'checkmark',
                                    }),
                                    m('span', {class: 'text-checkbox'},[
                                        m('span', {class: 'small'}, user.current.personne.see_date_of_birth ? i18n.cur.home_myinfo_hide_birth : i18n.cur.home_myinfo_hide_birth),
                                    ]),
                                    //model.getSignupInputErrorMessage('checkbox')
                                ]),
                            ]),
                        ]),
                        m(multiSelect, {
                            uniq : true,
                            research : (x) => user.range.research(x, i18n.getList),
                            remove : (x, jwt) => user.range.remove(jwt),
                            add : (x, jwt) => user.range.set(x, jwt),
                            all : () => user.range.get(),
                            minCarac : 0,
                            inputTitle : i18n.cur.home_myinfo_range
                        }),
                        m(recorder)
                    ])
                ])

            ]),
            model.myInfo.getAllBackendError(),
        ])
    },
    closeAllExtendLyricographie : function(){
        Object.entries(user.current.artiste.experiences).map(function(item){
            var i = 0
            Object.entries(item[1]).map(function(prod){
                user.current.artiste.experiences[item[0]][i].open = false
                i++
            })
        })
    },
    getButtonIfPictureIsSelect : function(){
        if(!this.getAPictureIsSelected()){
            return m("div", {class:"button-container"}, [
                m("button", {
                    class:"button-with-ico brown",
                    onclick: function(){
                        Home.setShowPopupAddPicture(true)
                    }
                }, [
                    m('span', {class: 'left-ico'},[
                        m('i', {class: 'fas fa-plus'})
                    ]),
                    m("span", {class:"text-button"}, i18n.cur.home_mypic_picture_add)
                ])
            ])
        }else{
            return m("div", {class:"button-container"}, [
                m("button", {
                    class:"button-with-ico brown",
                    onclick: function(){
                        Home.setShowPopupEditPicture(true)
                    }
                }, [
                    m('span', {class: 'left-ico'},[
                        m('i', {class: 'fas fa-edit'})
                    ]),
                    m("span", {class:"text-button"}, i18n.cur.home_picture_edit)
                ])
            ])
        }
    },
    getMyVideoView : function(){
        var that = this
        if(user.current.artiste.videos.length > 0){
            var videos = user.current.artiste.videos
            var minSlice = this._currentStartIndexVideoShow
            var maxSlice = this._currentStartIndexVideoShow + this._videoToShow
            return m("div", {class: "box-shadow widget my video-section"},[
                Home.getUpdateNotif(),
                m("div", {class:"header-home my-pictures"}, [
                    m("h3", {class: "center-title"}, [
                        m("div", i18n.cur.home_mymov_title),
                    ]),
                    this.getButtonIfVideoIsSelect()
                ]),
                m("div", {class:"content public-info"},[
                    m("div", {class:"left-section"},[
                        this.getShowTheVideos() ? m(myVideos) : '',
                        this.getNavMyVideos(),
                    ]),
                    m("div", {class:"right-section"},[
                        this.getBottomMyVideos()
                    ])
                ]),
            ])
        }else{
            return m("div", {class: "box-shadow widget extra-p video-section my"},[
                Home.getUpdateNotif(),
                m("h3", {class: "center-title"}, [
                    m("span", i18n.cur.home_empty_video_title),
                ]),
                m("div", {class:"content"},[
                    m("div", {class:"empty-section"},[
                        m("div", {class:"left-section"}, [
                            m("div", {class:"button-container"}, [
                                m("button", {
                                    class:"button-with-ico brown",
                                    onclick: function(){
                                        Home.setShowPopupAddVideo(true)
                                    }
                                }, [
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-film'})
                                    ]),
                                    m("span", {class:"text-button"}, i18n.cur.home_empty_video_button)
                                ])
                            ])
                        ]),
                        m("div", {class:"right-section"}, [
                            m("span", {class:"right-container"},[
                                m(infoDiv, {text : i18n.cur.home_empty_video_info})
                            ])
                        ]),
                    ])
                ]),
            ])
        }
    },
    getButtonIfVideoIsSelect : function(){
        if(this.getAVideoIsSelected()){
            return m("div", {class:"button-container"}, [
                m("button", {
                    class:"button-with-ico brown",
                    onclick: function(){
                        Home.setShowPopupEditVideo(true)
                    }
                }, [
                    m('span', {class: 'left-ico'},[
                        m('i', {class: 'fas fa-edit'})
                    ]),
                    m("span", {class:"text-button"}, i18n.cur.home_video_edit)
                ])
            ])
        }else{
            return m("div", {class:"button-container"}, [
                m("button", {
                    class:"button-with-ico brown",
                    onclick: function(){
                        Home.setShowPopupAddVideo(true)
                    }
                }, [
                    m('span', {class: 'left-ico'},[
                        m('i', {class: 'fas fa-plus'})
                    ]),
                    m("span", {class:"text-button"}, i18n.cur.home_mymov_video_add)
                ])
            ])
        }
    },
    getBottomMyVideos : function(){
        var that = this
        if(this.getAVideoIsSelected()){
            return m("div", [
                m("div", {class:"myinfo-form"},[
                    m('form', {
                        class:"user-data-form",
                        autocomplete : "off",
                        onsubmit : function(e){
                            e.preventDefault()
                        }
                    },[
                        m("div", {class:"more-data-container"}, [
                            m(multiSelect, {
                                uniq : false,
                                minCarac : 2,
                                research : (x) => entity.personne.researchByName(x),
                                remove : (x, jwt) => user.tag.video.personne.remove(x, jwt),
                                add : (x, jwt) => user.tag.video.personne.add(x, jwt),
                                all : () => user.tag.video.personne.get(),
                                inputTitle: i18n.cur.home_mypic_tags_personnes
                                
                            }),
                            m(multiSelect, {
                                uniq : false,
                                minCarac : 2,
                                research : (x) => entity.oeuvre.tags.researchByName(x),
                                remove : (x, jwt) => user.tag.video.oeuvre.remove(x, jwt),
                                add : (x, jwt) => user.tag.video.oeuvre.add(x, jwt),
                                all : () => user.tag.video.oeuvre.get(),
                                inputTitle: i18n.cur.home_mypic_tags_oeuvres
                                
                            }),
                            m(multiSelect, {
                                uniq : false,
                                minCarac : 2,
                                research : (x) => entity.location.tags.researchByName(x),
                                remove : (x, jwt) => user.tag.video.lieu.remove(x, jwt),
                                add : (x, jwt) => user.tag.video.lieu.add(x, jwt),
                                all : () => user.tag.video.lieu.get(),
                                inputTitle: i18n.cur.home_mypic_tags_lieux
                            })
                        ])
                    ])
                ]),
                m("div", {class:"button-container bottom-margin"}, [
                    m("button", {
                        class:"button-with-ico brown text-reducer bottom-margin",
                        onclick: function(){
                            Home.setShowPopupTagBuilderVideo(true)
                        }
                    }, [
                        m("span", {class:"text-button"}, i18n.cur.home_mypic_vacuum_button)
                    ])
                ]),
            ])
        }else{
            return m("div", [
                m("div", {class:"no-element-selected"}, [
                    m("div", {class:"infoDiv-container"}, [
                        m(infoDiv, {text : i18n.cur.home_mymov_video_edit})
                    ])
                ])
            ])
        }
    },
    getNavMyVideos : function(){
        var that = this
        var canLeft = (this._currentStartIndexVideoShow +1 - this._videoToShow) > 0
        var canRight = (this._currentStartIndexVideoShow -1) < user.current.artiste.videos.length - this._videoToShow -1
        var totalPage = Math.ceil(user.current.artiste.videos.length/this._videoToShow)
        var currentIndex = Math.ceil((this._currentStartIndexVideoShow+1)/this._videoToShow)
        return m("div", {class:"my-nav-container"}, [
            m("span", {class:"left-part"},[
                m("span", {class:canLeft ? "ico-container" : "ico-container hide-by-opacity"}, [
                    m("i", {
                        class: "fas fa-arrow-left",
                        onclick: function(e){
                            if(canLeft){
                                that.setShowTheVideos(false)
                                setTimeout(function(){
                                    that._currentStartIndexVideoShow = that._currentStartIndexVideoShow - that._videoToShow
                                    myVideos.setIndexToShow(that._currentStartIndexVideoShow)
                                    that.setShowTheVideos(true)
                                    m.redraw()
                                }, 100)
                            }
                        }
                    })
                ]),
            ]),
            m("span", {class:"middle-part"},[
                m("span", currentIndex + " / " + totalPage)
            ]),
            m("span", {class:"right-part"},[
                m("span", {class:canRight ? "ico-container" : "ico-container hide-by-opacity"}, [
                    m("i", {
                        class: "fas fa-arrow-right",
                        onclick: function(e){
                            if(canRight){
                                that._currentStartIndexVideoShow = that._currentStartIndexVideoShow + that._videoToShow
                                myVideos.setIndexToShow(that._currentStartIndexVideoShow)
                                setTimeout(function(){
                                    m.redraw()
                                }, 100)
                            }
                        }
                    })
                ]),
            ]),
        ])
    },
    getMyPictureView : function(){
        var that = this
        if(user.current.artiste.photos.length > 0){
            
            var photos = user.current.artiste.photos
            var minSlice = this._currentStartIndexPictureShow
            var maxSlice = this._currentStartIndexPictureShow + this._pictureToShow
            return m("div", {class: "box-shadow widget my"},[
                Home.getUpdateNotif(),
                m("div", {class:"header-home my-pictures"}, [
                    m("h3", {class: "center-title"}, [
                        m("div", i18n.cur.home_mypic_title),
                    ]),
                    this.getButtonIfPictureIsSelect()
                ]),
                m("div", {class:"content public-info"},[
                    m("div", {class:"left-section"}, [
                        this.getShowThePictures() ? m(myPhotosPictures) : '',
                        this.getNavMyPhotosPictures(),
                    ]),
                    m("div", {class:"right-section"},[
                        this.getBottomMyPictures()
                    ])
                ]),
            ])
        }else{
            return m("div", {class: "box-shadow widget extra-p my"},[
                Home.getUpdateNotif(),
                m("h3", {class: "center-title"}, [
                    m("span", i18n.cur.home_empty_picture_title),
                ]),
                m("div", {class:"content"},[
                    m("div", {class:"empty-section"},[
                        m("div", {class:"left-section"}, [
                            m("div", {
                                class:"add-file-picture",
                                ondragover : e => e.preventDefault(),
                                ondragenter : e => e.preventDefault(),
                                ondrop: function(event){
                                    event.preventDefault()
                                    Home._filesPictureSelected = event.dataTransfer.files
                                    Home.setShowPopupAddPicture(true)
                                },
                                onclick: function(){
                                    Home.setShowPopupAddPicture(true)
                                }
                            },[
                                m("span", {class:"ico-container"},[
                                    m("i", {class:"fas fa-plus"})
                                ])
                            ]),
                        ]),
                        m("div", {class:"right-section"},[
                            m("span", {class:"right-container"},[
                                m(infoDiv, {text : i18n.cur.home_empty_picture_info})
                            ])
                        ])
                    ])
                ]),
            ])
        }
        
    },
    getBottomMyPictures : function(){
        var that = this
        if(this.getAPictureIsSelected()){
            return m("div", [
                m("div", {class:"myinfo-form"},[
                    m('form', {
                        class:"user-data-form",
                        autocomplete : "off",
                        onsubmit : function(e){
                            e.preventDefault()
                        }
                    },[
                        m("div", {class:"more-data-container"}, [
                            m(multiSelect, {
                                uniq : false,
                                minCarac : 2,
                                research : (x) => entity.personne.researchByName(x),
                                remove : (x, jwt) => user.tag.picture.personne.remove(x, jwt),
                                add : (x, jwt) => user.tag.picture.personne.add(x, jwt),
                                all : () => user.tag.picture.personne.get(),
                                inputTitle: i18n.cur.home_mypic_tags_personnes
                            }),
                            m(multiSelect, {
                                uniq : false,
                                minCarac : 2,
                                research : (x) => entity.oeuvre.tags.researchByName(x),
                                remove : (x, jwt) => user.tag.picture.oeuvre.remove(x, jwt),
                                add : (x, jwt) => user.tag.picture.oeuvre.add(x, jwt),
                                all : () => user.tag.picture.oeuvre.get(),
                                inputTitle: i18n.cur.home_mypic_tags_oeuvres
                                
                            }),
                            m(multiSelect, {
                                uniq : false,
                                minCarac : 2,
                                research : (x) => entity.location.tags.researchByName(x),
                                remove : (x, jwt) => user.tag.picture.lieu.remove(x, jwt),
                                add : (x, jwt) => user.tag.picture.lieu.add(x, jwt),
                                all : () => user.tag.picture.lieu.get(),
                                inputTitle: i18n.cur.home_mypic_tags_lieux
                            })
                        ])
                    ])
                ]),
                m("div", {class:"button-container bottom-margin"}, [
                    m("button", {
                        class:"button-with-ico brown text-reducer bottom-margin",
                        onclick: function(){
                            Home.setShowPopupTagBuilderPicture(true)
                        }
                    }, [
                        m("span", {class:"text-button"}, i18n.cur.home_mypic_vacuum_button)
                    ])
                ]),
            ])
        }else{
            return m("div", [
                m("div", {class:"no-element-selected"}, [
                    m("div", {class:"infoDiv-container"}, [
                        m(infoDiv, {text : i18n.cur.home_mypic_info_click_picture})
                    ])
                ])
            ])
        }
    },
    getNavMyPhotosPictures : function(){
        var that = this
        var canLeft = (this._currentStartIndexPictureShow +1 - this._pictureToShow) > 0
        var canRight = (this._currentStartIndexPictureShow -1) < user.current.artiste.photos.length - this._pictureToShow -1
        var totalPage = Math.ceil(user.current.artiste.photos.length/this._pictureToShow)
        var currentIndex = Math.ceil((this._currentStartIndexPictureShow+1)/this._pictureToShow)

        return m("div", {class:"my-nav-container"}, [
            m("span", {class:"left-part"},[
                m("span", {
                    class:canLeft ? "ico-container" : "ico-container hide-by-opacity",
                    onclick: function(e){
                        if(canLeft){

                            that._currentStartIndexPictureShow = that._currentStartIndexPictureShow - that._pictureToShow
                            myPhotosPictures.setIndexToShow(that._currentStartIndexPictureShow) 
                            m.redraw()
                        }
                    }
            }, [
                    m("i", {class: "fas fa-arrow-left"})
                ]),
            ]),
            m("span", {class:"middle-part"},[
                m("span", currentIndex + "/" + totalPage)
            ]),
            m("span", {class:"right-part"},[
                m("span", {
                    class:canRight ? "ico-container" : "ico-container hide-by-opacity",
                    onclick: function(e){
                        if(canRight){
                            that._currentStartIndexPictureShow = that._currentStartIndexPictureShow + that._pictureToShow
                            myPhotosPictures.setIndexToShow(that._currentStartIndexPictureShow)
                            m.redraw()
                        }
                    }
                }, [
                    m("i", {class: "fas fa-arrow-right"})
                ]),
            ]),
        ])
    },
    setShowThePictures(x){
        this._showMyPicturePhotos = x
    },
    getShowThePictures(){
        return this._showMyPicturePhotos
    },
    setShowTheVideos(x){
        this._showMyPictureVideos = x
    },
    getShowTheVideos(){
        return this._showMyPictureVideos
    },
    getAVideoIsSelected(){
        var x = false
        user.current.artiste.videos.map(function(item){
            if(item.selected === true){
                x = true
            }
        })
        return x
    },
    getAPictureIsSelected(){
        var x = false
        user.current.artiste.photos.map(function(item){
            if(item.selected === true){
                x = true
            }
        })
        return x
    },
    getLimitPictureToShow(i){
        return i > this._currentStartIndexPictureShow-1 && i < this._currentStartIndexPictureShow+this._pictureToShow
    },
    getBgOnSelectedPictures(selected){
        if(selected){
            return m("span", {class:"container-on-select"},[
                m("span", {class:"select-ico-container"},[
                    m("i", {class:"fas fa-check"})
                ]),
                m("span", {class:"black-bg-100"}),
            ])
        }
    },
    getLyricographie : function(){
        var that = this
        return m
        ("div", {class: "box-shadow widget no-side-padding lyricographie"},[
            Home.getUpdateNotif(),
            m("div", {class: "center-title"}, [
                m("h3", "Ma lyricographie"),
                m("div", {class:"add-role-button"}, [
                    m("div", {class:"button-container"}, [
                        m("button", {
                            class:"button-with-ico brown",
                            onclick: function(){
                                that.setShowPopupEditLyricographie(true)
                                //that._repriseToEdit = reprise.id
                            }
                        }, [
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-bookmark'})
                            ]),
                            m("span", {class:"text-button"}, "Ajouter un rôle")
                        ])
                    ])
                ]),
            ]),
            m("div", {class:"my-lyricographie"}, [
                Object.entries(user.current.artiste.experiences).map(item => {
                    return m("div", {class:"job-section"}, [
                        m("h4", {class:"title-job"}, item[0]),
                        item[1].map(prod => {
                            return [m("div", {
                                class:"item-prod",
                                onclick: function(){
                                    that.toggleLyricoExtend(prod.bidingId, prod.open !== true)
                                }
                            }, [
                                m("div", {class:"line"}, [
                                    m("span", {class:"light-text middle"}, [
                                        m("span", {class:"small-text"}, i18n.cur.home_lyrico_compo)
                                    ]),
                                    m("span", {class:"light-text right"}, [
                                        m("span", {class:"bold-text"}, utils.removeHtml(prod.compositeur))
                                    ])
                                ]),
                                m("div", {class:"line"}, [
                                    m("span", {class:"light-text middle"}, [
                                        m("span", {class:"small-text"}, i18n.cur.home_lyrico_oeuvre)
                                    ]),
                                    m("span", {class:"light-text right"}, [
                                        m("span", {class:"bold-text"}, prod.oeuvre)
                                    ])
                                ]),
                                prod.personnages === undefined ? null : m("div", {class:"line"}, [
                                    m("span", {class:"light-text middle"}, [
                                        m("span", {class:"small-text"}, i18n.cur.home_lyrico_perso)
                                    ]),
                                    m("span", {class:"light-text right"}, [
                                        m("span", {class:"bold-text"}, prod.personnages)
                                    ])
                                ]),
                                m("div", {
                                    class:"button-extend",
                                },[
                                    m("div", {class:"ico-content"},[
                                        m("i", {class: prod.open !== true ? "fas fa-chevron-right" : "fas fa-chevron-right anim-rotate90"})
                                    ])
                                ])
                            ]),
                            prod.open === true ? m(extendLyricographie, {prod : prod, job : item[0]}) : null
                            ]
                        })
                    ])
                })
            ])
        ])
    },
    toggleLyricoExtend(bidingId, closeOtherExtend){
        closeOtherExtend ? Home.closeAllExtendLyricographie() : null
        Object.entries(user.current.artiste.experiences).map(function(items){
            var i = 0
            Object.entries(items[1]).map(function(){
                if(bidingId === user.current.artiste.experiences[items[0]][i].bidingId){
                    user.current.artiste.experiences[items[0]][i].open = !user.current.artiste.experiences[items[0]][i].open
                }
                i++
            })
        })
    },
    view: function() {
        return m("div", {class: "content home-display-d"},
        [
            m("div", {class:"item-d-h"},[
                Home.getMyInfoView(),
            ]),
            m("div", {class:"item-d-h"},[
                Home.getMyPictureView(),
            ]),
            m("div", {class:"item-d-h"},[
                Home.getMyVideoView(),
            ]),
            m("div", {class:"item-d-h"},[
                Home.getLyricographie(),
            ]),


            // POPUP ON FIXED 
            this.getShowPopupAddPicture() ? m(addPicturePopup, {isShow : (x) => this.setShowPopupAddPicture(x), fileSelected : Home._filesPictureSelected}) : null,
            this.getShowPopupEditPicture() ? m(editPicturePopup, {isShow : (x) => this.setShowPopupEditPicture(x), image : user.getPictureSelected()}) : null,
            this.getShowPopupAddVideo() ? m(addVideoPopup, {isShow : (x) => this.setShowPopupAddVideo(x), fileSelected : Home._filesPictureSelected}) : null,
            this.getShowPopupEditVideo() ? m(editVideoPopup, {isShow : (x) => this.setShowPopupEditVideo(x), image : user.getVideoSelected()}) : null,
            this.getShowPopupTagBuilderPicture() ? m(tagBuilderPicture, {isShow : (x) => this.setShowPopupTagBuilderPicture(x), image : user.getPictureSelected()}) : null,
            this.getShowPopupTagBuilderVideo() ? m(tagBuilderVideo, {isShow : (x) => this.setShowPopupTagBuilderVideo(x), image : Home._filesPictureSelected}) : null,
            this.getShowPopupEditLyricographie() ? m(editLyricographiePopup, {isShow : x => Home.setShowPopupEditLyricographie(x), repriseId : -1}) : null,
            this.getShowPopupAvatarCredit() ? m(avatarCreditPopup, {
                isShow : (x, blob) => this.setShowPopupAvatarCredit(x, blob),
                blob : Home._avatarBlob
            }) : null,
            this.getShowPopupAvatarCropper() ? m(avatarCropper, {
                isShow : (x) => this.setShowPopupAvatarCropper(x), 
                image : this._pictureAvatarSelected,
                showCreditPopup : (x, blob) => this.setShowPopupAvatarCredit(x, blob), 
            }) : null,
            eventHelper.removePrononciationPopup.get() ? m(popupConfirm, 
                {
                    acceptAction : () => recorder.trashRecording(), 
                    exitPopup : () => eventHelper.removePrononciationPopup.set(false),
                    title : i18n.cur.home_myinfo_pronun_desc,
                    confirmText : i18n.cur.home_myinfo_pronun_remove,
                    backText : i18n.cur.home_myinfo_pronun_keep
                }) : '',
        ])
    },
    setShowPopupEditLyricographie : function(x){
        this._showPopupEditLyricographie = x
    },
    getShowPopupEditLyricographie : function(){
        return this._showPopupEditLyricographie
    },
    getDayOption : function(){
        var fakeArray = new Array(31)
        return fakeArray.map(function(i){
            return m('option', {value : i}, i)
        })
    },
    setShowPopupAddVideo : function(x){
        !x ? Home._linkVideoSelected = null : null,
        Home._showPopupAddVideo = x
    },
    getShowPopupAddVideo : function(){
        return this._showPopupAddVideo
    },
    setShowPopupEditVideo : function(x){
        Home._showPopupEditVideo = x
    },
    getShowPopupEditVideo : function(){
        return this._showPopupEditVideo
    },
    setShowPopupAddPicture : function(x){
        !x ? Home._filesPictureSelected = null : null,
        Home._showPopupAddPicture = x
    },
    getShowPopupAddPicture : function(){
        return this._showPopupAddPicture
    },
    setShowPopupEditPicture : function(x){
        Home._showPopupEditPicture = x
    },
    getShowPopupEditPicture : function(){
        return this._showPopupEditPicture
    },
    setShowPopupTagBuilderPicture : function(x){
        Home._showPopupTagBuilderPicture = x
    },
    getShowPopupTagBuilderPicture : function(){
        return this._showPopupTagBuilderPicture
    },
    setShowPopupTagBuilderVideo : function(x){
        Home._showPopupTagBuilderVideo = x
    },
    getShowPopupTagBuilderVideo : function(){
        return this._showPopupTagBuilderVideo
    },
    setShowPopupAvatarCropper : function(x){
        /*if(x === false){
            this._pictureAvatarSelected = undefined
            document.getElementById("avatar_home_input_file").value = ""
        }*/
        if(x === true){
            utils.hideScrollBar()
        }else{
            utils.showScrollBar()
        }
        Home._showPopupAvatarCropper = x
    },
    getShowPopupAvatarCropper : function(){
        return Home._showPopupAvatarCropper
    },
    setShowPopupAvatarCredit : function(x, blob = null){
        if(x === true){
            Home._avatarBlob = blob
            utils.hideScrollBar()
        }else{
            utils.showScrollBar()
        }
        Home._showPopupAvatarCredit = x
        m.redraw()
    },
    getShowPopupAvatarCredit : function(){
        return Home._showPopupAvatarCredit
    },

}

module.exports = Home

var m = require('mithril')
var global = require('../Global')
var i18n = require('../models/TranslationModel')

var Footer = {
    onbeforeremove : function(){

    },
    view: function(ctrl) {
        return m('footer', {class: "flex-footer"},[
            m('div', {class:'container-plain-t'},[
                m('div', {class:'top-header'},[
                    m('h2', 'PRÔlyrix'),
                    m('div', {class: 'footer-nav'}, [
                        m('ul', [
                            m('li', {class:'col-50'},[
                                m("a", {class:'item-nav', href:"https://www.olyrix.com/pages/a-propos-olyrix", target:"_blank"}, 
                                    m('div', {class: "item-nav"}, i18n.cur.footer_about)
                                ),
                            ]),
                            m('li', {class:'col-50'},[
                                m(m.route.Link, {class:'item-nav', href:"/therms-of-service"}, 
                                    m('div', {class: "item-nav"}, i18n.cur.footer_terms)
                                )
                            ]),
                            m('li', {class:'col-50'},[
                                m(m.route.Link, {class:'item-nav', href:"/legal-notice"}, 
                                    m('div', {class: "item-nav"}, i18n.cur.footer_legal_notice)
                                )
                            ]),
                            m('li', {class:'col-50'},[
                                m(m.route.Link, {class:'item-nav', href:"/contact"}, 
                                    m('div', {class: "item-nav"}, i18n.cur.footer_contact)
                                )
                            ])
                        ])
                    ])
                ]),
            ]),
            m('div', {class:'bottom-header'},[
                m('div', {class:'container-plain-t'},[
                    m('div', {class:'all-rights-reserved item-nav'}, [
                        m('i', {class: 'far fa-copyright'}),
                        m('a', {class: 'olyrix-link item-nav', href: global.url.olyrix}, 'Ôlyrix'),
                        m('span', "-"),
                        m('span', i18n.cur.footer_copyright)
                    ])
                ])
            ])
        ])
    }
}

module.exports = Footer

var m = require('mithril')
var Utils = require('../helpers/Utils')
var Global = require('../Global')
var model = require('../models/LostPassword')
var i18n = require('../models/TranslationModel')

var LostPassword = {
    view : function(){
        return [
            m("div", {class:"flex-center-container"}, [
                m("div", {class:"title-page m-d-none"}, [
                    m("div", {class:"title-container"}, [
                        m("div", {class:"main-title"}, 
                            m("h1", i18n.cur.lostpwd_title)
                        ),
                        m("div", {class:"second-title"}, 
                            m("h2", i18n.cur.lostpwd_sub_title)
                        )
                    ])
                ]),
            ]),
            m("div", {class: "content lost-password-view"},[
                m("div", {class: "box-shadow widget"},[
                    m("h3", {class: "center-title"}, [
                        m("span", i18n.cur.lostpwd_w_title)
                    ]),
                    m("div", {class:"description-lost-password"},[
                        m("p", i18n.cur.lostpwd_desc)
                    ]),
                    m("div", {class:"lost-password-form"},[
                        m('form', { onsubmit: model.post},[
                            // EMAIL

                            m('div', {class:"input-section", name:"lost-password-form"},[
                                m('div', {class:"title"},[
                                    m('span', i18n.cur.lostpwd_in_email)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-at'})
                                    ]),
                                    m('input', {
                                        type : 'text',
                                        placeholder : 'claude.debussy@gmail.com',
                                        autocomplete : 'email',
                                        class : 'input_email',
                                        name : 'email',
                                        onfocusout: function(e) {
                                            model.check.email(e.target.value)
                                        }
                                    }),
                                    model.getInputStatesIndicator('email')
                                ]),
                                model.getInputErrorMessage('email')
                            ]),

                            // SUBMIT BUTTON

                            m('div', {class:"input-section submit-section"}, [
                                m('div', {class:"submit-button right"},[
                                    m('input', {
                                        id: 'register-input',
                                        type: 'submit',
                                        value: i18n.cur.lostpwd_submit,
                                        onclick: function(e){
                                            e.preventDefault()
                                            model.submit(e)
                                        }
                                    })
                                ])
                            ])
                        ])
                    ]),
                    model.getBackendResponse()
                ])
        ])]
    }
}

module.exports = LostPassword
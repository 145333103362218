var m = require('mithril')
var utils = require('../helpers/Utils')
var Auth = require('../services/Auth')
var i18n = require('../models/TranslationModel')
var user = require('../models/User')


var model = {
    _currentCode : '33',
    _quickOlyrixConnect : false,
    current : {},
    currentSignin : {},
    backendSuccessMessages : [],
    backendErrorsMessages : [],
    getBackend: function(){
        var SuccessMessages = model.backendSuccessMessages
        var ErrorsMessages = model.backendErrorsMessages
        return model.getBackendResponse(SuccessMessages,ErrorsMessages)
    },
    getBackendResponse : function(SuccessMessages, ErrorsMessages){
        var successMessageExist = Boolean(typeof SuccessMessages !== 'undefined' && SuccessMessages.length > 0)
        var errorsMessageExist = Boolean(typeof ErrorsMessages !== 'undefined' && ErrorsMessages.length > 0)
        var successView;
        var errorsView;
        if(successMessageExist){
            successView = model.getSuccessMessagesView(SuccessMessages)
        }
        if(errorsMessageExist){
            errorsView = model.getErrorsMessagesView(ErrorsMessages)
        }
        if(successMessageExist || errorsMessageExist){
            return m("div", {class:"response-message"}, [
                successMessageExist ? successView : '',
                errorsMessageExist ? errorsView : ''
            ])
        }else{
            return null
        }
    },
    setBackendSuccessMessages : function(messages){
        model.backendErrorsMessages = []
        model.backendSuccessMessages = messages
    },
    setBackendErrorsMessages : function(messages){
        model.backendSuccessMessages = []
        model.backendErrorsMessages = messages
        utils.scrollToTop(500)
    },
    getSuccessMessagesView(messages){
        return m('div', [
            messages.map(function(x){
                return m("span", {class:"response-text success"}, x)
            })
        ])
    },
    getErrorsMessagesView(messages){
        return m('div', [
            messages.map(function(x){
                return m("span", {class:"response-text error"}, x)
            })
        ])
    },
    getAllSignupBackendError : function(){
        var messages = model._backendErrorMessages
        if(messages !== [] && messages !== undefined){
            return m("div", {class: "backend-errors-container"},[
                messages.map(x => m('span', {class: "error"}, x))
            ])
        }
        return ''
    },
    submitSignup : function(){
        return new Promise(function(resolve, reject){
            if(!model.getQuickOlyrixConnect()){
                if(model.checkAllRegisterInput()){
                    utils.getRecaptcha().then(function(token){ //here we check the token
                        model.current.token_recaptcha = token
                        model.postSignup().then((res) => resolve(res))
                        .catch((err) => {
                            reject(err)
                        })
                    })
                }
            }else{
                if(model.checkAllOlyrixRegisterInput()){
                    utils.getRecaptcha().then(function(token){ //here we check the token
                        model.current.token_recaptcha = token
                        model.postOlyrixSignup()
                        .then((res) => resolve(res))
                        .catch((err) => reject(err))
                    })
                }
            }
        })
    },
    submitSignin : function(){
        return new Promise(function(resolve, reject){
            if(model.checkAllSigninInput()){
                model.postSignin().then(function(res){
                    resolve(res)
                }).catch((err) => reject(err))
            }else{
                reject()
            }
        })
        
    },
    _formSigninStates : {// state -1 = initial / state 0 = error / state 1 = valid
        email : {state : -1},
        password : {state : -1}
    },
    _formSignupStates : {// state -1 = initial / state 0 = error / state 1 = valid
        firstName : {state : -1},
        lastName : {state : -1},
        email : {state : -1},
        phone_number : {state : -1},
        password : {state : -1},
        password_confirmation : {state : -1},
        checkbox : {state : -1}
    },
    checkAllRegisterInput : function(){
        var form = document.getElementsByClassName('signup-form')[0]

        model.check.lastName(form.getElementsByClassName('input_lastname')[0].value)
        model.check.firstName(form.getElementsByClassName('input_firstname')[0].value)
        model.check.phone_number(form.getElementsByClassName('input-phone')[0].value)
        model.check.email(form.getElementsByClassName('input_email')[0].value)
        model.check.password(form.getElementsByClassName('input_password')[0].value)
        model.check.password_confirmation(form.getElementsByClassName('input_password_confirmation')[0].value)
        model.check.checkbox(form.getElementsByClassName('input_checkbox_cgu')[0].checked)

        return !model.registerHaveError()
        

    },
    checkAllOlyrixRegisterInput : function(){
        var form = document.getElementsByClassName('signup-form')[0]

        model.check.phone_number(form.getElementsByClassName('input-phone')[0].value)
        model.check.email(form.getElementsByClassName('input_email')[0].value)
        model.check.password(form.getElementsByClassName('input_password')[0].value)
        model.check.checkbox(form.getElementsByClassName('input_checkbox_cgu')[0].checked)

        return !model.olyrixRegisterHaveError()
        

    },
    checkAllSigninInput : function(){
        var form = document.getElementsByClassName('signin-form')[0]

        model.check.emailSignin(form.getElementsByClassName('input_email')[0].value)
        model.check.passwordSignin(form.getElementsByClassName('input_password')[0].value)

        return !model.signinHaveError()
    },
    signinHaveError(){
        var errorUccured = true

        if(model._formSigninStates.email.state === 1
        && model._formSigninStates.password.state === 1)
        {
            errorUccured = false
        }

        return errorUccured
    },
    registerHaveError(){
        var errorUccured = true

        if(model._formSignupStates.lastName.state === 1
        && model._formSignupStates.firstName.state === 1
        && model._formSignupStates.phone_number.state === 1
        && model._formSignupStates.email.state === 1
        && model._formSignupStates.password.state === 1
        && model._formSignupStates.password_confirmation.state === 1
        && model._formSignupStates.checkbox.state === 1)
        {
            errorUccured = false
        }

        return errorUccured
    },
    olyrixRegisterHaveError(){
        var errorUccured = true

        if(model._formSignupStates.phone_number.state === 1
        && model._formSignupStates.email.state === 1
        && model._formSignupStates.password.state === 1
        && model._formSignupStates.checkbox.state === 1)
        {
            errorUccured = false
        }

        return errorUccured
    },
    getCurrentCode : function (){
        return this._currentCode
    },
    setCurrentCode : function (dc){
        this._currentCode = dc
    },
    getSignupStates : function (){
        return model._formSignupStates
    },
    setFormSignupStates : function (newFormSignupStates){
        model._formSignupStates = newFormSignupStates
    },
    getSigninStates : function (){
        return model._formSigninStates
    },
    setFormSigninStates : function (newFormSigninStates){
        model._formSigninStates = newFormSigninStates
    },
    postSignin : function(connectUser = true){
        return new Promise(
            function(resolve, reject){
                utils.getRecaptcha().then(function(token){
                    model.currentSignin.token_recaptcha = token
                    Auth.signin(model.currentSignin, connectUser, i18n)
                    .then(function(res){
                        m.route.set("/account/home")
                        resolve(res)
                    })
                    .catch(function(err){
                        if (err.status === 2){
                            user.redirectStatus(Auth.getJWT())
                        }
                        reject(err)
                    })
                })
            }
        )
    },
    postSignup : function(){
        return new Promise((resolve, reject) => {
            model.current.lang = i18n.getLang()
            Auth.signup(model.current)
            .then(function(res){
                model.currentSignin.email = model.current.email
                model.currentSignin.password = model.current.password
                model.postSignin().then(res => resolve(res)).catch(err => reject(err))
            }).catch(function(err){
                model.setBackendErrorsMessages([err.response.error])
            })
        })
    },
    postOlyrixSignup : function(){// we first connect the user and after signup with olyrix
        model.currentSignin.email = model.current.email
        model.currentSignin.password = model.current.password
        model.current.lang = i18n.getLang()
        return new Promise(function(resolve, reject){
            model.postSignin(false).then(function(){
                Auth.olyrixSignup(model.current)
                .then(function(res){
                    model.currentSignin.email = model.current.email
                    model.currentSignin.password = model.current.password
                    model.postSignin().then(res => resolve(res)).catch(err => reject(err))
                }).catch(function(err){
                    model.setBackendErrorsMessages([err.response.message])
                })
            }).catch(function(err){
                reject(err)
            })
        })
        ///
    },
    getSignupInputErrorMessage : function(stringEnum){
        var message = model.getSignupInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getSignupInputStatesIndicator : function(stringEnum){
        var state = model.getSignupInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getSignupInputStates : function(stringEnum){
        var o
        switch (stringEnum) {
            case 'firstName':
                o = model._formSignupStates.firstName
            break;
            case 'lastName':
                o = model._formSignupStates.lastName
            break;
            case 'email':
                o = model._formSignupStates.email
            break;
            case 'phone_number':
                o = model._formSignupStates.phone_number
            break;
            case 'password':
                o = model._formSignupStates.password
            break;
            case 'password_confirmation':
                o = model._formSignupStates.password_confirmation
            break;
            case 'checkbox':
                o = model._formSignupStates.checkbox
            break;
            default:
                o = null
        }
        return o
    },
    getSigninInputErrorMessage : function(stringEnum){
        var message = model.getSigninInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getSigninInputStatesIndicator : function(stringEnum){
        var state = model.getSigninInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getSigninInputStates : function(stringEnum){
        var o
        switch (stringEnum) {
            case 'email':
                o = model._formSigninStates.email
            break;
            case 'password':
                o = model._formSigninStates.password
            break;
            default:
                o = null
        }
        return o
    },
    cleanAllForm: function(){

        model._formSigninStates = {
            email : {state : -1},
            password : {state : -1}
        }

        model._formSignupStates = { // state -1 = initial / state 0 = error / state 1 = valid
            firstName : {state : -1},
            lastName : {state : -1},
            email : {state : -1},
            phone_number : {state : -1},
            password : {state : -1},
            password_confirmation : {state : -1},
            checkbox : {state : -1}
        }
        /*if(!Login.getSignupIsShow()){
            var allSignupInput = [...document.getElementsByClassName('signin-form')[0].getElementsByTagName("input")]
            allSignupInput.map(x => x.value = "")
        }else{
            var allSignupInput = [...document.getElementsByClassName('signup-form')[0].getElementsByTagName("input")]
            allSignupInput.map(x => x.value = "")
        }*/

        model.currentSignin = {}
        model.current = {}
        m.redraw()
    },
    setQuickOlyrixConnect: function(q){
        model._quickOlyrixConnect = q
    },
    getQuickOlyrixConnect : function(){
        return model._quickOlyrixConnect
    },
    check : {
        firstName : function(e){
            var signupState = model.getSignupStates()
            if(utils.validateLengthCarac(e, 2, 80)){// min-mac carac
                signupState.firstName.state = 0
                signupState.firstName.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "2", MaxCharac : "80"})
            }else{
                signupState.firstName.message = null
                model.current.firstname = e
                signupState.firstName.state = 1
            }
            model.setFormSignupStates(signupState)
        },
        lastName : function(e){
            var signupState = model.getSignupStates()
            if(utils.validateLengthCarac(e, 2, 80)){// min-mac carac
                signupState.lastName.state = 0
                signupState.lastName.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "2", MaxCharac : "80"})
            }else{
                signupState.lastName.message = null
                model.current.lastname = e
                signupState.lastName.state = 1
            }
            model.setFormSignupStates(signupState)
        },
        email : function(e){
            return new Promise(function(resolve, reject){
                var signupState = model.getSignupStates()
                if(utils.validateLengthCarac(e, 5, 255)){// min-mac carac
                    signupState.email.state = 0
                    signupState.email.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "5", MaxCharac : "255"})
                }else if(utils.validateEmail(e)){
                    signupState.email.state = 0
                    signupState.email.message = i18n.cur.error_email_not_valid
                }else{
                    Auth.emailAlreadyExist(e).then(function(res){
                        signupState.email.message = null
                        model.current.email = e
                        signupState.email.state = 1
                        if(res.email){//remove the superfluous data if we switch to quick olyrix Connect
                            delete model.current.lastname
                            delete model.current.firstname
                            delete model.current.password_confirmation
                        }
                        resolve(res)
                    })
                }
                model.setFormSignupStates(signupState)
            })
        },
        phone_number : function(e){
            var signupState = model.getSignupStates()
            var stringNumber = '';
            var code = document.getElementsByClassName('select-your-country-phone')[0].value
            if(code === '33'){// we just remove the common error to add the '0' after the country code for french user. 
                stringNumber = (e.charAt(0) === '0' ? e.slice(1,e.length) : e).replace(/\s/g,'') //remove the white space
            }else{
                stringNumber = e
            }

            if(utils.validateLengthCarac(e, 5, 20)){ // small verif to check if the user have a minimum wrote a good tel-number
                signupState.phone_number.state = 0
                signupState.phone_number.message = i18n.cur.error_phone_number
            }else{
                signupState.phone_number.state = 1
                signupState.phone_number.message = null
                model.current.phone_number = stringNumber
                model.current.country_code_phone_number = code
            }
            model.setFormSignupStates(signupState)
        },
        emailSignin : function(e){
            var signinState = model.getSigninStates()
            if(utils.validateLengthCarac(e, 5, 255)){// min-mac carac
                signinState.email.state = 0
                signinState.email.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "5", MaxCharac : "255"})
            }else if(utils.validateEmail(e)){
                signinState.email.state = 0
                signinState.email.message = i18n.cur.error_email_not_valid
            }else{
                signinState.email.message = null
                model.currentSignin.email = e
                signinState.email.state = 1
            }
            model.setFormSigninStates(signinState)
        },
        passwordSignin : function(e){
            var signinState = model.getSigninStates()
            if(utils.validateLengthCarac(e, 6, 60)){
                signinState.password.state = 0
                signinState.password.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "6", MaxCharac : "60"})
            }else{//no errors
                model.currentSignin.password = e
                signinState.password.state = 1
                signinState.password.message = null
            }
            model.setFormSigninStates(signinState)
        },
        password : function(e){
            var signupState = model.getSignupStates()
            var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/
            if(utils.validateLengthCarac(e, 6, 60)){
                signupState.password.state = 0
                signupState.password.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "6", MaxCharac : "60"})
            }else if(!regex.test(String(e))){
                signupState.password.state = 0
                signupState.password.message = i18n.cur.error_format_pwd
            }else{//no errors
                model.current.password = e
                signupState.password.state = 1
                signupState.password.message = null
            }
            if(!model.getQuickOlyrixConnect()){
                var txtInputPwdConf = document.getElementById('input_register_password_confirmation').value
                if(txtInputPwdConf !== ''){//required to check if the user has changed the password when the password_confirmation's already wrote
                    model.check.password_confirmation(txtInputPwdConf)
                }
            }
            model.setFormSignupStates(signupState)
        },
        password_confirmation : function(e){
            var signupState = model.getSignupStates()
            if(e !== model.current.password){
                signupState.password_confirmation.state = 0
                signupState.password_confirmation.message = i18n.cur.error_not_same_pwd
            }else{
                signupState.password_confirmation.state = 1
                signupState.password_confirmation.message = null
                model.current.password_confirmation = e
            }
            model.setFormSignupStates(signupState)
        },
        checkbox : function(e){
            var signupState = model.getSignupStates()
            if(!e){
                signupState.checkbox.state = 0
                signupState.checkbox.message = i18n.cur.error_terms_not_accepted
            }else{
                signupState.checkbox.state = 1
                signupState.checkbox.message = null
                //model.current.checkbox = e // backend does not need this data
            }
            model.setFormSignupStates(signupState)
        },
    }
}

module.exports = model
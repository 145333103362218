var m = require('mithril')
var navModel = require('../models/Nav')
var global = require('../Global')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')
var langNav = require('../components/LangNav')
var user = require('../models/User')
var i18n = require('../models/TranslationModel')

var Nav = {
    oninit : function(vnode){
        Nav.forceLangNav = vnode.attrs.forceLangNav
    },
    onbeforeremove : function(){
        var bgElement = document.getElementById('nav-background')
        bgElement.classList.add('fadeOut')
        var navElement = document.getElementById('left-nav-container')
        navElement.classList.add('exit-a')
        return Promise.all([
            utils.waitBeforeElementUpdate(navElement),
            utils.waitBeforeElementUpdate(bgElement)
        ])
    },
    getLangNav : function(){
        return eventHelper.langNav.get() ? m(langNav) : ''
    },
    toggleLangNav : function(){
        eventHelper.langNav.set(!eventHelper.langNav.get())
    },
    getLangNavIfLog: function(){
        if(user.isLogged() || Nav.forceLangNav){
            return m('div', {class: "lang-box lang-defaut"},[
                m('div', {class: 'abs-container'} ,[
                    m('div', {
                        class: "current-lang lang-section clearfix",
                        onclick: function(){
                            Nav.toggleLangNav()
                        }}, 
                        [
                            m('span', {class: "lang-selected"}, [
                                m('span', {class: "lang-text"}, i18n.getLang())
                            ]),
                            m('span', {class: "ico-container"}, [
                                m('i', {class : "fas fa-chevron-down"})
                            ])
                        ]
                    )
                ]),
                Nav.getLangNav()
            ])
        }else{
            return ''
        }
    },
    view: function(ctrl) {
        return m("div", {id : "left-nav-container"}, [
            m("section", {"id":"sidebar-area"},
            [
                m("div", {class:"sticky-container"}, [
                    m("div", {"id":"prolyrix-logo"},
                        m(m.route.Link, {
                            href: user.isLogged() ? "home" : "", 
                            class: "logo-link",
                            onclick : function(e){
                                e.preventDefault()
                                eventHelper.leftNav.set(false)
                            }
                        },[
                            m("img", {"alt": "Logo PRÔlyrix" ,"src": global.url.assets + "images/prolyrix.svg"})
                        ])
                    ),
                    m('div', {class: 'left-nav-lang noselect'}, [
                        Nav.getLangNavIfLog()
                    ]),
                    m("div", {"id":"left-menu"}, 
                        m("nav",[
                            m("ul",
                                navModel.getAll().map(function(navItem) {
                                    var isHttp = navItem.href.includes("https://")
                                    var pHref = navItem.href.includes("https://") ? "a" : m.route.Link // check if we have plain domain name or mithril route
                                    var isActive = m.route.get() === navItem.href
                                    var noAction = false
                                    if(m.route.get() === "/account/verify" || m.route.get() === "/account/pending" || m.route.get() === "/account/activation" && navItem.href === "/login"){ // we want the button connexion is Active on the account/verify view
                                        isActive = true
                                        noAction = true
                                    }
                                    return m(isActive ? "li.active" : "li", [
                                        m(pHref, {
                                            target : isHttp ? "_blank" : "_self",
                                            "href": navItem.href,
                                            onclick : function(e){
                                                if(user.isLogged()){
                                                    if(noAction){
                                                        e.preventDefault()
                                                    }else{
                                                        var nav = document.getElementById("login-nav")
                                                        var h1Container = nav.getElementsByClassName("title-view-container")[0]
                                                        h1Container.style.display = 'none'
                                                        setTimeout(() => h1Container.style.display = 'table', 50)
                                                    }
                                                }
                                                eventHelper.leftNav.set(false)
                                            }
                                        },[
                                            m("span", {"class":"item-text noselect"}, navItem.text )
                                        ])
                                    ])
                                })
                            )
                        ])
                    )
                ])
            ]),
            m(Nav.BackgroundNav)
        ])
    }
}

Nav.BackgroundNav = {
    view : function(ctrl){
        return m("div", {
            id: 'nav-background',
            class: 'fadeIn d-d-none', //by default we show with fadeIn
            onclick : function(){
                eventHelper.leftNav.set(false)
            }
        })
    }
}

module.exports = Nav

var m = require('mithril')
var utils = require('../helpers/Utils')
var eventHelper = require('../helpers/EventHelper')
var auth = require('../services/Auth')
var user = require('../models/User')
var i18n = require('../models/TranslationModel')

var model = {}

model.myInfo = {
    current : {},
    _backendErrorMessages : [],
    getAllBackendError : function(){
        var messages = model.myInfo._backendErrorMessages
        if(messages !== [] && messages !== undefined){
            return m("div", {class: "backend-errors-container"},[
                messages.map(x => 
                    m('span', {class:"error"}, x))
            ])
        }
        return ''
    },
    submit : function(){
        if(model.myInfo.checkAllInput()){
            utils.getRecaptcha().then(function(token){ //here we check the token
                model.myInfo.current.token_recaptcha = token
                model.myInfo.post()
            })
        }
    },
    _formStates : {// state -1 = initial / state 0 = error / state 1 = valid
        birthdate : {state : -1},
    },
    checkAllInput : function(){
        var form = document.getElementsByClassName('contact-form')[0]

        return !model.myInfo.registerHaveError()
        

    },
    registerHaveError(){
        var errorUccured = true

        if(model.myInfo._formStates.email.state === 1
        && model.myInfo._formStates.phone_number.state === 1
        && model.myInfo._formStates.syntaxe.state === 1
        && model.myInfo._formStates.address.state === 1)
        {
            errorUccured = false
        }

        return errorUccured
    },
    getCurrentCode : function (){
        return this._currentCode
    },
    setCurrentCode : function (dc){
        this._currentCode = dc
    },
    getStates : function (){
        return model.myInfo._formStates
    },
    setFormStates : function (newFormStates){
        model.myInfo._formStates = newFormStates
    },
    post : function(){
        return new Promise(function(resolve, reject){
            if(model.myInfo.checkAllInput()){
                utils.getRecaptcha().then(function(token){ //here we check the token
                    console.log(token)
                    model.myInfo.current.token_recaptcha = token
                    resolve()
                })
            }else{
                reject()
            }
        })
    },
    getInputErrorMessage : function(stringEnum){
        var message = model.myInfo.getInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getInputStatesIndicator : function(stringEnum){
        var state = model.myInfo.getInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getInputStates : function(stringEnum){
        var o;
        switch (stringEnum) {
            case 'birthdate':
                o = model.myInfo._formStates.birthdate
            break;
            default:
                o = null
        }
        return o
    },
    update : {
        hideMyBirthdate : function(value){
            user.current.personne.see_date_of_birth = value
            user.toggleDateViewable(auth.getJWT())
        },
        birthdate : function(e, newPartialDate){
            var birthdate = user.current.personne.birthdate
            if(birthdate === null){
                user.current.personne.birthdate = ""
                birthdate = ""
            }
            var newBirthdate = [];
            if(birthdate.includes("-")){
                birthdate = birthdate.split("-")
            }
            var getOldMonth = "00"
            var getOldDay = "00"
            var getOldYear = "0000"

            if(birthdate[1] !== void 0){
                //console.log("it's undefined")
            }
            if(Array.isArray(birthdate)){
                getOldYear = birthdate[0]
                getOldDay = birthdate[2]
                getOldMonth = birthdate[1]
            }else{
                getOldYear = birthdate
            }
            newPartialDate.forEach(function(x,i){
                if(x !== '' && i == 2){ // here we edit the day
                    if(isNaN(x)){
                        x = "00"
                    }
                    newBirthdate[i] = x
                    newBirthdate[0] = getOldYear // année
                    newBirthdate[1] = getOldMonth // month
                }
                if(x !== '' && i == 1){ // here we edit the month
                    if(isNaN(x)){
                        x = "00"
                    }
                    newBirthdate[i] = x
                    newBirthdate[0] = getOldYear // year
                    newBirthdate[2] = getOldDay // day
                }
                if(x !== '' && i == 0){ // here we edit the year
                    if(isNaN(x)){
                        x = "0000"
                    }
                    newBirthdate[i] = x
                    newBirthdate[2] = getOldDay // day
                    newBirthdate[1] = getOldMonth // month
                }
            })
            if(newBirthdate[1].length == 1){
                newBirthdate[1] = "0" + newBirthdate[1]
            }
            if(newBirthdate[2].length == 1){
                newBirthdate[2] = "0" + newBirthdate[2]
            }
            return new Promise(function(resolve, reject){
                console.log("Send to server: " + newBirthdate.join('-'))
                user.current.personne.birthdate = newBirthdate.join('-')
                user.updatePersonne(auth.getJWT())
                .then(function(){
                    resolve()
                })
            })
        },
        syntaxe : function(e){
            var State = model.myInfo.getStates()
            if(utils.validateLengthCarac(e, 3, 300)){// min-mac carac
                State.syntaxe.state = 0
                State.syntaxe.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "3", MaxCharac : "300"})
            }else{
                State.syntaxe.message = null
                model.myInfo.current.syntaxe = e
                State.syntaxe.state = 1
            }
            model.myInfo.setFormStates(State)
        },
    }
}

module.exports = model
var m = require('mithril')
var utils = require('../helpers/utils')
var eventHelper = require('../helpers/EventHelper')
var user = require('../models/User')
var i18n = require('../models/TranslationModel')

var model = {
    current : {},
    _backendErrorMessages : [],
    getAllBackendError : function(){
        var messages = model._backendErrorMessages
        if(messages !== [] && messages !== undefined){
            return m("div", {class: "backend-errors-container"},[
                messages.map(x => 
                    m('span', {class:"error"}, x))
            ])
        }
        return ''
    },
    _formStates : {// state -1 = initial / state 0 = error / state 1 = valid
        link : {state : -1},
        explanation : {state : -1},
        file : {state : -1}
    },
    checkAllInput : function(e){ //TODO
        var form = utils.findAncestor(e.target, "send-edit-form")

        model.check.explanation(form.getElementsByClassName('input_edit_explanation')[0].value)
        model.check.file(form.getElementsByClassName('input_files_evidence')[0].files, form)
        model.check.link(form.getElementsByClassName('input_link')[0].value)

        return !model.registerHaveError()
        

    },
    registerHaveError(){
        var errorUccured = false
        if(model._formStates.explanation.state !== 1){
            errorUccured = true
        }
        if(!(model._formStates.file.state === 1 || model._formStates.link.state === 1))
        {
            errorUccured = true
            model._backendErrorMessages = []
            model._backendErrorMessages.push(i18n.cur.home_popup_lyrico_error)
        }else{
            model._backendErrorMessages = []
        }
        return errorUccured
    },
    getStates : function (){
        return model._formStates
    },
    setFormStates : function (newFormStates){
        model._formStates = newFormStates
    },
    post : function(e, jwt){
        return new Promise(function(resolve, reject){
            if(model.checkAllInput(e)){
                // TODO  : SEND TO SERVER
                var formData = new FormData()
                if(model.current.link !== undefined && model.current.link !== ""){
                    formData.append('link_site', model.current.link)
                }

                formData.append('content', model.current.explanation)
                console.log(model.current.files)
                if(model.current.files !== undefined){
                    formData.append("files[]", model.current.files);
                }
                var endpoint = ""
                if(model.current.repriseId === -1){
                    endpoint = "api/requests/lyricographie"
                }else{
                    endpoint = "api/requests/lyricographie/" + String(model.current.repriseId)
                    formData.append('oeuvreId', String(model.current.oeuvreId))
                    formData.append('productionId', String(model.current.productionId))
                    formData.append('repriseId', String(model.current.repriseId))

                }
                console.log(endpoint)
                m.request({
                    method: "POST",
                    url: endpoint,
                    body: formData,
                    serialize: function(value) {return value},
                    headers : {
                        Authorization: "Bearer " + jwt,
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                    config: function(xhr) {
                        xhr.upload.addEventListener("progress", function(e) {
                            var progress = e.loaded / e.total
                        })
                    }
                }).then(function(res) {
                    resolve(res)
                }).catch(function(err) {
                    console.log(err)
                    reject(err)
                })
                model._backendErrorMessages = []
            }else{
                reject()
            }
        })
    },
    getInputErrorMessage : function(stringEnum){
        var message = model.getInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getInputStatesIndicator : function(stringEnum){
        var state = model.getInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getInputStates : function(stringEnum){
        var o
        switch (stringEnum) {
            case 'link':
                o = model._formStates.link
            break;
            case 'explanation':
                o = model._formStates.explanation
            break;
            case 'file':
                o = model._formStates.file
            break;
            default:
                o = null
        }
        return o
    },
    clearFileInput(input){
        input.value = ""
        var element = input.parentNode.getElementsByClassName('files-name')[0]
        element.innerHTML = model.defaultImageInputFileTitle
    },
    cleanAllForm: function(){
        model._formStates = { // state -1 = initial / state 0 = error / state 1 = valid
            link : {state : -1},
            explanation : {state : -1},
            file : {state : -1}
        }
        model.current = {}
    },
    check : {
        link : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 0, 255)){// min-mac carac
                State.link.state = 0
                State.link.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "1", MaxCharac : "255"})
            }else if(e.length < 1){
                State.link.state = -1
            }else{
                State.link.message = null
                model.current.link = e
                State.link.state = 1
                model._backendErrorMessages = []
            }
            model.setFormStates(State)
        },
        explanation : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 14, 255)){// min-mac carac
                State.explanation.state = 0
                State.explanation.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "15", MaxCharac : "255"})
            }else{
                State.explanation.message = null
                model.current.explanation = e
                State.explanation.state = 1
            }
            model.setFormStates(State)
        },
        validateLengthCarac: function(e, minCarac, maxCarac){
            var length = e.length
            return !(length >= minCarac && length <= maxCarac)
        },
        file: function(e, form){
            var state = model.getStates()
            var files = e
            if(files.length === 1){
                if(utils.checkLimitFileSize(files)){
                    model.clearFileInput(form.getElementsByClassName("input_edit_pictures")[0])
                    state.file.state = 0
                    state.file.message = i18n.cur.error_ic_too_big.formatUnicorn({n : "15"})
                }else if(utils.checkIfFormatIsAuthorize(files)){
                    model.clearFileInput(form.getElementsByClassName("input_edit_pictures")[0])
                    state.file.state = 0
                    state.file.message = i18n.cur.error_wrong_files_type.formatUnicorn({filesType : "JPG,PNG"})
                }else{
                    if(files.length > 0){
                        var element = form.getElementsByClassName('edit-picture-text')[0]
                        element.innerHTML = files[0].name
                    }
                    model.current.files = files[0]
                    state.file.state = 1
                    state.file.message = null
                    model._backendErrorMessages = []
                }
            }else if(files.length === 0){
                state.file.state = -1
            }else{
                model.clearFileInput(form.getElementsByClassName("input_edit_pictures")[0])
                state.file.state = 0
                state.file.message = i18n.cur.error_too_many_picture
            }
            model.setFormStates(state)
        }
    }
}

module.exports = model
var m = require('mithril')
var utils = require('../helpers/Utils')
var Global = require('../Global')
var i18n = require('../models/TranslationModel')
var Auth = require('../services/Auth')
var user = require('../models/User')

var model = {
    _formStates : {// state -1 = initial / state 0 = error / state 1 = valid
        password : {state : -1},
        password_confirmation : {state : -1}
    },
    token : '',
    current : {},
    backendSuccessMessages : [],
    backendErrorsMessages : [],
    getBackendResponse : function(){
        var SuccessMessages = model.backendSuccessMessages
        var ErrorsMessages = model.backendErrorsMessages
        var successMessageExist = Boolean(typeof SuccessMessages !== 'undefined' && SuccessMessages.length > 0)
        var errorsMessageExist = Boolean(typeof ErrorsMessages !== 'undefined' && ErrorsMessages.length > 0)
        var successView;
        var errorsView;
        if(successMessageExist){
            successView = model.getSuccessMessagesView(SuccessMessages)
        }
        if(errorsMessageExist){
            errorsView = model.getErrorsMessagesView(ErrorsMessages)
        }
        if(successMessageExist || errorsMessageExist){
            return m("div", {class:"response-message"}, [
                successMessageExist ? successView : '',
                errorsMessageExist ? errorsView : ''
            ])
        }else{
            return null
        }
    },
    postAction: function(key){
        utils.getRecaptcha().then(function(token){
            model.current.password_confirm = model.current.password_confirmation
            model.current.token_recaptcha = token
            model.current.key = key
            m.request({
                method: 'POST',
                url: "api/users/update_password", // TODO: when backend will be finished -> auth.login and redirect to /home
                body: model.current,
                headers : {'Content-Type' : 'application/json', 'X-CSRF-TOKEN': utils.getCsrf()},
            })
            .then(function(res) {
                model.setBackendSuccessMessages([i18n.cur.lostpwd_confirm_reinit])
                model.clearForm()
                utils.getRecaptcha().then(function(token2){
                    var logData = {
                        token_recaptcha : token2,
                        password : model.current.password_confirm,
                        email : res.email
                    }
                    setTimeout(function(){
                        Auth.signin(logData, true, i18n)
                        .then(function(res){
                            m.route.set("/account/home")
                            return undefined
                        })
                        .catch(function(err){
                            if (err.status === 2){
                                user.redirectStatus(Auth.getJWT())
                            }
                            return undefined
                        })
                    }, 3000)
                })
            })
            .catch(function(err) {
                console.log(err)
                //model.setBackendErrorsMessages(["500 server error"])
            })
        })
    },
    clearForm : function(){
        var form = document.getElementsByClassName('lost-password-form')[0]
        form.getElementsByClassName('input_password_confirmation')[0].value = ""
        form.getElementsByClassName('input_password')[0].value = ""
    },
    setBackendSuccessMessages : function(messages){
        model.backendErrorsMessages = []
        model.backendSuccessMessages = messages

    },
    setBackendErrorsMessages : function(messages){
        model.backendSuccessMessages = []
        model.backendErrorsMessages = messages
    },
    getSuccessMessagesView(messages){
        return m('div', [
            messages.map(function(x){
                return m("span", {class:"response-text success"}, x)
            })
        ])
    },
    getErrorsMessagesView(messages){
        return m('div', [
            messages.map(function(x){
                return m("span", {class:"response-text error"}, x)
            })
        ])
    },
    checkAllInput : function(){
        var form = document.getElementsByClassName('lost-password-form')[0]
        model.check.password(form.getElementsByClassName('input_password')[0].value)
        model.check.password_confirmation(form.getElementsByClassName('input_password_confirmation')[0].value)
        return !model.haveError()
    },
    haveError: function(){
        var errorUccured = true
        
        if(model._formStates.password.state === 1
        && model._formStates.password_confirmation.state === 1)
        {
            errorUccured = false
        }

        return errorUccured
    },
    setFormStates : function (newFormStates){
        model._formStates = newFormStates
    },
    getFormStates : function (){
        return model._formStates
    },
    getInputErrorMessage : function(stringEnum){
        var message = model.getInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getInputStatesIndicator : function(stringEnum){
        var state = model.getInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getInputStates : function(stringEnum){
        var o
        switch (stringEnum) {
            case 'password':
                o = model._formStates.password
            break;
            case 'password_confirmation':
                o = model._formStates.password_confirmation
            break;
            default:
                o = null
        }
        return o
    },
    submit : function(key){
        if(model.checkAllInput()){
            model.postAction(key)
        }
    },
    check : {
        email : function(e){
            var signupState = model.getFormStates()
            if(model.check.validateLengthCarac(e, 5, 255)){// min-mac carac
                signupState.email.state = 0
                signupState.email.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "5", MaxCharac : "255"})
            }else if(model.check.validateEmail(e)){
                signupState.email.state = 0
                signupState.email.message = i18n.cur.error_email_not_valid
            }else{
                signupState.email.message = null
                model.current.email = e
                signupState.email.state = 1
            }
            model.setFormStates(signupState)
        },
        validateEmail(e) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return !(re.test(String(e).toLowerCase()))
        },
        validateLengthCarac: function(e, minCarac, maxCarac){
            var length = e.length
            return !(length >= minCarac && length <= maxCarac)
        },
        password : function(e){
            var signupState = model.getFormStates()
            var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/
            if(model.check.validateLengthCarac(e, 6, 60)){
                signupState.password.state = 0
                signupState.password.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "6", MaxCharac : "60"})
            }else if(!regex.test(String(e))){
                signupState.password.state = 0
                signupState.password.message = i18n.cur.error_format_pwd
            }else{//no errors
                model.current.password = e
                signupState.password.state = 1
                signupState.password.message = null
            }
            var txtInputPwdConf = document.getElementById('input_register_password_confirmation').value
            if(txtInputPwdConf !== ''){//required to check if the user has changed the password when the password_confirmation's already wrote
            model.check.password_confirmation(txtInputPwdConf)
            }
            model.setFormStates(signupState)
        },
        password_confirmation : function(e){
            var signupState = model.getFormStates()
            if(e !== model.current.password){
                signupState.password_confirmation.state = 0
                signupState.password_confirmation.message = i18n.cur.error_not_same_pwd
            }else{
                signupState.password_confirmation.state = 1
                signupState.password_confirmation.message = null
                model.current.password_confirmation = e
            }
            model.setFormStates(signupState)
        }
    }
}

module.exports = model
var Cropper = require('cropperjs')
var m = require('mithril')
var utils = require('../helpers/Utils')
var user = require('../models/User')
var i18n = require('../models/TranslationModel')
var eventHelper = require('../helpers/EventHelper')
var auth = require('../services/Auth')

var AvatarCropper = {
    cropper : undefined,
    oldWidth : 0,
    oninit : function(vnode){
        AvatarCropper.image = vnode.attrs.image
        AvatarCropper.isShow = vnode.attrs.isShow
        AvatarCropper.showCreditPopup = vnode.attrs.showCreditPopup

        if(!document.getElementById('cropperCss')) {
            var link = document.createElement('link')
            link.id = 'cropperCss'
            link.rel = 'stylesheet'
            link.href = '/assets/prolyrix/css/lib/cropper.min.css'
            document.head.appendChild(link);
        }

    },
    onbeforeremove : function(e){
        var bgElement = document.getElementById('background-popup-full')
        bgElement.classList.add('fadeOut')
        var popup = e.dom
        popup.classList.add("plain-popup-exit")
        return Promise.all([
            utils.waitBeforeElementUpdate(popup),
            utils.waitBeforeElementUpdate(bgElement)
        ])
    },
    oncreate : function(vnode){
        const image = document.getElementById('img-to-edit')
        AvatarCropper.cropper = new Cropper(image, {
            aspectRatio: 1 / 1,
            autoCropArea : 0.8,
            background : false,
            viewMode : 2,
            cropmove: function(e) {
                var data = AvatarCropper.cropper.getData()
                if (data.width < 500 && data.width < AvatarCropper.oldWidth) {
                    e.preventDefault()
                    data.width = 502;
                    data.height = 502;
                    AvatarCropper.cropper.setData(data)
                }
        
                
                AvatarCropper.oldWidth = data.width
            },
            ready : function(){
                var data = AvatarCropper.cropper.getData()
                if(data.width < 500 || data.height < 500){
                    data.width = 500;
                    data.height = 500;
                    AvatarCropper.cropper.setData(data)
                }
            }
        })
        setTimeout(() => AvatarCropper.cropper.setDragMode("move"), 500)
          

    },
    validImage : function(){
        let url = AvatarCropper.cropper.getCroppedCanvas({width: 500, height: 500}).toDataURL()
        //let url = URL.createObjectURL(img)
        user.current.personne.photoIdentity = url
        AvatarCropper.isShow(false)
        setTimeout(function(){
            AvatarCropper.cropper.getCroppedCanvas({width: 500, height: 500}).toBlob((blob) => {
                AvatarCropper.showCreditPopup(true, blob)
            })
        }, 150)
        // remove this cause to show popup before
        //AvatarCropper.post()
    },
    view: function() {
        return m("div", {class:"plain-container-popup"},[
            m("div", {class:"relative-div"}, [
                m("div", {class:"full-size cropper"},[
                    m("div", {class:"title-cropper"},[
                        m("div", i18n.cur.home_myinfo_avatar_title)
                    ]),
                    m("div", {class:"exit-button"},[
                        m("i", {
                            class:"fas fa-times",
                            onclick : function(){
                                AvatarCropper.isShow(false)
                            }
                        })
                    ]),
                    m("div", {class:"full-container-size"}, [
                        m("div", {class:"relative-full-div"},[
                            m("img", {
                                id:"img-to-edit",
                                src:URL.createObjectURL(AvatarCropper.image)
                            }),
                        ])
                    ]),
                    m("div", {class:"rounded-button-container"},[
                        m("div", {class:"full-relative-div"}, [
                            m("div", {class:"left-section"},[
                                m("div", {
                                    class:"button-item",
                                    onclick: () => AvatarCropper.cropper.rotate(-90)
                                }, [
                                    m("i", {class:"fas fa-undo-alt"})
                                ]),
                                m("div", {
                                    class:"button-item",
                                    onclick: () => AvatarCropper.cropper.rotate(90)
                                }, [
                                    m("i", {class:"fas fa-undo-alt fa-flip-horizontal"})
                                ]),
                                m("div", {
                                    class:"button-item",
                                    onclick: () => AvatarCropper.cropper.reset()
                                }, [
                                    m("i", {class:"fas fa-sync-alt"})
                                ])
                            ]),
                            m("div", {class:"right-section"},[
                                m("div", {
                                    class:"button-item back",
                                    onclick : () => AvatarCropper.isShow(false)
                                }, [
                                    m("i", {class:"fas fa-times"})
                                ]),
                                m("div", {
                                    class:"button-item valid",
                                    onclick : () => AvatarCropper.validImage()
                                }, [
                                    m("i", {class:"fas fa-check"})
                                ]),
                            ])
                        ])
                    ]),
                ]),      
            ]),
            m(AvatarCropper.BackgroundNav)
        ])
    },
    post : function(){
        AvatarCropper.postBackend().then(function(res){
            /*eventHelper.widgetLoader.setProgress(5)
            // FAKE LOADER
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(40)
            }, 500)
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(100)
                
            }, 1600)
            setTimeout(function(){
                AvatarCropper.toggleLoader()
                AvatarCropper.isShow(false)
            }, 2300)*/
            AvatarCropper.toggleLoader()
            AvatarCropper.isShow(false)
        }).catch(function(err){
            console.log(err)
            AvatarCropper.isShow(false)
            AvatarCropper.toggleLoader()
        })
    },
    postBackend : function(){
        return new Promise(function(resolve, reject){
            AvatarCropper.cropper.getCroppedCanvas().toBlob((blob) => {
                var formData = new FormData()

                formData.append('image', blob, user.current.personne.fullname + '.png')

                m.request({
                    method: "POST",
                    url: "/api/requests/personne/avatar",
                    body: formData,
                    serialize: function(value) {return value},
                    headers : {
                        Authorization: "Bearer " + auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                    config: function(xhr) {
                        xhr.upload.addEventListener("progress", function(e) {
                            var progress = e.loaded / e.total
                            console.log(progress)
                        })
                    }
                }).then(function(res) {
                    console.log(res)
                    resolve(res)
                }).catch(function(err) {
                    console.log(err)
                    reject(err)
                })
            })
        })
    },
    getLoader : function(){
        return eventHelper.widgetLoader.get() ? m(Loader) : ''
    },
    toggleLoader : function(){
        eventHelper.widgetLoader.set(!eventHelper.widgetLoader.get())
    },
}

AvatarCropper.BackgroundNav = {
    view : function(ctrl){
        return m("div", {
            id: 'background-popup-full',
            class: 'fadeIn',
            onclick : function(){
                AvatarCropper.isShow(false)
            }
        })
    }
}

module.exports = AvatarCropper
var m = require('mithril')
var utils = require('../helpers/Utils')
var user = require('../models/User')
var editLyricographiePopup = require('../components/EditLyricographiePopup')
var Global = require('../Global')
var Auth = require('../services/Auth')
var i18n = require('../models/TranslationModel')

let ExtendLyrico = {
    _showPopupEditLyricographie : false,
    _repriseToEdit : -1,
    oninit: function(vnode){
        this.prod = vnode.attrs.prod
        this.job = vnode.attrs.job
    },
    oncreate : function(vnode){
        var that = this
        if(that.prod.isLoaded === false){
            this.getData().then(function(res){
                // remove the loader
                var loader = vnode.dom.getElementsByClassName("lds-ellipsis")
                loader[0].classList.add("quick-over-sizing")
                utils.waitBeforeElementUpdate(loader[0]).then(function(){
                    Object.entries(user.current.artiste.experiences).map(function(item){
                        var i = 0
                        Object.entries(item[1]).map(function(){ // just i is used in this .map
                            if(that.prod.bidingId === user.current.artiste.experiences[item[0]][i].bidingId){
                                user.current.artiste.experiences[item[0]][i].isLoaded = true
                                user.current.artiste.experiences[item[0]][i].reprises = res
                            }
                            i++
                        })
                    })
                    m.redraw()
                })
            })
        }
    },
    view: function() {
        var that = this
        return [m("div", {class:"extend-lyricographie-container"}, [
            this.prod.isLoaded ? this.getPageContent() : this.getLoaderOnLoading()
        ]),
        that.getShowPopupEditLyricographie() ? m(editLyricographiePopup, {
            isShow : x => that.setShowPopupEditLyricographie(x), 
            repriseId : that._repriseToEdit,
            oeuvreId: that.prod.oeuvre_id,
            productionId : that._productionToEdit
        }) : null]
    },
    getPageContent : function(){
        var that = this
        return m("div", {class:"all-prod-extend"}, [
            this.prod.reprises.map(function(reprise){
                return m("div", {class:"widget-reprise"}, [
                    m("div", {class:"left-gold-border"}),
                    m("div", {
                        class:"edit-reprise rounded-button",
                        onclick : function(){
                            console.log(that.prod)
                            that._repriseToEdit = reprise.id
                            that._productionToEdit = reprise.productionId
                            that.setShowPopupEditLyricographie(true)
                        }
                    }, [
                        m('i', {class:"fas fa-pen"})
                    ]),
                    m("h3", {class:"title-section"}, reprise.lieu.value),
                    m("div", {class:"line"}, [
                        m("span", {class:"light-text middle"},[
                            m("span", {class:"small-text"}, i18n.cur.home_lyrico_cm)
                        ]),
                        m("span", {class:"light-text right"}, [
                            m("span", {class:"bold-text"}, reprise.bandmasters[0])
                        ])
                    ]),
                    m("div", {class:"line"}, [
                        m("span", {class:"light-text middle"},[
                            m("span", {class:"small-text"}, i18n.cur.home_lyrico_sd)
                        ]),
                        m("span", {class:"light-text right"}, [
                            m("span", {class:"bold-text"}, reprise.stageDirectors[0])
                        ])
                    ]),
                    m("h3", {class:"title-section second-S"}, i18n.cur.home_lyrico_representation),
                    m("div", {class:"all-representation-date"}, [
                        reprise.representations.map(function(item){
                            return m("div", {class: "representation-date-container"}, [
                                m("div", {class:"month-with-year-readable"}, [
                                    m("span", item.monthText)
                                ]),
                                m("div", {class:"all-representation-date"}, [
                                    item.allDate.map(function(uniqDate){
                                        return m("span", {class:"date-item"}, uniqDate)
                                    })
                                ])
                            ])
                        })
                    ]),
                ])
            })
        ])
    },
    setShowPopupEditLyricographie : function(x){
        this._showPopupEditLyricographie = x
    },
    getShowPopupEditLyricographie : function(){
        return this._showPopupEditLyricographie
    },
    getLoaderOnLoading : function(){
        return m("div", {class:"lds-ellipsis"}, [
            m("div"),
            m("div"),
            m("div"),
            m("div")
        ])
    },
    getData : function(){
        var that = this
        return new Promise(function(resolve, reject){
            var body = Object.create(null)
            body.artiste = {
                reprise : {
                    oeuvre_id: that.prod.oeuvre_id // OEUVRE ID
                }
            }
            m.request({
                method: 'POST',
                url: Global.api.user.show + String(user.current.user.id),
                body: body,
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " + Auth.getJWT(),
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                var allRepriseData = []
                res.artiste.reprise.forEach(item => {
                    var reprise = {}
                    reprise.bandmasters = item.band_masters
                    reprise.id = item.id // REPRISE ID 
                    reprise.productionId = item.production_id // PROD ID
                    reprise.stageDirectors = item.stage_directors
                    reprise.lieu = {id : item.lieu.id, value : item.lieu.name}

                    Object.entries(item.representations).map(function(item){
                        var i = 0
                        var representations = []
                        Object.entries(item[1]).map(function(repreScop){
                            var i = 0
                            representations.push({
                                monthText : repreScop[0] + " " + item[0],
                                allDate : repreScop[1]
                            })
                        })
                        reprise.representations = representations
                    })

                    allRepriseData.push(reprise)

                })
                resolve(allRepriseData)
            }).catch((err) => reject(err))
        })
    }
}


module.exports = ExtendLyrico
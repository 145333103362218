var m = require('mithril')

var Utils = {
    getCsrf : () => (document.head.querySelector('meta[name="csrf-token"]').content),

    _onDesktop : false,
    __files_size_limit_by_input : 15, // in mo
    waitBeforeElementUpdate: function(element){
        return new Promise((resolve) => {
            element.addEventListener("animationend", resolve)
        })
    },
    getUniqKey : () => new Date().valueOf().toString(36) + Math.random().toString(36).substr(2),
    extract : function(xhr, xhrOptions) {
        if (xhrOptions.method == "HEAD") return xhr.getResponseHeader("x-item-count")
        else return xhr.responseText
    },
    getRecaptcha : function(){
        return new Promise(function(resolve){
            grecaptcha.ready(function() {
                grecaptcha.execute('6LccgW0kAAAAAKDv4Gkb9YYdI3-A08Ms_ZCcpev2', {action: 'homepage'}).then(function(token) {
                    resolve(token)
                })
            })
        })
    },
    onDesktop : function(){
        var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    },
    getOnDesktop : function(){
        this.onDesktop()
        return this._onDesktop
    },
    setOnDesktop : function(x){
        this._onDesktop = x
    },
    hideScrollBar : function(){
        document.body.style.overflow = 'hidden';
    },
    showScrollBar : function(){
        document.body.style.overflow = 'visible';
    },
    withKey : function(key, callback) {
        return function(e) {
            var ek = e.keyCode
            if (ek == key) callback(e)
        }
    },
    findAncestor : function(el, cls) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    },
    getsizeInMB : function(size){
        return (size / (1024*1024)).toFixed(2);
    },
    checkIfFormatIsAuthorize(files){
        var authorize = false
        Object.keys(files).map(function(key, index) {
            var type = files[key].type
            if(type === "image/png" 
            || type === "application/pdf"
            || type === "image/jpeg")
            {
                authorize = true
            }
        })
        return !authorize
    },
    validateLengthCarac: function(e, minCarac, maxCarac){ // check if an element is bewteen a range of characters
        var length = e.length
        return !(length >= minCarac && length <= maxCarac)
    },
    validateEmail(e) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !(re.test(String(e).toLowerCase()))
    },
    validatePassword(e) {
        var re = /^(?=.[a-z])(?=.[A-Z])(?=.*\d).+$/
        return !(re.test(String(e).toLowerCase()))
    },
    checkLimitFileSize(files){ // return bool -> check the number of files selected by the user and return bool
        var totalSize = 0;

        Object.keys(files).map(function(key, index) {
            totalSize += files[key].size
        })

        totalSize = Utils.getsizeInMB(totalSize)
        

        return totalSize >= this.__files_size_limit_by_input
    },
    scrollToTop : function(duration, timeoutDuration) { // translation duration, timeout duration -> all in ms
        return new Promise(function(res) {
            setTimeout(function(){
                if(window.scrollY < 5){
                    res()
                }else{
                    const scrollHeight = window.scrollY,
                    scrollStep = Math.PI / ( duration / 15 ),
                    cosParameter = scrollHeight / 2;
                    var scrollCount = 0,
                    scrollMargin,
                    scrollInterval = setInterval( function() {
                        if ( window.scrollY != 0 ) {
                            scrollCount = scrollCount + 1;  
                            scrollMargin = cosParameter - cosParameter * Math.cos( scrollCount * scrollStep );
                            window.scrollTo( 0, ( scrollHeight - scrollMargin ) );
                        } 
                        else{
                            clearInterval(scrollInterval);
                            res()
                        }
                    }, 15 );
                }
            }, timeoutDuration)
        })
    },
    getYoutubeVideoIDFromUrl : function(url){
        var rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]+).*/
        return url.match(rx)[1]
    },
    getFileFromUrl : function(url){ // DOESN'T WORK
        /*return new Promise(function(resolve){
            m.request({
                method: "GET",
                url: url,
                responseType : "blob",
                header : "Access-Control-Allow-Origin: *"
            })
            .then(function(blob) {
                console.log(blob)
            })
        })


        var file = new File([blob], "miniature.jpg");*/
    },
    parseDuration(PT) {
        var output = []
        var durationInSec = 0
        var matches = PT.match(/P(?:(\d*)Y)?(?:(\d*)M)?(?:(\d*)W)?(?:(\d*)D)?T?(?:(\d*)H)?(?:(\d*)M)?(?:(\d*)S)?/i)
        var parts = [
        { // years
            pos: 1,
            multiplier: 86400 * 365
        },
        { // months
            pos: 2,
            multiplier: 86400 * 30
        },
        { // weeks
            pos: 3,
            multiplier: 604800
        },
        { // days
            pos: 4,
            multiplier: 86400
        },
        { // hours
            pos: 5,
            multiplier: 3600
        },
        { // minutes
            pos: 6,
            multiplier: 60
        },
        { // seconds
            pos: 7,
            multiplier: 1
        }]
        
        for (var i = 0; i < parts.length; i++) {
          if (typeof matches[parts[i].pos] != 'undefined') {
            durationInSec += parseInt(matches[parts[i].pos]) * parts[i].multiplier
          }
        }
        
        // Hours extraction
        if (durationInSec > 3599) {
          output.push(parseInt(durationInSec / 3600))
          durationInSec %= 3600;
        }
        // Minutes extraction with leading zero
        output.push(('0' + parseInt(durationInSec / 60)).slice(-2))
        // Seconds extraction with leading zero
        output.push(('0' + durationInSec % 60).slice(-2))
        
        return output.join(':')
    },
    removeHtml : function(htmlContent){
        var removeHtml = document.createElement("div")
        removeHtml.innerHTML = htmlContent
        return removeHtml.textContent || removeHtml.innerText || ""
    },
    getFormData(object) {
        var formData = new FormData()
        Object.keys(object).forEach(key => formData.append(key, object[key]))
        return formData
    },
    convertTranslationList(translationList){
        var newList = Array()
        Object.entries(translationList).forEach(item => {
            var id = item[0].split('_').reverse()[0]
            var data = item[1]
            newList.push({id : id, value : data})
        })
        return newList
    }
}

Array.prototype.extend = function (array) {
    array.forEach(item => this.push(item))
}

String.prototype.formatUnicorn = String.prototype.formatUnicorn ||
function () {
    "use strict";
    var str = this.toString();
    if (arguments.length) {
        var t = typeof arguments[0];
        var key;
        var args = ("string" === t || "number" === t) ?
            Array.prototype.slice.call(arguments)
            : arguments[0];

        for (key in args) {
            str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }
    }

    return str;
};

Math.easeInOutQuad = function (t, b, c, d) {
    t /= d/2
    if (t < 1) return c/2*t*t + b
    t--
    return -c/2 * (t*(t-2) - 1) + b
};

module.exports = Utils
var m = require('mithril')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')
var user = require('../models/User')
var model = require('../models/TagBuilderModel')
var infoDiv = require('../components/InfoDiv')
var Loader = require('../components/WidgetLoader')
var areaTagBuilder = require('../components/AreaTagBuilder')
var selectTagBuilder = require('../components/SelectTagBuilder')
var entity = require('../models/EntityModel')
var i18n = require('../models/TranslationModel')
var auth = require('../services/Auth')

var TagBuilder = {
    oninit: function(vnode){
        TagBuilder.isShow = vnode.attrs.isShow
        TagBuilder.baseImage = vnode.attrs.image
        model.baseImage = vnode.attrs.image
    },
    oncreate : function(e){

    },
    onbeforeremove : function(e){
        var bgElement = document.getElementById('background-popup-k')
        bgElement.classList.add('fadeOut')
        var popup = e.dom.getElementsByClassName("popup-widget-bg")[0]
        popup.classList.add("big-popup-exit")
        return Promise.all([
            utils.waitBeforeElementUpdate(popup),
            utils.waitBeforeElementUpdate(bgElement)
        ])
    },
    view: function() {
        var that = this
        return m("div", {class:"plain-container tags-builder-popup"},[
            m("div", {class:"relative-div"}, [
                m("div", {class:"popup-widget-bg custom-input-animation"},[
                    m("div", {class:"header"}, [
                        m("span", {class:"title-popup"},[
                            m("h3", {class:"text"}, i18n.cur.aspi_title)
                        ]),
                        m("span", {
                            class:"ico-container",
                            onclick : function(){
                                TagBuilder.isShow(false)
                            }
                        },[
                            m("i", {class:"fas fa-times"})
                        ])
                    ]),
                    m("div", {class:"send-edit-form"},[
                        m('form', { onsubmit: "", name:"send-form"},[
                            m("div", {class:"left-section"}, [
                                // OEUVRES
                            
                                m(selectTagBuilder, {
                                    uniq : true,
                                    minCarac : 2,
                                    research : (x) => entity.oeuvre.tags.researchByName(x),
                                    remove : (x, jwt) => model.oeuvre.set(null),
                                    add : (x, jwt) => model.oeuvre.set(x, jwt),
                                    all : () => model.oeuvre.get(),
                                    inputTitle : i18n.cur.aspi_in_oeuvre
                                }),
        
                                // PRODUCTION
                                
                                model.oeuvre.isSet() ? m(selectTagBuilder, {
                                    uniq : true,
                                    //minCarac : 1,
                                    research : (x) => entity.production.tags.researchByName(
                                        x, 
                                        model.oeuvre.get()[0].id
                                    ),
                                    remove : () => model.production.set(null),
                                    add : (x, jwt) => model.production.set(x, jwt),
                                    all : () => model.production.get(),
                                    inputTitle : i18n.cur.aspi_in_sd
                                }) : '',
        
                                // REPRISE
            
                                model.oeuvre.isSet() && 
                                model.production.isSet() ? 
                                m(selectTagBuilder, {
                                    uniq : true,
                                    research : (x) => entity.reprise.tags.researchByName(
                                        x, 
                                        model.oeuvre.get()[0].id,
                                        model.production.get()[0].id
                                    ),
                                    remove : () => model.reprise.set(null),
                                    add : (x, jwt) => model.reprise.set(x, jwt),
                                    all : () => model.reprise.get(),
                                    inputTitle : i18n.cur.aspi_in_lieu
                                }) : '',
            
                                // DISTRIBUTION 
            
                                model.oeuvre.isSet() && 
                                model.production.isSet() &&
                                model.reprise.isSet() ? 
                                m(selectTagBuilder, {
                                    uniq : true,
                                    research : (x) => entity.distribution.tags.researchByName(
                                        x, 
                                        model.oeuvre.get()[0].id,
                                        model.production.get()[0].id,
                                        model.reprise.get()[0].id
                                    ),
                                    remove : (x, jwt) => model.distribution.set(null),
                                    add : (x, jwt) => model.distribution.set(x, jwt),
                                    all : () => model.distribution.get(),
                                    inputTitle : i18n.cur.aspi_in_distri
                                }) : '',                      
            
                                model.oeuvre.isSet() ? '' : m("div", {class:"bottom-margin"}, [
                                    m(infoDiv, {text : i18n.cur.aspi_help})
                                ]),
                            ]),
                            m("div", {class:"right-section"}, [
                                m(areaTagBuilder, {
                                    uniq : true,
                                    all : () => model.getAllData(),
                                    inputTitle : "tag(s) proposé(s)"
                                }),

                                model.oeuvre.isSet() ? m("div", {}, [
                                    m(infoDiv, {text : i18n.cur.aspi_help_tag_area})
                                ]) : ''
                            ])
                        ]),
                        m("div", {class:"button-container"}, [
                            model.getAllData().length > 0 ?
                            m("button", {
                                class:"button-with-ico brown recycle",
                                onclick: function(){
                                    model.clearBuilder()
                                }
                            }, [
                                m('span', {class: 'left-ico'},[
                                    m('i', {class: 'fas fa-recycle'})
                                ]),
                                m("span", {class:"text-button"}, i18n.cur.aspi_recycle)
                            ]) : '',
                            m("button", {
                                class:"button-with-ico brown",
                                onclick: function(){
                                    that.addTagToTarget()
                                }
                            }, [
                                m('span', {class: 'left-ico'},[
                                    m('i', {class: 'fas fa-plus'})
                                ]),
                                m("span", {class:"text-button"}, i18n.cur.aspi_add)
                            ])
                        ])
                    ]),
                    //model.getAllBackendError(),
                    TagBuilder.getLoader()
                ]),
                m(TagBuilder.BackgroundNav)
            ])
        ])
    },
    addTagToTarget : function(){
        var allTagsToAdd = areaTagBuilder.getAllTagsToAdd()
        var allPromise = []
        allTagsToAdd.map(function(item){
            switch(item.type){
                case "people":
                    allPromise.push(user.tag.video.personne.add(item, auth.getJWT(), false))
                    break;
                case "oeuvre":
                    allPromise.push(user.tag.video.oeuvre.add(item, auth.getJWT(), false))
                    break;
                case "production":
                    allPromise.push(user.tag.video.production.add(item, auth.getJWT(), false))
                    break;
                case "lieu":
                    allPromise.push(user.tag.video.lieu.add(item, auth.getJWT(), false))
                    break;
            }
        })
        setTimeout(function(){
            eventHelper.widgetLoader.setProgress(40)
        }, 200)
        Promise.all(allPromise)
        .then(res => {
            user.tag.video.saveSelected(auth.getJWT())
        })
        setTimeout(function(){
            eventHelper.widgetLoader.setProgress(100)
        }, 400)
        setTimeout(function(){
            TagBuilder.toggleLoader()
            TagBuilder.isShow(false)
        }, 800)
    },
    post : function(e){
        /*TagBuilder.toggleLoader()
        model.post(e).then(function(res){
            console.log("it's ok")
            eventHelper.widgetLoader.setProgress(5)
            // FAKE LOADER
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(40)
            }, 500)
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(100)
                
            }, 1600)
            setTimeout(function(){
                TagBuilder.toggleLoader()
            }, 23000)
        }).catch(function(err){
            console.log(err)
            TagBuilder.toggleLoader()
        })*/
    },
    getLoader : function(){
        return eventHelper.widgetLoader.get() ? m(Loader) : ''
    },
    toggleLoader : function(){
        eventHelper.widgetLoader.set(!eventHelper.widgetLoader.get())
    },
}

TagBuilder.BackgroundNav = {
    view : function(ctrl){
        return m("div", {
            id: 'background-popup-k',
            class: 'fadeIn', //by default we show with fadeIn
            onclick : function(){
                TagBuilder.isShow(false)
            }
        })
    }
}

module.exports = TagBuilder

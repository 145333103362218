var m = require('mithril')
var eventHelper = require('../helpers/EventHelper')
var utils = require("../helpers/Utils")
var i18n = require("../models/TranslationModel")

var Notif = {
    oninit : function(vnode){
        if(vnode.attrs.isAuto === undefined){
            this.isAuto = true
        }else{
            this.isAuto = vnode.attrs.isAuto
        }
    },
    onbeforeremove: function(){
        var notif = document.getElementsByClassName('update-notif')[0]
        return Promise.all([
            utils.waitBeforeElementUpdate(notif)
        ])
    },
    oncreate: function(vnode){
        setTimeout(function(){
            var notif = document.getElementsByClassName('update-notif')[0]
            notif.classList.add('leave-update-widget-notif')
            if(this.isAuto || this.isAuto === undefined){
                eventHelper.updateWidgetNotif.set(false) 
                eventHelper.updatePassWidgetNotif.set(false)
            }
            m.redraw()
        }, 3000)
    },
    view: function() {
        return m("div", {class:"update-notif"},[
            m("div", {class:"notif-container"}, [
                m("span", {class: "notif-ico"}, [
                    m("i", {class:"fas fa-cloud-upload-alt"})
                ]),
                m("span", {class:"saved-text"}, i18n.cur.widget_notif_saved)
            ])
        ])
    }
}

module.exports = Notif

var m = require('mithril')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')
var i18n = require('../models/TranslationModel')

var LangNav = {
    oninit(){// here we select to good lang if any was change in old session
        var item = LangNav.model.getItemWithCode(i18n.getLang())
        LangNav.model.setCurrent(item, false)
    },
    onbeforeremove : function(vnode){
        vnode.dom.classList.add('exit-popup-lang')
        return utils.waitBeforeElementUpdate(vnode.dom)
    },
    close: function() {
        eventHelper.langNav.set(false)
    },
    view : function(){
        return m('div', {class: 'abs-container container-lang-box box-shadow'} ,[
            m('div', {
                class: "current-lang clearfix",
                onclick: function(){
                    LangNav.close()
                }},
                [
                    m('span', {class: "lang-selected"}, [
                        m('span', {class: "lang-text"}, LangNav.model.getCurrent().code)
                    ]),
                    m('span', {class: "ico-container"}, [
                        m('i', {class : "fas fa-times"})
                    ])
                ]
            ),
            m('ul',{class: "nav-extend clearfix"},
                LangNav.model.getAllExceptedCurrent().map(function(navItem) {
                    return m('li', {
                        class: "lang-to-selected",
                        onclick : function(){
                            LangNav.model.setCurrent(navItem)
                            LangNav.close()
                        }
                    },
                    [
                        m('span', {class: "lang-text"}, navItem.code)
                    ])
                })
            )
            /*m('span', {class: "lang-selected"}, [
                m('span', {class: "lang-text"}, LangNav.model.getCurrent().code)
            ]),*/
        ])
    }
}

LangNav.model = {
    _all : [
        {id : 1, code : 'fr', langString : 'Français', current : 1},
        {id : 2, code : 'en', langString : 'English', current : 0},
        {id : 3, code : 'de', langString : 'Deutsch', current : 0},
        {id : 4, code : 'es', langString : 'Español', current : 0},
        {id : 5, code : 'it', langString : 'Italiano', current : 0}
    ],
    setCurrent : function(item, refresh = true){
        if(LangNav.model.getCurrent() != item){ // update current lang only if item != of _current
            LangNav.model.getCurrent().current = 0
            item.current = 1
            refresh ? i18n.setLang(item.code) : null
        }
    },
    getCurrent : function(){
        var current;
        LangNav.model._all.map(function(x){
            if(x.current){
                current = x
            }
        })
        return current
    },
    add : function(item){
        LangNav.model._all.push(item)
    },
    getAll : function(){
        return LangNav.model._all 
    },
    getAllExceptedCurrent : function(){
        var allNoCurrentLang = []
        
        LangNav.model.getAll().map(x => x.current === 0 ? allNoCurrentLang.push(x) : null)

        return allNoCurrentLang
    },
    getItemWithCode : function(code){
        var item;
        LangNav.model.getAll().map(x => x.code === code ? item = x : null)
        return item
    }
}

module.exports = LangNav


var m = require('mithril')
var user = require('../models/User')
var Global = require('../Global')
var router = require('../services/Router')
var utils = require('../helpers/Utils')

var Auth = {
    statut : {

    },
    emailAlreadyExist: function(email){
        return new Promise(
            function(resolve, reject) {
            utils.getRecaptcha().then((token) => {
                m.request({
                    method: 'POST',
                    url: Global.api.auth.emailAlreadyExist,
                    body: {
                        email : email,
                        token_recaptcha : token
                    },
                    headers : {'Content-Type' : 'application/json', 'X-CSRF-TOKEN': utils.getCsrf()},
                })
                .then(function(res) {
                    resolve(res)
                })
                .catch(function(err) {
                    reject(err)
                })
            })
        })
    },
    signup: function(data) {
        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'POST',
                url: Global.api.auth.signup,
                body: data,
                headers : {'Content-Type' : 'application/json', 'X-CSRF-TOKEN': utils.getCsrf()},
            })
            .then(function(res) {
                resolve(res)
            })
            .catch(function(err) {
                reject(err)
            })
        })
    },
    olyrixSignup: function(data) {
        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'POST',
                url: Global.api.auth.olyrixSignup,
                body: data,
                headers : {
                    'Content-Type' : 'application/json',
                    Authorization: "Bearer " + Auth.getJWT(),
                    'X-CSRF-TOKEN': utils.getCsrf()
                }
            })
            .then(function(res) {
                resolve(res)
            })
            .catch(function(err) {
                reject(err)
            })
        })
    },
    signin: function(data, connectUser = true, i18n) {
        return new Promise(
            function(resolve, reject) {
                m.request({
                method: 'POST',
                url: Global.api.auth.signin,
                body: data,
                extract : function(xhr) {return {status: xhr.status, body: JSON.parse(xhr.responseText)}},
                headers : {'Content-Type' : 'application/json', 'X-CSRF-TOKEN': utils.getCsrf()},
                })
                .then(function(response) {
                    if(response.status >= 400){
                        reject(response.body)
                    }else{
                        var response = response.body
                        Auth.setNewJWT(response.token) //need to be set before connectUser()
                        if(!user.isLogged()){ // the function signin is also called for test the user password in model setting so we dont want to load again the user
                            if(connectUser){
                                Auth.connectUser(i18n).then(function(response){
                                    resolve(response)
                                }).catch(function(err){
                                    reject(err)
                                })
                            }else{ // we just want to see if the user credentials is good
                                resolve(true)
                            }
                        }else{
                            resolve(response)
                        }
                    }
                })
                .catch(function(error) {
                    console.log(JSON.stringify(error.message))
                    reject(error)
                })
            }
        )
    },
    connectUser: function(i18n){
        return new Promise(
            function(resolve, reject) {
                Auth.getUserIdByJWT().then(function(res){
                    user.current.user.id = res.id
                    user.load(Auth.getJWT(), i18n).then(function(res){
                        resolve(res)
                    }).catch((err) => {
                        reject(err)
                    })
                }).catch((err) => reject(err))
            }
        )
    },
    init: function(i18n){
        return new Promise(
            function(resolve, reject) {
                if(Auth.getJWT() !== false){
                    Auth.refreshToken()
                    .then(function(res){
                        Auth.connectUser(i18n)
                        .then((res) => resolve(res))
                        .catch((err) => reject(err))
                    })
                    .catch((err) => reject(err))
                }else{
                    reject()
                }
            }
        )
    },
    getUserIdByJWT: function(){
        return new Promise(
            function(resolve, reject) {
                m.request({
                    method: 'GET',
                    url: Global.api.auth.getUserId,
                    extract : function(xhr) {return {status: xhr.status, body: JSON.parse(xhr.responseText)}},
                    body: {},
                    headers : {
                        'Content-Type' : 'application/json',
                        Authorization: "Bearer " + Auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                })
                .then((response) => {
                    if(response.status >= 400){
                        reject(response.body)
                    }else{
                        resolve(response.body)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    reject(err)
                })
            }
        )
    },
    removeJWT: function(){
        localStorage.removeItem('token')
        //console.log(localStorage)
    },
    getJWT: function(){
        if(localStorage.hasOwnProperty('token')){
            //console.log(localStorage.getItem("token"))
            return localStorage.getItem('token')
        }else{
            return false
        }
    },
    setNewJWT: function(token){
        localStorage.setItem("token", token)
        user.jwt = this.getJWT()
        //setTimeout(Auth.refreshToken(), 1000*60*Global.refreshTokenInMinutes); 
    },
    refreshToken : function(){
        return new Promise(
            function(resolve, reject) {
                m.request({
                    method: 'GET',
                    url: Global.api.auth.refreshToken,
                    body: {},
                    headers : {
                        'Content-Type' : 'application/json',
                        Authorization: "Bearer " + Auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                })
                .then(function(res) {
                    Auth.setNewJWT(res.token)
                    resolve(res)
                })
                .catch(function(err) {
                    reject(err)
                })
            }
        ) 
    }
}


module.exports = Auth
var m = require('mithril')
var global = require('../Global')
var i18n = require('../models/TranslationModel')
var user = require('../models/User')

var CookieView = {
    view: function(ctrl) {
        return m("div", {id:"cookie-fixed-container"},[
            m("div", {class:"plain-div"},[
                m("div", {class: "cookie-container"}, [
                    m("div", {class: "message-container"}, [
                        m("div", {class: "messages"}, "En naviguant sur notre site, vous acceptez l’utilisation de cookies pour vous proposer des contenus et services adaptés à vos centres d’intérêts."),
                        m("a", {class: "more-information", href:"https://www.olyrix.com/pages/cgu-cgv", target:"_blank"}, "En savoir plus.")
                    ])
                ]),
                m("div", {class:"close-button-container"},[
                    m("i", {
                        class:"fas fa-times",
                        onclick : function(){
                            user.setCookieMessage()
                        }
                    })
                ])
            ])
        ])
    }
}

module.exports = CookieView
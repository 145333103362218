var m = require('mithril')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')

var WidgetLoader = {
    onbeforeremove : function(vnode){
        vnode.dom.classList.add('exit-widget-loader')
        return utils.waitBeforeElementUpdate(vnode.dom)
        
    },
    view: function() {
        return m("div", {class : "widget-loader-container"}, [
            m("div", {
                class: "loader-background",
                style: "width:" + eventHelper.widgetLoader.getProgress() + "%;"
            })
        ])
    }
}

module.exports = WidgetLoader

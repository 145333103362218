var m = require('mithril')
var navModel = require('../models/Nav')
var global = require('../Global')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')
var user = require('../models/User')
var model = require('../models/EditLyricographieModel')
var infoDiv = require('../components/InfoDiv')
var Loader = require('../components/WidgetLoader')
var i18n = require('../models/TranslationModel')
var auth = require('../services/Auth')

var EditLyrico = {
    oninit: function(vnode){
        EditLyrico.isShow = vnode.attrs.isShow
    },
    oncreate : function(vnode){
        model.current.repriseId = vnode.attrs.repriseId
        model.current.oeuvreId = vnode.attrs.oeuvreId
        model.current.productionId = vnode.attrs.productionId
    },
    onbeforeremove : function(e){
        model.cleanAllForm()
        var bgElement = document.getElementById('background-popup-k')
        bgElement.classList.add('fadeOut')
        var popup = e.dom.getElementsByClassName("popup-widget-bg")[0]
        popup.classList.add("big-popup-exit")
        return Promise.all([
            utils.waitBeforeElementUpdate(popup),
            utils.waitBeforeElementUpdate(bgElement)
        ])
    },
    view: function() {
        return m("div", {class:"plain-container"},[
            m("div", {class:"relative-div"}, [
                m("div", {class:"popup-widget-bg"},[
                    m("div", {class:"header"}, [
                        m("span", {class:"title-popup"},[
                            m("h3", {class:"text"}, i18n.cur.home_lyrico_edit)
                        ]),
                        m("span", {
                            class:"ico-container",
                            onclick : function(){
                                EditLyrico.isShow(false)
                                model._backendErrorMessages = []

                            }
                        },[
                            m("i", {class:"fas fa-times"})
                        ])
                    ]),
                    m("div", {class:"send-edit-form"},[
                        m('form', { onsubmit: model.Post, name:"send-form"},[
    
                            // TEXT AREA
        
                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('span', i18n.cur.home_lyrico_note)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('textarea', {
                                        placeholder : i18n.cur.home_lyrico_ppp_plh_desc,
                                        class : 'input_edit_explanation textarea',
                                        name : 'explanation',
                                        onfocusout: function(e) {
                                            model.check.explanation(e.target.value)
                                        }
                                    }),
                                    model.getInputStatesIndicator('explanation')
                                ]),
                                model.getInputErrorMessage('explanation')
                            ]),
 
                            // EVIDENCE
        
                            m('div', {class:"input-section border-bottom-input-file input-type-file"},[
                                m('div', {class:"title"},[
                                    m('span', i18n.cur.home_lyrico_reference)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('input[multiple]', {
                                        type : 'file',
                                        class : 'input_files_evidence file_input',
                                        name : 'input_evidence',
                                        onchange: function(e) {
                                            var form = utils.findAncestor(e.target, "send-edit-form")
                                            model.check.file(e.target.files, form)
                                        }
                                    }),
                                    m('label', {for:'input_pictures'},[
        
                                        m('div', {class:"browse"}, [
                                            m('span', i18n.cur.home_lyrico_browse)
                                        ]),
                                        m('span', {class:"file-name"}, [
                                            m('span', {class:'italic files-name edit-picture-text'}, i18n.cur.home_lyrico_down_files)
                                        ])
                                    ]),
                                    model.getInputStatesIndicator('file')
                                ]),
                                model.getInputErrorMessage('file'),
                                m("div", {class:"text-multiple-choice"}, [
                                    m("div", {class:"text-center"}, i18n.cur.home_lyrico_and_or)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-link'})
                                    ]),
                                    m('input', {
                                        type : 'text',
                                        placeholder : i18n.cur.home_lyrico_ppp_plh_external_link,
                                        class : 'input_link',
                                        name : 'link',
                                        onfocusout: function(e) {
                                            model.check.link(e.target.value)
                                        }
                                    }),
                                    model.getInputStatesIndicator('link')
                                ]),
                                model.getInputErrorMessage('link')
                            ]),

                            // SUBMIT BUTTON
        
                            m('div', {class:"input-section clearfix submit-section"}, [
                                m('div', {class:"submit-button right"},[
                                    m('input', {
                                        type: 'submit',
                                        value: i18n.cur.home_lyrico_send,
                                        onclick: function(e){
                                            e.preventDefault()
                                            EditLyrico.post(e)
                                        }
                                    })
                                ])
                            ])
                        ])
                    ]),
                    model.getAllBackendError(),
                    m("div", {}, [
                        m(infoDiv, {text : i18n.cur.home_lyrico_ppp_info})
                    ]),
                    EditLyrico.getLoader()
                ]),
                m(EditLyrico.BackgroundNav)
            ])
        ])
    },
    post : function(e){
        EditLyrico.toggleLoader()
        model.post(e, auth.getJWT()).then(function(res){
            console.log(res)
            eventHelper.widgetLoader.setProgress(5)
            // FAKE LOADER
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(40)
            }, 500)
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(100)
                
            }, 800)
            setTimeout(function(){
                EditLyrico.toggleLoader()
                EditLyrico.isShow(false)
            }, 1200)
        }).catch(function(err){
            console.log(err)
            EditLyrico.toggleLoader()
        })
    },
    getLoader : function(){
        return eventHelper.widgetLoader.get() ? m(Loader) : ''
    },
    toggleLoader : function(){
        eventHelper.widgetLoader.set(!eventHelper.widgetLoader.get())
    },
}

EditLyrico.BackgroundNav = {
    view : function(ctrl){
        return m("div", {
            id: 'background-popup-k',
            class: 'fadeIn', //by default we show with fadeIn
            onclick : function(){
                EditLyrico.isShow(false)
                model._backendErrorMessages = []
            }
        })
    }
}

module.exports = EditLyrico

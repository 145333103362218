var m = require('mithril')
var key = require('../helpers/TranslationKey')
var auth = require('../services/Auth')
var Global = require('../Global')
var utils = require('../helpers/Utils')

var translation = {
    langs : {
        FR : 'fr',
        EN : 'en',
        ES : 'es',
        DE : 'de',
        IT : 'it'
    },

    cur : {}, // cur -> current

    getLang : function(){
        var lang = null
        if (localStorage.getItem('lang') === null) {
            var navigatorLang = navigator.language || navigator.userLanguage
            lang = navigatorLang.split('-')[0]
        }else{
            lang = localStorage.getItem('lang')
        }
        return lang
    },
    _refreshLang(){
        //this.loadAll().then(function(){
            setTimeout(function(){
                location.reload()
            }, 200)
        //})
    },
    setLang(lang){// lang need to be like this "fr" or "en"
        localStorage.setItem('lang', lang)
        this._refreshLang()
    },
    loadAll : function(){
        return new Promise(function(resolve, reject){
            var allObjectKey = []
            key.getAll().map(item => { // we get all key exist on prolyrix
                allObjectKey.push({translation_key : item}) // and we add translation_key like back-end need
            })
            m.request({
                method: 'POST',
                url: Global.api.translation,
                body : {
                    locale : translation.getLang(),
                    translations : allObjectKey
                },
                extract : function(xhr) {return {status: xhr.status, body: JSON.parse(xhr.responseText)}},
                headers : {
                    'Content-Type' : 'application/json',
                    Authorization: "Bearer " + auth.getJWT(),
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                if(res.status === 200){
                    var currentTrad = {}
                    Object.entries(res.body).map(item => {
                        if(item !== null){
                            currentTrad = Object.assign(currentTrad, {[item[1].translation_key] : item[1].value})// we create a big object who contains all trad with the good key
                        }
                    })
                    translation.cur = currentTrad
                    translation.cur.locale = translation.getLang()
                    resolve()
                }else{
                    reject(res)
                }
            })
            .catch(function(err) {
                console.log(err)
                reject()
            })
        })
    },
    getList : function(stringToSearch){
        return new Promise(function(resolve, reject){
            var objectKey = []
            objectKey.push({translation_key : stringToSearch, list: true})
            m.request({
                method: 'POST',
                url: Global.api.translation,
                body : {
                    locale : translation.getLang(),
                    translations : objectKey
                },
                extract : function(xhr) {return {status: xhr.status, body: JSON.parse(xhr.responseText)}},
                headers : {
                    'Content-Type' : 'application/json',
                    Authorization: "Bearer " +  auth.getJWT(),
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                if(res.status === 200){
                    var list = {}
                    Object.entries(res.body).map(item => {
                        if(item !== null){
                            list = Object.assign(list, {[item[1].translation_key] : item[1].value})// we create a big object who contains all trad with the good key
                        }
                    })
                    
                    resolve(utils.convertTranslationList(list))
                    
                }else{
                    reject(res)
                }
            })
            .catch(function(err) {
                console.log(err)
                reject()
            })
        })
    },
    getFRListTranslation(stringToSearch){
        return new Promise(function(resolve, reject){
            var key = [{translation_key : stringToSearch, list: true}]
            m.request({
                method: 'POST',
                url: Global.api.translation,
                body : {
                    locale : 'fr',
                    translations : key
                },
                extract : function(xhr) {return {status: xhr.status, body: JSON.parse(xhr.responseText)}},
                headers : {
                    'Content-Type' : 'application/json',
                    Authorization: "Bearer " + auth.getJWT(),
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                if(res.status === 200){
                    var list = {}
                    Object.entries(res.body).map(item => {
                        if(item !== null){
                            list = Object.assign(list, {[item[1].translation_key] : item[1].value})// we create a big object who contains all trad with the good key
                        }
                    })
                    resolve(utils.convertTranslationList(list))
                    
                }else{
                    reject(res)
                }
            })
            .catch(function(err) {
                console.log(err)
                reject(err)
            })
        })
    },
    
}


module.exports = translation
var m = require('mithril')
var navModel = require('../models/Nav')
var global = require('../Global')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')
var user = require('../models/User')
var model = require('../models/AddPictureModel')
var infoDiv = require('../components/InfoDiv')
var Loader = require('../components/WidgetLoader')
var i18n = require('../models/TranslationModel')

var AddPicture = {
    isClickable : true,
    oninit: function(vnode){
        AddPicture.isShow = vnode.attrs.isShow
        if(vnode.attrs.fileSelected !== null || vnode.attrs.fileSelected !== undefined){
            AddPicture.fileSelected = vnode.attrs.fileSelected
        }
    },
    onbeforeremove : function(e){
        model.cleanAllForm()
        var bgElement = document.getElementById('background-popup-k')
        bgElement.classList.add('fadeOut')
        var popup = e.dom.getElementsByClassName("popup-widget-bg")[0]
        popup.classList.add("big-popup-exit")
        return Promise.all([
            utils.waitBeforeElementUpdate(popup),
            utils.waitBeforeElementUpdate(bgElement)
        ])
    },
    oncreate : function(vnode){
        if(vnode.attrs.fileSelected !== null){
            var form = vnode.dom.getElementsByClassName("send-form")[0]
            form.getElementsByClassName('input_pictures')[0].files = vnode.attrs.fileSelected
            model.check.file(form.getElementsByClassName('input_pictures')[0].files, form)
            m.redraw()
        }
    },
    view: function() {
        return m("div", {class:"plain-container"},[
            m("div", {class:"relative-div"}, [
                m("div", {class:"popup-widget-bg"},[
                    m("div", {class:"header"}, [
                        m("span", {class:"title-popup"},[
                            m("h3", {class:"text"}, i18n.cur.home_mypic_picture_add)
                        ]),
                        m("span", {
                            class:"ico-container",
                            onclick : function(){
                                AddPicture.isShow(false)
                            }
                        },[
                            m("i", {class:"fas fa-times"})
                        ])
                    ]),
                    m("div", {class:"send-form"},[
                        m('form', { onsubmit: model.Post, name:"send-form"},[
    
                            // FILES
        
                            m('div', {class:"input-section border-bottom-input-file input-type-file"},[
                                m('div', {class:"title"},[
                                    m('span', i18n.cur.home_mypic_ppp_select_img),
                                    m('span', {class:"small"}, " " + i18n.cur.home_mypic_ppp_required)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('input[multiple]', {
                                        type : 'file',
                                        class : 'input_pictures file_input',
                                        name : 'input_pictures',
                                        onchange: function(e) {
                                            var form = utils.findAncestor(e.target, "send-form")
                                            model.check.file(e.target.files, form)
                                        }
                                    }),
                                    m('label', {for:'input_pictures'},[
        
                                        m('div', {class:"browse"}, [
                                            m('span', i18n.cur.home_mypic_ppp_browser)
                                        ]),
                                        m('span', {class:"file-name"}, [
                                            m('span', {class:'italic files-name'}, i18n.cur.home_mypic_ppp_files_download)
                                        ])
                                    ]),
                                    model.getInputStatesIndicator('file')
                                ]),
                                model.getInputErrorMessage('file')
                            ]),
    
                            // TITLE PICTURE
        
                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('span', "Titre de la photo")
                                ]),
                                m('div', {class: 'input-item no-ico'},[
                                    
                                    m('input', {
                                        type : 'text',
                                        placeholder : "Nuit d'automne",
                                        class : 'input_title_picture',
                                        name : 'titlePicture',
                                        onfocusout: function(e) {
                                            model.check.titlePicture(e.target.value)
                                        }
                                    }),
                                    model.getInputStatesIndicator('titlePicture')
                                ]),
                                model.getInputErrorMessage('titlePicture')
                            ]),
    
                            // TEXT AREA
        
                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('span', i18n.cur.home_mypic_ppp_picture_desc)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('textarea', {
                                        placeholder : i18n.cur.home_mypic_ppp_plh_desc_edit,
                                        class : 'input_description textarea',
                                        name : 'description',
                                        onfocusout: function(e) {
                                            model.check.description(e.target.value)
                                        }
                                    }),
                                    model.getInputStatesIndicator('description')
                                ]),
                                model.getInputErrorMessage('description')
                            ]),
        
                            // CREDITS 
        
                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('span', "Crédits")
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-copyright'})
                                    ]),
                                    m('input', {
                                        type : 'text',
                                        placeholder : i18n.cur.home_mypic_ppp_plh_credit,
                                        class : 'input_credit',
                                        name : 'credit',
                                        onfocusout: function(e) {
                                            model.check.credit(e.target.value)
                                        }
                                    }),
                                    model.getInputStatesIndicator('credit')
                                ]),
                                model.getInputErrorMessage('credit')
                            ]),                        
        
                            // SUBMIT BUTTON
        
                            m('div', {class:"input-section clearfix submit-section"}, [
                                m('div', {class:"submit-button right"},[
                                    m('input', {
                                        id: 'register-input',
                                        type: 'submit',
                                        value: i18n.cur.home_mypic_ppp_picture_add_button,
                                        onclick: function(e){
                                            e.preventDefault()
                                            if(AddPicture.isClickable){
                                                AddPicture.isClickable = false
                                                AddPicture.post(e)
                                            }
                                        }
                                    })
                                ])
                            ])
                        ])
                    ]),
                    model.getAllBackendError(),
                    m("div", {}, [
                        m(infoDiv, {text : i18n.cur.home_mypic_ppp_info_add})
                    ]),
                    AddPicture.getLoader()
                ]),
                m(AddPicture.BackgroundNav)
            ])
        ])
    },
    post : function(e){
        AddPicture.toggleLoader()
        model.post(e).then(function(res){
            eventHelper.widgetLoader.setProgress(5)
            // FAKE LOADER
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(40)
            }, 500)
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(100)
                
            }, 1200)
            setTimeout(function(){
                AddPicture.toggleLoader()
                AddPicture.isShow(false)
                AddPicture.isClickable = true
            }, 1400)
        }).catch(function(err){
            console.log(err)
            console.log("error occured")
            AddPicture.isClickable = true
            AddPicture.toggleLoader()
        })
    },
    getLoader : function(){
        return eventHelper.widgetLoader.get() ? m(Loader) : ''
    },
    toggleLoader : function(){
        eventHelper.widgetLoader.set(!eventHelper.widgetLoader.get())
    },
}

AddPicture.BackgroundNav = {
    view : function(ctrl){
        return m("div", {
            id: 'background-popup-k',
            class: 'fadeIn', //by default we show with fadeIn
            onclick : function(){
                AddPicture.isShow(false)
            }
        })
    }
}

module.exports = AddPicture

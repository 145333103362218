var m = require('mithril')
var user = require('../models/User')
var utils = require('../helpers/Utils')


let legal = {
    oncreate : function(){
        utils.scrollToTop(500, 0) // animation during in ms, timeout in ms
    },
    getContent : function(){
        return m
        ("div", {class: "box-shadow widget cgu"}, [
            m("p", [
                m("span", "Le site "),
                m("a", {target:"_blank", href:"https://www.prolyrix.com/"}, "prolyrix.com "),
                m("span", "est édité par la société Olyrix, SAS au capital de 55 000 €, dont le siège est sis 43 rue Manessier, 94130 Nogent-sur-Marne, et immatriculée au registre du commerce et des sociétés de Créteil sous le numéro 809 143 266. Son directeur de la publication est Damien Dutilleul. La société peut être contactée à travers le formulaire de contact du site, ou à l’adresse "),
                m("a", {href:"mailto:contact@prolyrix.com"}, "contact@prolyrix.com."),
            ]),
            m("p",[
                m("span", "Le site est hébergé par la société OVH SAS, domiciliée au 2 rue Kellermann, 59100 Roubaix (France) et joignable au "),
                m("a", {href:"tel:+33972101007"}, "09 72 10 10 07."),
            ]),
        ])
    },
    view: function() {
        return m("div", {class:user.isLogged() ? "multi-statut-route" : ""}, [
            m("div", {class:"flex-center-container"}, [
                m("div", {class:"title-page m-d-none"}, [
                    m("div", {class:"title-container"}, [
                        m("div", {class:"main-title"}, 
                            m("h1", "Mentions Légales")
                        ),
                        /*m("div", {class:"second-title"}, 
                            m("h2", "Conditions générales d'utilisation")
                        )*/
                    ])
                ]),
            ]),
            m("div", {class: "content"},[
                legal.getContent()
            ])
        ])
    }
}

module.exports = legal
var m = require('mithril')
var navModel = require('../models/Nav')
var global = require('../Global')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')
var user = require('../models/User')
var model = require('../models/EditVideoModel')
var infoDiv = require('../components/InfoDiv')
var Loader = require('../components/WidgetLoader')
var i18n = require('../models/TranslationModel')
var auth = require('../services/Auth')

var EditVideo = {
    oninit: function(vnode){
        // here baseImage is video
        EditVideo.isShow = vnode.attrs.isShow
        EditVideo.baseImage = vnode.attrs.image
        model.baseImage = vnode.attrs.image
        //model.defaultImageInputFileTitle = EditVideo.baseImage.title.length > 19 ? EditVideo.baseImage.title.substring(0,19) + "..." : EditVideo.baseImage.title

        //model.descriptionText = EditVideo.baseImage.description
        model.titleText = EditVideo.baseImage.title
    },
    oncreate : function(e){
        //e.dom.getElementsByClassName("edit-picture-text")[0].innerHTML = model.defaultImageInputFileTitle
        console.log(model.titleText)
        e.dom.getElementsByClassName("input_title_edit_picture")[0].value = model.titleText

    },
    onbeforeremove : function(e){
        model.cleanAllForm()
        var bgElement = document.getElementById('background-popup-k')
        bgElement.classList.add('fadeOut')
        var popup = e.dom.getElementsByClassName("popup-widget-bg")[0]
        popup.classList.add("big-popup-exit")
        return Promise.all([
            utils.waitBeforeElementUpdate(popup),
            utils.waitBeforeElementUpdate(bgElement)
        ])
    },
    view: function() {
        return m("div", {class:"plain-container"},[
            m("div", {class:"relative-div"}, [
                m("div", {class:"popup-widget-bg"},[
                    m("div", {class:"header"}, [
                        m("span", {class:"title-popup"},[
                            m("h3", {class:"text"}, i18n.cur.home_video_edit)
                        ]),
                        m("span", {
                            class:"ico-container",
                            onclick : function(){
                                EditVideo.isShow(false)
                            }
                        },[
                            m("i", {class:"fas fa-times"})
                        ])
                    ]),
                    m("div", {class:"send-edit-form"},[
                        m('form', { onsubmit: model.Post, name:"send-form"},[
    
                            // FILES
        
                            /*m('div', {class:"input-section border-bottom-input-file input-type-file"},[
                                m('div', {class:"title"},[
                                    m('span', i18n.cur.home_mypic_ppp_select_img)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('input[multiple]', {
                                        type : 'file',
                                        class : 'input_edit_pictures file_input',
                                        name : 'input_pictures',
                                        onchange: function(e) {
                                            var form = utils.findAncestor(e.target, "send-edit-form")
                                            model.check.file(e.target.files, form)
                                        }
                                    }),
                                    m('label', {for:'input_pictures'},[
        
                                        m('div', {class:"browse"}, [
                                            m('span', i18n.cur.home_mypic_ppp_browser)
                                        ]),
                                        m('span', {class:"file-name"}, [
                                            m('span', {class:'italic files-name edit-picture-text'}, i18n.cur.home_mypic_ppp_files_download)
                                        ])
                                    ]),
                                    model.getInputStatesIndicator('file')
                                ]),
                                model.getInputErrorMessage('file')
                            ]),*/
    
                            // VIDEO TITLE
        
                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('span', i18n.cur.home_mymov_video_title)
                                ]),
                                m('div', {class: 'input-item no-ico'},[
                                    
                                    m('input', {
                                        type : 'text',
                                        placeholder : i18n.cur.home_mymov_plh_title,
                                        class : 'input_title_edit_picture',
                                        name : 'titlePicture',
                                        onfocusout: function(e) {
                                            model.check.titlePicture(e.target.value)
                                        }
                                    }),
                                    model.getInputStatesIndicator('titlePicture')
                                ]),
                                model.getInputErrorMessage('titlePicture')
                            ]),
    
                            // TEXT AREA
        
                            /*m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('span', i18n.cur.home_mypic_ppp_picture_desc)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('textarea', {
                                        placeholder : i18n.cur.home_mypic_ppp_plh_desc,
                                        class : 'input_edit_description textarea',
                                        name : 'description',
                                        onfocusout: function(e) {
                                            model.check.description(e.target.value)
                                        }
                                    }),
                                    model.getInputStatesIndicator('description')
                                ]),
                                model.getInputErrorMessage('description')
                            ]),*/
        
                            // CREDITS PAS DE CREDIT POUR VIDEO
        
                            /*m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('span', i18n.cur.home_mypic_ppp_picture_credit)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-copyright'})
                                    ]),
                                    m('input', {
                                        type : 'text',
                                        placeholder : i18n.cur.home_mypic_ppp_plh_credit,
                                        class : 'input_edit_credit',
                                        name : 'credit',
                                        onfocusout: function(e) {
                                            model.check.credit(e.target.value)
                                        }
                                    }),
                                    model.getInputStatesIndicator('credit')
                                ]),
                                model.getInputErrorMessage('credit')
                            ]),*/                        
        
                            // SUBMIT BUTTON
        
                            m('div', {class:"input-section clearfix submit-section"}, [
                                m('div', {class:"submit-button right"},[
                                    m('input', {
                                        id: 'register-input',
                                        type: 'submit',
                                        value: i18n.cur.home_mypic_ppp_save,
                                        onclick: function(e){
                                            e.preventDefault()
                                            EditVideo.post(e)
                                        }
                                    })
                                ])
                            ])
                        ])
                    ]),
                    model.getAllBackendError(),
                    m("div", {}, [
                        m(infoDiv, {text : i18n.cur.home_mypic_ppp_info})
                    ]),
                    EditVideo.getLoader()
                ]),
                m(EditVideo.BackgroundNav)
            ])
        ])
    },
    post : function(e){
        EditVideo.toggleLoader()
        model.post(e, auth.getJWT()).then(function(res){
            eventHelper.widgetLoader.setProgress(5)
            // FAKE LOADER
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(40)
            }, 500)
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(100)
                
            }, 900)
            setTimeout(function(){
                EditVideo.toggleLoader()
                EditVideo.isShow(false)
            }, 1300)
        }).catch(function(err){
            console.log(err)
            EditVideo.toggleLoader()
        })
    },
    getLoader : function(){
        return eventHelper.widgetLoader.get() ? m(Loader) : ''
    },
    toggleLoader : function(){
        eventHelper.widgetLoader.set(!eventHelper.widgetLoader.get())
    },
}

EditVideo.BackgroundNav = {
    view : function(ctrl){
        return m("div", {
            id: 'background-popup-k',
            class: 'fadeIn', //by default we show with fadeIn
            onclick : function(){
                EditVideo.isShow(false)
            }
        })
    }
}

module.exports = EditVideo

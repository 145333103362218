var m = require('mithril')

var EventHelper = {}

EventHelper.leftNav = {
    _isShow : false,
    set : function(o){
        EventHelper.leftNav._isShow = o
    },
    get : function(){
        return EventHelper.leftNav._isShow
    }
}

EventHelper.langNav = {
    _isShow : false,
    set : function(o){
        EventHelper.langNav._isShow = o
    },
    get : function(){
        return EventHelper.langNav._isShow
    }
}

EventHelper.CookiesMessage = {
    _isShow : false,
    set : function(o){
        EventHelper.CookiesMessage._isShow = o
    },
    get : function(){
        return EventHelper.CookiesMessage._isShow
    }
}

EventHelper.userNav = {
    _isShow : false,
    set : function(o){
        EventHelper.userNav._isShow = o
    },
    get : function(){
        return EventHelper.userNav._isShow
    }
}

EventHelper.widgetLoader = {
    _isShow : false,
    _progress : 0,
    set : function(o){
        EventHelper.widgetLoader._isShow = o
        m.redraw()
    },
    get : function(){
        return EventHelper.widgetLoader._isShow
    },
    setProgress: function(p){
        EventHelper.widgetLoader._progress = p
        m.redraw()
    },
    getProgress: function(){
        return EventHelper.widgetLoader._progress
    }
}

EventHelper.removeAccountPopup = {
    _isShow : false,
    set : function(o){
        EventHelper.removeAccountPopup._isShow = o
    },
    get : function(){
        return EventHelper.removeAccountPopup._isShow
    }
}

EventHelper.removePrononciationPopup = {
    _isShow : false,
    set : function(o){
        EventHelper.removePrononciationPopup._isShow = o
    },
    get : function(){
        return EventHelper.removePrononciationPopup._isShow
    }
}

EventHelper.updateWidgetNotif = {
    _isShow : false,
    set : function(o){
        EventHelper.updateWidgetNotif._isShow = o
    },
    get : function(){
        return EventHelper.updateWidgetNotif._isShow
    }
}
EventHelper.updatePassWidgetNotif = {
    _isShow : false,
    set : function(o){
        EventHelper.updatePassWidgetNotif._isShow = o
    },
    get : function(){
        return EventHelper.updatePassWidgetNotif._isShow
    }
}



module.exports = EventHelper
let json = {
    range : [
        {id : 1 , value :'Basse'},
        {id : 2 , value :'Baryton'},
        {id : 3 , value :'Ténor'},
        {id : 4 , value :'Mezzo-soprano'},
        {id : 5 , value :'Soprano'},
        {id : 6 , value :'Basse profonde'},
        {id : 7 , value :'Basse bouffe'},
        {id : 8 , value :'Baryton-Basse'},
        {id : 9 , value :'Baryton-Martin'},
        {id : 10, value :'Ténor léger'},
        {id : 11, value :'Ténor lyrique'},
        {id : 12, value :'Ténor dramatique'},
        {id : 13, value :'Contralto'},
        {id : 14, value :'Alto'},
        {id : 15, value :'Mezzo-soprano léger'},
        {id : 16, value :'Mezzo-soprano lyrique'},
        {id : 17, value :'Mezzo-soprano dramatique'},
        {id : 18, value :'Soprano colorature'},
        {id : 19, value :'Soprano léger'},
        {id : 20, value :'Soprano lyrique'},
        {id : 21, value :'Soprano dramatique'},
        {id : 22, value :'Contre-ténor'},
        {id : 23, value :'Soprano spinto'},
        {id : 24, value :'Haute-contre'},
        {id : 25, value :'Basse chantante'},
        {id : 26, value :'Baryton dramatique'},
        // 27 , 'Basse profonde', // Tessiture dupliquée
        {id : 28, value :'Baryton lyrique'},
        {id : 29, value :'Rôle parlé'},
        {id : 30, value :'Rôle muet'},
        {id : 31, value :'Basse-taille'},
        {id : 32, value :'Basse dramatique'},
    ],
    countries : [
        {code:'93', country:'Afghanistan 93'},
        {code:'355', country:'Albania 355'},
        {code:'213', country:'Algeria 213'},
        {code:'1-684', country:'American Samoa 1-684'},
        {code:'376', country:'Andorra 376'},
        {code:'244', country:'Angola 244'},
        {code:'1-264', country:'Anguilla 1-264'},
        {code:'672', country:'Antarctica 672'},
        {code:'1-268', country:'Antigua and Barbuda 1-268'},
        {code:'54', country:'Argentina 54'},
        {code:'374', country:'Armenia 374'},
        {code:'297', country:'Aruba 297'},
        {code:'61', country:'Australia 61'},
        {code:'43', country:'Austria 43'},
        {code:'994', country:'Azerbaijan 994'},
        {code:'1-242', country:'Bahamas 1-242'},
        {code:'973', country:'Bahrain 973'},
        {code:'880', country:'Bangladesh 880'},
        {code:'1-246', country:'Barbados 1-246'},
        {code:'375', country:'Belarus 375'},
        {code:'32', country:'Belgium 32'},
        {code:'501', country:'Belize 501'},
        {code:'229', country:'Benin 229'},
        {code:'1-441', country:'Bermuda 1-441'},
        {code:'975', country:'Bhutan 975'},
        {code:'591', country:'Bolivia 591'},
        {code:'387', country:'Bosnia and Herzegovina 387'},
        {code:'267', country:'Botswana 267'},
        {code:'55', country:'Brazil 55'},
        {code:'246', country:'British Indian Ocean Territory 246'},
        {code:'1-284', country:'British Virgin Islands 1-284'},
        {code:'673', country:'Brunei 673'},
        {code:'359', country:'Bulgaria 359'},
        {code:'226', country:'Burkina Faso 226'},
        {code:'257', country:'Burundi 257'},
        {code:'855', country:'Cambodia 855'},
        {code:'237', country:'Cameroon 237'},
        {code:'1', country:'Canada 1'},
        {code:'238', country:'Cape Verde 238'},
        {code:'1-345', country:'Cayman Islands 1-345'},
        {code:'236', country:'Central African Republic 236'},
        {code:'235', country:'Chad 235'},
        {code:'56', country:'Chile 56'},
        {code:'86', country:'China 86'},
        {code:'61', country:'Christmas Island 61'},
        {code:'61 CC', country:'Cocos Islands 61 CC'},
        {code:'57', country:'Colombia 57'},
        {code:'269', country:'Comoros 269'},
        {code:'682', country:'Cook Islands 682'},
        {code:'506', country:'Costa Rica 506'},
        {code:'385', country:'Croatia 385'},
        {code:'53', country:'Cuba 53'},
        {code:'599', country:'Curacao 599'},
        {code:'357', country:'Cyprus 357'},
        {code:'420', country:'Czech Republic 420'},
        {code:'243', country:'Democratic Republic of the Congo 243'},
        {code:'45', country:'Denmark 45'},
        {code:'253', country:'Djibouti 253'},
        {code:'1-767', country:'Dominica 1-767'},
        {code:'1-809', country:'Dominican Republic 1-809'},
        {code:'1-829', country:'Dominican Republic 1-829'},
        {code:'1-849', country:'Dominican Republic 1-849'},
        {code:'670', country:'East Timor 670'},
        {code:'593', country:'Ecuador 593'},
        {code:'20', country:'Egypt 20'},
        {code:'503', country:'El Salvador 503'},
        {code:'240', country:'Equatorial Guinea 240'},
        {code:'291', country:'Eritrea 291'},
        {code:'372', country:'Estonia 372'},
        {code:'251', country:'Ethiopia 251'},
        {code:'500', country:'Falkland Islands 500'},
        {code:'298', country:'Faroe Islands 298'},
        {code:'679', country:'Fiji 679'},
        {code:'358', country:'Finland 358'},
        {code:'33', country:'France 33'},
        {code:'689', country:'French Polynesia 689'},
        {code:'241', country:'Gabon 241'},
        {code:'220', country:'Gambia 220'},
        {code:'995', country:'Georgia 995'},
        {code:'49', country:'Germany 49'},
        {code:'233', country:'Ghana 233'},
        {code:'350', country:'Gibraltar 350'},
        {code:'30', country:'Greece 30'},
        {code:'299', country:'Greenland 299'},
        {code:'1-473', country:'Grenada 1-473'},
        {code:'1-671', country:'Guam 1-671'},
        {code:'502', country:'Guatemala 502'},
        {code:'44-1481', country:'Guernsey 44-1481'},
        {code:'224', country:'Guinea 224'},
        {code:'245', country:'Guinea-Bissau 245'},
        {code:'592', country:'Guyana 592'},
        {code:'509', country:'Haiti 509'},
        {code:'504', country:'Honduras 504'},
        {code:'852', country:'Hong Kong 852'},
        {code:'36', country:'Hungary 36'},
        {code:'354', country:'Iceland 354'},
        {code:'91', country:'India 91'},
        {code:'62', country:'Indonesia 62'},
        {code:'98', country:'Iran 98'},
        {code:'964', country:'Iraq 964'},
        {code:'353', country:'Ireland 353'},
        {code:'44-1624', country:'Isle of Man 44-1624'},
        {code:'972', country:'Israel 972'},
        {code:'39', country:'Italy 39'},
        {code:'225', country:'Ivory Coast 225'},
        {code:'1-876', country:'Jamaica 1-876'},
        {code:'81', country:'Japan 81'},
        {code:'44-1534', country:'Jersey 44-1534'},
        {code:'962', country:'Jordan 962'},
        {code:'7', country:'Kazakhstan 7'},
        {code:'254', country:'Kenya 254'},
        {code:'686', country:'Kiribati 686'},
        {code:'383', country:'Kosovo 383'},
        {code:'965', country:'Kuwait 965'},
        {code:'996', country:'Kyrgyzstan 996'},
        {code:'856', country:'Laos 856'},
        {code:'371', country:'Latvia 371'},
        {code:'961', country:'Lebanon 961'},
        {code:'266', country:'Lesotho 266'},
        {code:'231', country:'Liberia 231'},
        {code:'218', country:'Libya 218'},
        {code:'423', country:'Liechtenstein 423'},
        {code:'370', country:'Lithuania 370'},
        {code:'352', country:'Luxembourg 352'},
        {code:'853', country:'Macau 853'},
        {code:'389', country:'Macedonia 389'},
        {code:'261', country:'Madagascar 261'},
        {code:'265', country:'Malawi 265'},
        {code:'60', country:'Malaysia 60'},
        {code:'960', country:'Maldives 960'},
        {code:'223', country:'Mali 223'},
        {code:'356', country:'Malta 356'},
        {code:'692', country:'Marshall Islands 692'},
        {code:'222', country:'Mauritania 222'},
        {code:'230', country:'Mauritius 230'},
        {code:'262', country:'Mayotte 262'},
        {code:'52', country:'Mexico 52'},
        {code:'691', country:'Micronesia 691'},
        {code:'373', country:'Moldova 373'},
        {code:'377', country:'Monaco 377'},
        {code:'976', country:'Mongolia 976'},
        {code:'382', country:'Montenegro 382'},
        {code:'1-664', country:'Montserrat 1-664'},
        {code:'212', country:'Morocco 212'},
        {code:'258', country:'Mozambique 258'},
        {code:'95', country:'Myanmar 95'},
        {code:'264', country:'Namibia 264'},
        {code:'674', country:'Nauru 674'},
        {code:'977', country:'Nepal 977'},
        {code:'31', country:'Netherlands 31'},
        {code:'599', country:'Netherlands Antilles 599'},
        {code:'687', country:'New Caledonia 687'},
        {code:'64', country:'New Zealand 64'},
        {code:'505', country:'Nicaragua 505'},
        {code:'227', country:'Niger 227'},
        {code:'234', country:'Nigeria 234'},
        {code:'683', country:'Niue 683'},
        {code:'850', country:'North Korea 850'},
        {code:'1-670', country:'Northern Mariana Islands 1-670'},
        {code:'47', country:'Norway 47'},
        {code:'968', country:'Oman 968'},
        {code:'92', country:'Pakistan 92'},
        {code:'680', country:'Palau 680'},
        {code:'970', country:'Palestine 970'},
        {code:'507', country:'Panama 507'},
        {code:'675', country:'Papua New Guinea 675'},
        {code:'595', country:'Paraguay 595'},
        {code:'51', country:'Peru 51'},
        {code:'63', country:'Philippines 63'},
        {code:'64 PN', country:'Pitcairn 64 PN'},
        {code:'48', country:'Poland 48'},
        {code:'351', country:'Portugal 351'},
        {code:'1-787, 1-939', country:'Puerto Rico 1-787, 1-939'},
        {code:'974', country:'Qatar 974'},
        {code:'242', country:'Republic of the Congo 242'},
        {code:'262', country:'Reunion 262'},
        {code:'40', country:'Romania 40'},
        {code:'7', country:'Russia 7'},
        {code:'250', country:'Rwanda 250'},
        {code:'590', country:'Saint Barthelemy 590'},
        {code:'290', country:'Saint Helena 290'},
        {code:'1-869', country:'Saint Kitts and Nevis 1-869'},
        {code:'1-758', country:'Saint Lucia 1-758'},
        {code:'590', country:'Saint Martin 590'},
        {code:'508', country:'Saint Pierre and Miquelon 508'},
        {code:'1-784', country:'Saint Vincent and the Grenadines 1-784'},
        {code:'685', country:'Samoa 685'},
        {code:'378', country:'San Marino 378'},
        {code:'239', country:'Sao Tome and Principe 239'},
        {code:'966', country:'Saudi Arabia 966'},
        {code:'221', country:'Senegal 221'},
        {code:'381', country:'Serbia 381'},
        {code:'248', country:'Seychelles 248'},
        {code:'232', country:'Sierra Leone 232'},
        {code:'65', country:'Singapore 65'},
        {code:'1-721', country:'Sint Maarten 1-721'},
        {code:'421', country:'Slovakia 421'},
        {code:'386', country:'Slovenia 386'},
        {code:'677', country:'Solomon Islands 677'},
        {code:'252', country:'Somalia 252'},
        {code:'27', country:'South Africa 27'},
        {code:'82', country:'South Korea 82'},
        {code:'211', country:'South Sudan 211'},
        {code:'34', country:'Spain 34'},
        {code:'94', country:'Sri Lanka 94'},
        {code:'249', country:'Sudan 249'},
        {code:'597', country:'Suriname 597'},
        {code:'47', country:'Svalbard and Jan Mayen 47'},
        {code:'268', country:'Swaziland 268'},
        {code:'46', country:'Sweden 46'},
        {code:'41', country:'Switzerland 41'},
        {code:'963', country:'Syria 963'},
        {code:'886', country:'Taiwan 886'},
        {code:'992', country:'Tajikistan 992'},
        {code:'255', country:'Tanzania 255'},
        {code:'66', country:'Thailand 66'},
        {code:'228', country:'Togo 228'},
        {code:'690', country:'Tokelau 690'},
        {code:'676', country:'Tonga 676'},
        {code:'1-868', country:'Trinidad and Tobago 1-868'},
        {code:'216', country:'Tunisia 216'},
        {code:'90', country:'Turkey 90'},
        {code:'993', country:'Turkmenistan 993'},
        {code:'1-649', country:'Turks and Caicos Islands 1-649'},
        {code:'688', country:'Tuvalu 688'},
        {code:'1-340', country:'U.S. Virgin Islands 1-340'},
        {code:'256', country:'Uganda 256'},
        {code:'380', country:'Ukraine 380'},
        {code:'971', country:'United Arab Emirates 971'},
        {code:'44', country:'United Kingdom 44'},
        {code:'1', country:'United States 1'},
        {code:'598', country:'Uruguay 598'},
        {code:'998', country:'Uzbekistan 998'},
        {code:'678', country:'Vanuatu 678'},
        {code:'379 VA', country:'Vatican 379 VA'},
        {code:'58', country:'Venezuela 58'},
        {code:'84', country:'Vietnam 84'},
        {code:'681', country:'Wallis and Futuna 681'},
        {code:'212', country:'Western Sahara 212'},
        {code:'967', country:'Yemen 967'},
        {code:'260', country:'Zambia 260'},
        {code:'263', country:'Zimbabwe 263'},
    ]
}

module.exports = json
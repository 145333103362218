var m = require('mithril')
var utils = require('../helpers/Utils')
var auth = require('../services/Auth')
var Loader = require('../components/WidgetLoader')
var eventHelper = require('../helpers/EventHelper')
var i18n = require('../models/TranslationModel')
var user = require('../models/User')


var model = {
    _formStates : {// state -1 = initial / state 0 = error / state 1 = valid
        file1 : {state : -1},
        //file2 : {state : -1}
    },
    current : {},
    backendSuccessMessages : [],
    backendErrorsMessages : [],
    getBackendResponse : function(){
        var SuccessMessages = model.backendSuccessMessages
        var ErrorsMessages = model.backendErrorsMessages
        var successMessageExist = Boolean(typeof SuccessMessages !== 'undefined' && SuccessMessages.length > 0)
        var errorsMessageExist = Boolean(typeof ErrorsMessages !== 'undefined' && ErrorsMessages.length > 0)
        var successView;
        var errorsView;
        if(successMessageExist){
            successView = model.getSuccessMessagesView(SuccessMessages)
        }
        if(errorsMessageExist){
            errorsView = model.getErrorsMessagesView(ErrorsMessages)
        }
        if(successMessageExist || errorsMessageExist){
            return m("div", {class:"response-message"}, [
                successMessageExist ? successView : '',
                errorsMessageExist ? errorsView : ''
            ])
        }else{
            return null
        }
    },
    postAction: function(e){
        return new Promise(
            function(resolve, reject) {
            if(model.checkAllInput()){
                var formData = new FormData()

                for (var i = 0; i < e.target.form[0].files.length; i++) {
                    formData.append("file[]", e.target.form[0].files[i])
                }

                /*for (var i = 0; i < e.target.form[1].files.length; i++) {
                    formData.append("file[]", e.target.form[1].files[i])
                }*/

                m.request({
                    method: "POST",
                    url: "api/users/" + user.current.user.id + "/verify",
                    body: formData,
                    serialize: function(value) {return value},
                    headers : {
                        Authorization: "Bearer " + auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                    config: function(xhr) {
                        xhr.upload.addEventListener("progress", function(e) {
                            var progress = e.loaded / e.total
                            eventHelper.widgetLoader.setProgress(progress)
                        })
                    }
                }).then(function(res) {
                    user.current.user.redirectStatus = "1"
                    model.setBackendSuccessMessages(["Documents reçus ✔️"])
                    eventHelper.widgetLoader.setProgress(100)
                    setTimeout(function(){
                        user.redirectOnRoute(user.current.user.redirectStatus)
                        resolve(res)
                    }, 1500)
                }).catch(function(err) {
                    reject(err)
                    model.setBackendErrorsMessages([err.response.message])
                })
            }else{
                reject()
            }
        })
    },
    clearForm : function(){
        var form = document.getElementsByClassName('lost-password-form')[0]
        form.getElementsByClassName('input_email')[0].value = ""
    },
    setBackendSuccessMessages : function(messages){
        model.backendErrorsMessages = []
        model.backendSuccessMessages = messages
    },
    setBackendErrorsMessages : function(messages){
        model.backendSuccessMessages = []
        model.backendErrorsMessages = messages
    },
    getSuccessMessagesView(messages){
        return m('div', [
            messages.map(x => m("span", {class:"response-text success"}, x))
        ])
    },
    getErrorsMessagesView(messages){
        return m('div', [
            messages.map(x => m("span", {class:"response-text error"}, x))
        ])
    },
    checkAllInput : function(){
        var form = document.getElementsByClassName('lost-password-form')[0]
        //model.check.password(form.getElementsByClassName('input_password')[0].value)
        //model.check.password_confirmation(form.getElementsByClassName('input_password_confirmation')[0].value)
        return !model.haveError()
    },
    haveError: function(){
        var errorUccured = true
        
        if(model._formStates.file1.state === 1)
        {
            errorUccured = false
        }

        return errorUccured
    },
    setFormStates : function (newFormStates){
        model._formStates = newFormStates
    },
    getFormStates : function (){
        return model._formStates
    },
    getInputErrorMessage : function(stringEnum){
        var message = model.getInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getInputStatesIndicator : function(stringEnum){
        var state = model.getInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getInputStates : function(stringEnum){
        var o
        switch (stringEnum) {
            case 'file1':
                o = model._formStates.file1
            break;
            /*case 'file2':
                o = model._formStates.file2
            break;*/
            default:
                o = null
        }
        return o
    },
    submit : function(){
        if(model.checkAllInput()){
            console.log('action')
            model.postAction()
        }
    },
    clearFileInput(input){
        input.value = ""
        var element = input.parentNode.getElementsByClassName('files-name')[0]
        element.innerHTML = i18n.cur.verify_in_upload
    },
    check : {
        file1: function(e){
            var signupState = model.getFormStates()
            var files = e.target.files
            if(files.length >= 1){
                if(files.length > 3){
                    model.clearFileInput(e.target)
                    signupState.file1.state = 0
                    signupState.file1.message = i18n.cur.error_too_many_files.formatUnicorn([{n: "3"}])
                }else if(utils.checkLimitFileSize(files)){
                    model.clearFileInput(e.target)
                    signupState.file1.state = 0
                    signupState.file1.message = i18n.cur.error_ic_too_big.formatUnicorn([{n: "15"}])
                }else if(utils.checkIfFormatIsAuthorize(files)){
                    model.clearFileInput(e.target)
                    signupState.file1.state = 0
                    signupState.file1.message = i18n.cur.error_wrong_files_type.formatUnicorn([{filesType: "PDF,JPG,PNG"}])
                }else{
                    if(files.length === 1){
                        var element = e.target.parentNode.getElementsByClassName('files-name')[0]
                        element.innerHTML = files[0].name
                    }else{
                        var element = e.target.parentNode.getElementsByClassName('files-name')[0]
                        element.innerHTML = i18n.cur.contact_input_files_info.formatUnicorn([{n: String(files.length)}])
                    }
                    signupState.file1.state = 1
                    signupState.file1.message = null
                }
            }
            model.setFormStates(signupState)
        },
        /*file2: function(e){
            var signupState = model.getFormStates()
            var files = e.target.files
            if(files.length >= 1){
                if(files.length > 10){//limit files
                    model.clearFileInput(e.target)
                    signupState.file2.state = 0
                    signupState.file2.message = i18n.cur.error_too_many_files.formatUnicorn([{n: "10"}])
                }else if(utils.checkLimitFileSize(files)){
                    model.clearFileInput(e.target)
                    signupState.file2.state = 0
                    signupState.file2.message = i18n.cur.error_ic_too_big.formatUnicorn([{n: "15"}])
            
                }else if(utils.checkIfFormatIsAuthorize(files)){
                    model.clearFileInput(e.target)
                    signupState.file2.state = 0
                    signupState.file2.message = i18n.cur.error_wrong_files_type.formatUnicorn([{filesType: "PDF,JPG,PNG"}])
                }else{
                    if(files.length === 1){
                        var element = e.target.parentNode.getElementsByClassName('files-name')[0]
                        element.innerHTML = files[0].name
                    }else{
                        var element = e.target.parentNode.getElementsByClassName('files-name')[0]
                        element.innerHTML = i18n.cur.contact_input_files_info.formatUnicorn([{n: String(files.length)}])
                    }
                    signupState.file2.state = 1
                    signupState.file2.message = null
                }
            }
            model.setFormStates(signupState)
        }*/
    }
}

module.exports = model
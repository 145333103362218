var m = require('mithril')
var navModel = require('../models/Nav')
var global = require('../Global')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')
var user = require('../models/User')
var model = require('../models/AddVideoModel')
var infoDiv = require('../components/InfoDiv')
var Loader = require('../components/WidgetLoader')
var i18n = require('../models/TranslationModel')

var AddVideo = {
    oninit: function(vnode){
            AddVideo.isShow = vnode.attrs.isShow
            if(vnode.attrs.fileSelected !== null || vnode.attrs.fileSelected !== undefined){
                AddVideo.fileSelected = vnode.attrs.fileSelected
            }
            i18n.getList('video_cat_').then(x => {
                VideoCat.setAll(x)
                VideoCat.set(2) // by defaut we want spectacle category
            })
            
    },
    onbeforeremove : function(e){
        model.cleanAllForm()
        var bgElement = document.getElementById('background-popup-k')
        bgElement.classList.add('fadeOut')
        var popup = e.dom.getElementsByClassName("popup-widget-bg")[0]
        popup.classList.add("big-popup-exit")
        return Promise.all([
            utils.waitBeforeElementUpdate(popup),
            utils.waitBeforeElementUpdate(bgElement)
        ])
    },
    oncreate : function(vnode){
        if(vnode.attrs.fileSelected !== null){
            var form = vnode.dom.getElementsByClassName("send-form")[0]
            form.getElementsByClassName('input_pictures')[0].files = vnode.attrs.fileSelected
            model.check.file(form.getElementsByClassName('input_pictures')[0].files, form)
            m.redraw()
        }
    },
    view: function() {
        return m("div", {class:"plain-container"},[
            m("div", {class:"relative-div"}, [
                m("div", {class:"popup-widget-bg"},[
                    m("div", {class:"header"}, [
                        m("span", {class:"title-popup"},[
                            m("h3", {class:"text"}, i18n.cur.home_mymov_video_add)
                        ]),
                        m("span", {
                            class:"ico-container",
                            onclick : function(){
                                AddVideo.isShow(false)
                            }
                        },[
                            m("i", {class:"fas fa-times"})
                        ])
                    ]),
                    m("div", {class:"send-form"},[
                        m('form', { onsubmit: model.Post, name:"send-form"},[
    
                            // VIDEO LINK
                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('span', i18n.cur.home_mymov_video_link)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fab fa-youtube'})
                                    ]),
                                    m('input', {
                                        type : 'text',
                                        placeholder : i18n.cur.home_mymov_video_target_link,
                                        class : 'input_link',
                                        name : 'link',
                                        onkeyup: function(e) {
                                            model.check.link(e.target.value)
                                        },
                                    }),
                                    model.getInputStatesIndicator('link')
                                ]),
                                model.getInputErrorMessage('link'),
                            ]),

                            // VIDEO CATEGORIE
                            model.getStates().link.state === 1 ? 
                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('span', i18n.cur.home_add_video_cat_title)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-video'})
                                    ]),
                                    m('span', {class: 'select-input-container-video-cat'} ,[
                                        m('select', {name : 'selectLang', class : 'select-video-cat',
                                        onchange : function(e){
                                            VideoCat.set(e.target.options[e.target.selectedIndex].id)
                                            model.check.category(e.target.value)
                                        }},[
                                            VideoCat.getAll().map(function(x){
                                                if(x.id == VideoCat.get()){
                                                    return m('option', {id : x.id, selected : "true"}, x.value)
                                                }else{
                                                    return m('option', {id : x.id}, x.value)
                                                }
                                            })
                                        ]),
                                        m('i', {class:'fas fa-caret-down'})
                                    ]),
                                    
                                    model.getInputStatesIndicator('video_cat')
                                ]),
                                model.getInputErrorMessage('video_cat')
                            ]) : null,
    
                            // TITLE VIDEO
                            model.getStates().link.state === 1 ? 
                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('span', i18n.cur.home_mymov_video_title)
                                ]),
                                m('div', {class: 'input-item no-ico'},[
                                    
                                    m('input', {
                                        type : 'text',
                                        placeholder : i18n.cur.home_mymov_plh_title,
                                        class : 'input_title_video',
                                        name : 'title',
                                        onfocusout: function(e) {
                                            model.check.title(e.target.value)
                                        }
                                    }),
                                    model.getInputStatesIndicator('title')
                                ]),
                                model.getInputErrorMessage('title')
                            ]) : null,
    
                            // TEXT AREA
                            model.getStates().link.state === 1 ?
                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('span', i18n.cur.home_mymov_video_desc)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('textarea', {
                                        placeholder : i18n.cur.home_mymov_plh_desc,
                                        class : 'input_description textarea',
                                        name : 'description',
                                        onfocusout: function(e) {
                                            model.check.description(e.target.value)
                                        }
                                    }),
                                    model.getInputStatesIndicator('description')
                                ]),
                                model.getInputErrorMessage('description')
                            ]) : null,                     
        
                            // SUBMIT BUTTON
                            model.getStates().link.state === 1 ?
                            m('div', {class:"input-section clearfix submit-section"}, [
                                m('div', {class:"submit-button right"},[
                                    m('input', {
                                        id: 'register-input',
                                        type: 'submit',
                                        value: i18n.cur.home_mymov_video_add_this,
                                        onclick: function(e){
                                            e.preventDefault()
                                            AddVideo.post(e)
                                        }
                                    })
                                ])
                            ]) : null
                        ])
                    ]),
                    model.getAllBackendError(),
                    m("div", {style:"margin-top: 15px;"}, [
                        m(infoDiv, {text : i18n.cur.home_mymov_ppp_info})
                    ]),
                    AddVideo.getLoader()
                ]),
                m(AddVideo.BackgroundNav)
            ])
        ])
    },
    post : function(e){
        AddVideo.toggleLoader()
        model.post(e).then(function(res){
            console.log("it's ok")
            eventHelper.widgetLoader.setProgress(5)
            // FAKE LOADER
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(40)
            }, 500)
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(100)
                
            }, 1600)
            setTimeout(function(){
                AddVideo.toggleLoader()
                AddVideo.isShow(false)
            }, 2300)
        }).catch(function(err){
            console.log(err)
            AddVideo.toggleLoader()
        })
    },
    getLoader : function(){
        return eventHelper.widgetLoader.get() ? m(Loader) : ''
    },
    toggleLoader : function(){
        eventHelper.widgetLoader.set(!eventHelper.widgetLoader.get())
    },
}

AddVideo.BackgroundNav = {
    view : function(ctrl){
        return m("div", {
            id: 'background-popup-k',
            class: 'fadeIn', //by default we show with fadeIn
            onclick : function(){
                AddVideo.isShow(false)
            }
        })
    }
}

var VideoCat = {
    current : null,
    all : [],
    getAll(){
        return VideoCat.all
    },
    get(){
        return VideoCat.current // return an ID
    },
    setAll(data){
        VideoCat.all = data
    },
    set(id){
        VideoCat.current = id
    },
    remove(){

    }
}

module.exports = AddVideo

var m = require('mithril')
var utils = require('../helpers/Utils')
var Global = require('../Global')
var i18n = require('../models/TranslationModel')

var model = {
    _formStates : {// state -1 = initial / state 0 = error / state 1 = valid
        email : {state : -1},
    },
    current : {},
    backendSuccessMessages : [],
    backendErrorsMessages : [],
    getBackendResponse : function(){
        var SuccessMessages = model.backendSuccessMessages
        var ErrorsMessages = model.backendErrorsMessages
        var successMessageExist = Boolean(typeof SuccessMessages !== 'undefined' && SuccessMessages.length > 0)
        var errorsMessageExist = Boolean(typeof ErrorsMessages !== 'undefined' && ErrorsMessages.length > 0)
        var successView;
        var errorsView;
        if(successMessageExist){
            successView = model.getSuccessMessagesView(SuccessMessages)
        }
        if(errorsMessageExist){
            errorsView = model.getErrorsMessagesView(ErrorsMessages)
        }
        if(successMessageExist || errorsMessageExist){
            return m("div", {class:"response-message"}, [
                successMessageExist ? successView : '',
                errorsMessageExist ? errorsView : ''
            ])
        }else{
            return null
        }
    },
    postAction: function(){
        m.request({
            method: 'POST',
            url: "api/users/reload_password", // TODO: when backend will be finished
            body: model.current,
            headers : {'Content-Type' : 'application/json', 'X-CSRF-TOKEN': utils.getCsrf()},
        })
        .then(function(res) {
            model.setBackendSuccessMessages([i18n.cur.lostpwd_confirm_sending])
            model.clearForm()
            return undefined
        })
        .catch(function(err) {
            console.log(err)
            model.setBackendSuccessMessages([i18n.cur.lostpwd_confirm_sending])
            return undefined
        })
    },
    clearForm : function(){
        var form = document.getElementsByClassName('lost-password-form')[0]
        form.getElementsByClassName('input_email')[0].value = ""
    },
    setBackendSuccessMessages : function(messages){
        model.backendErrorsMessages = []
        model.backendSuccessMessages = messages

    },
    setBackendErrorsMessages : function(messages){
        model.backendSuccessMessages = []
        model.backendErrorsMessages = messages
    },
    getSuccessMessagesView(messages){
        return m('div', [
            messages.map(function(x){
                return m("span", {class:"response-text success"}, x)
            })
        ])
    },
    getErrorsMessagesView(messages){
        return m('div', [
            messages.map(function(x){
                return m("span", {class:"response-text error"}, x)
            })
        ])
    },
    checkAllInput : function(){
        var form = document.getElementsByClassName('lost-password-form')[0]
        model.check.email(form.getElementsByClassName('input_email')[0].value)
        return !model.haveError()
    },
    haveError: function(){
        var errorUccured = true

        if(model._formStates.email.state === 1){
            errorUccured = false
        }

        return errorUccured
    },
    setFormStates : function (newFormStates){
        model._formStates = newFormStates
    },
    getFormStates : function (){
        return model._formStates
    },
    getInputErrorMessage : function(stringEnum){
        var message = model.getInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getInputStatesIndicator : function(stringEnum){
        var state = model.getInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getInputStates : function(stringEnum){
        var o
        switch (stringEnum) {
            case 'email':
                o = model._formStates.email
            break;
            default:
                o = null
        }
        return o
    },
    submit : function(){
        if(model.checkAllInput()){
            utils.getRecaptcha().then(function(token){ //here we check/get the token
                model.current.token_recaptcha = token
                console.log(token)
                model.postAction()
            })
        }
    },
    check : {
        email : function(e){
            var signupState = model.getFormStates()
            if(model.check.validateLengthCarac(e, 5, 255)){// min-mac carac
                signupState.email.state = 0
                signupState.email.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "5", MaxCharac : "255"})
            }else if(model.check.validateEmail(e)){
                signupState.email.state = 0
                signupState.email.message = i18n.cur.error_email_not_valid
            }else{
                signupState.email.message = null
                model.current.email = e
                signupState.email.state = 1
            }
            model.setFormStates(signupState)
        },
        validateEmail(e) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return !(re.test(String(e).toLowerCase()))
        },
        validateLengthCarac: function(e, minCarac, maxCarac){
            var length = e.length
            return !(length >= minCarac && length <= maxCarac)
        },
    }
}

module.exports = model
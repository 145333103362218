var m = require('mithril')
var user = require('../models/User')
var utils = require('../helpers/Utils')


let Therms = {
    oncreate : function(){
        utils.scrollToTop(500, 0) // animation during in ms, timeout in ms
    },
    getContent : function(){
        return m
        ("div", {class: "box-shadow widget cgu"}, [
            m("p", {class: "semi-heavy-text"}, "Les présentes conditions générales d’utilisation (ci-après « les Conditions Générales ») sont conclues entre la société Ôlyrix éditrice du portail PRÔlyrix, sise 43 rue Manessier, 94130 Nogent-sur-Marne (ci-après « La Société ») et l’Utilisateur (ci-après « l’Utilisateur »)."),
            m("h3", {class: "text-to-left"}, [
                m("span", "1. OBJET"),
            ]),
            m("h4", "1.1. Préambule"),
            m("br"),
            m("p", m.trust("PRÔlyrix propose à l’Utilisateur, sur son site Internet accessible à l’adresse https://www.prolyrix.com, (ci-après « PRÔlyrix »), différents services et fonctionnalités permettant aux personnes disposant d’une page à leur nom sur le site https://www.olyrix.com (ci-après « Ôlyrix ») d’y gérer certaines informations ou contenus (ci-après les « Services »).<br> La Société se réserve le droit d'ajouter, de supprimer ou de modifier tout ou partie des Services qu’elle propose sur PRÔlyrix.")),
            m("p", m.trust("Les présentes Conditions Générales ont pour objet de régir la mise à disposition et l’accès par l’Utilisateur à ces Services, ainsi que l’utilisation qu’il en fait.")),
            m("h4", "1.2. Accès au site"),
            m("br"),
            m("p", m.trust("L’accès et l’utilisation de tout ou partie des Services proposés sur PRÔlyrix est réservé aux personnes majeures disposant d’une page à leur nom sur Ôlyrix, ayant créé un compte sur PRÔlyrix, et dont l’identité a pu être vérifiée par la Société.")),
            m("p", m.trust("Si une page d’Ôlyrix concerne une personne mineure, l’accès au compte PRÔlyrix associé se fera par son représentant légal, ou sous son contrôle et avec son autorisation. La création du compte nécessitera alors de justifier de l’identité de l’artiste, de son représentant légal et du lien qui les lie. La création du compte requerra dès lors l’acceptation pleine et entière de l’ensemble des présentes Conditions Générales par le représentant légal.")),
            m("p", m.trust("Si une page d’Ôlyrix concerne une personne décédée, l’accès au compte PRÔlyrix associé peut être demandé par ses ayants droit. La création du compte nécessitera alors de justifier de l’identité de l’artiste, de son représentant légal et du lien qui les lie. La création du compte requerra dès lors l’acceptation pleine et entière de l’ensemble des présentes Conditions Générales par les ayants droit.")),
            m("p", m.trust("De manière générale, l’utilisation de PRÔlyrix implique de la part de l’Utilisateur une acceptation pleine et entière de l’ensemble des présentes Conditions Générales. L’Utilisateur confirme donc avoir lu et accepté l’intégralité des présentes Conditions Générales avant toute utilisation des Services.")),
            m("p", m.trust("L’acceptation des Conditions Générales exclut l’application de toute disposition différente ou contraire. Les stipulations des présentes Conditions Générales prévaudront en toute hypothèse.")),
            m("p", m.trust("Les Conditions Générales constituent l’intégralité du contrat passé entre l’Utilisateur et la Société. La Société pourra proposer des conditions particulières complémentaires relatives à certains Services spécifiques, qui devront être acceptées par l’Utilisateur s’il souhaite avoir accès aux dits Services.")),
            m("p", m.trust("L’accès à PRÔlyrix nécessite de disposer d’un compte associé à une page Ôlyrix. Pour cela, l’Utilisateur doit remplir et valider le formulaire en ligne d’inscription et envoyer électroniquement les justificatifs demandés. Ces justificatifs ont pour but de valider l’identité du demandeur et son habilitation à gérer la page Ôlyrix associée au compte. Une fois la demande d’inscription validée, PRÔlyrix adresse un courriel à l'Utilisateur afin de confirmer la création du compte. L'Utilisateur s'engage à ne communiquer que des données exactes, notamment en ce qui concerne ses noms, prénoms et adresse e-mail.")),
            m("p", m.trust("La communication de documents frauduleux en vue d’accéder au compte associé à la page Ôlyrix d’un tiers est susceptible de donner lieu à des poursuites de la part de la Société. La Société se réserve par ailleurs le droit d’informer le tiers en question de la tentative de fraude et de lui communiquer les données collectées à cette occasion.")),
            m("p", m.trust("Lors de son inscription, l’Utilisateur choisit un mot de passe, qui lui permet de se connecter sur le Site. Il assume seul la responsabilité des agissements effectués sur le Site à l'aide de ce mot de passe et doit donc veiller à sa confidentialité.")),
            m("p", m.trust("La Société ne peut être tenue pour responsable de l’utilisation frauduleuse d’un compte ou d’une identité, notamment dans le cas où un faux justificatif d’identité lui serait fourni.")),
            m("p", m.trust("Si l’Utilisateur suspecte ou constate une utilisation frauduleuse de son compte, de son identité ou de son mot de passe, il s'engage à en avertir immédiatement PRÔlyrix via le formulaire de contact.")),
            m("p", m.trust("L'Utilisateur peut clôturer son compte en cliquant sur « Supprimer mon compte » dans la section « Mes paramètres ». Toutes ses données personnelles (adresse, adresse e-mail, numéro de téléphone) sont alors définitivement supprimées de la base de données de PRÔlyrix. En revanche, la Société garde de plein droit la possibilité de publier et utiliser les informations et contenus (photos, vidéos) dont les droits de publication ont été concédés en vertu de l’article 2.4 des présentes.")),
            m("h3", {class: "text-to-left"}, [
                m("span", "2. FOURNITURE ET UTILISATION DES SERVICES"),
            ]),
            m("h4", "2.1. Engagements et responsabilité de PRÔlyrix"),
            m("br"),
            m("p", m.trust("La Société fournit ses Services en l’état et n’accorde aucune garantie à cet égard. En particulier, elle ne prend pas d’engagement de disponibilité d’accès.")),
            m("p", m.trust("Compte tenu notamment des aléas techniques liés au fonctionnement décentralisé du réseau Internet, la Société ne fournit aucune garantie de continuité de service ou d’absence d’erreurs des Services.")),
            m("p", m.trust("Dans toute la mesure permise par la réglementation en vigueur, la Société ne prend aucun engagement, express ou implicite, notamment concernant la qualité et la compatibilité des Services aux usages de l’Utilisateur. Aucune information ou conseil donné par la Société, via les Services ou non, ne crée d’obligation pour la Société ni de garantie.")),
            m("p", m.trust("En particulier, et bien que la Société mette tous ses efforts en œuvre afin d'éviter ces situations, la Société ne déclare ni ne garantit :")),
            m("ul", [
                m("li", " • Que l’utilisation des Services répondra aux besoins de l’Utilisateur ;"),
                m("li", " • Que l’utilisation faite des Services par l’Utilisateur sera ininterrompue, opportune, ou exempte d’erreurs ;"),
                m("li", " • Que tous les ajouts, modifications ou suppressions d’informations ou de contenus opérés par l’intermédiaire de PRÔlyrix seront sauvegardés, pourront être traités, ni n’apparaîtront sur Ôlyrix ;"),
            ]),
            m("br"),
            m("p", m.trust("La Société ne garantit pas que l’accès à tout ou partie des Services de PRÔlyrix demeure gratuit pendant une durée indéfinie. En cas d’instauration de nouveaux services payants ou de modification d’un service gratuit en un service payant, des Conditions Spécifiques d’Utilisation seront proposés à l’Utilisateur au préalable, ou, le cas échéant, les présentes Conditions Générales d’Utilisation seront modifiées dans le respect des dispositions de l'article 3 des présentes.")),
            m("p", m.trust("Dans le cas où la responsabilité de la Société serait néanmoins engagée, la réparation ne s’appliquera qu’aux seuls dommages directs, personnels et certains.")),
            m("p", m.trust("En toute hypothèse, la Société ne pourra voir sa responsabilité engagée en cas de faute ou de fait totalement ou partiellement imputable à l’Utilisateur, à un tiers ou en cas de survenance d’un cas de force majeure.")),
            m("p", m.trust("Dans tous les cas où la responsabilité de la Société pourrait être engagée, toute action ou réclamation quelle qu’elle soit ne pourrait être engagée contre la Société plus d'1 (un) an après le jour du manquement commis par elle, et après qu'une conciliation ait été tentée.")),
            m("h4", "2.2. Liens hypertextes"),
            m("br"),
            m("p", m.trust("PRÔlyrix peut contenir des liens hypertextes vers des contenus émanant de tiers ou vers des sites Internet exploités par des tiers.")),
            m("p", m.trust("PRÔlyrix n’est pas responsable de la qualité ou de l'exactitude de ces contenus ni de ces sites Internet et ne peut pas non plus être considérée comme approuvant, publiant ou autorisant ces sites Internet ou ces contenus. De même, Ôlyrix ne peut en aucun cas garantir que les contenus mis en avant à travers ces liens hypertextes resteront indéfiniment inchangés ni accessibles.")),
            m("p", m.trust("Les exploitants de ces sites internet sont seuls responsables du respect des législations et réglementations applicables, notamment quant aux produits et services qu'ils proposent sur leur site, et plus particulièrement en matière de protection du consommateur, de vente à distance, de réglementation des prix, de protection des données personnelles, etc.")),
            m("p", m.trust("La Société décline toute responsabilité quant aux dommages pouvant résulter de l’utilisation de ces sites.")),
            m("h4", "2.3. Propriété intellectuelle des contenus disponibles sur PRÔlyrix"),
            m("br"),
            m("p", m.trust("<b>Toutes les données et informations présentes ou accessibles par le site PRÔlyrix sont soumises au droit de la propriété intellectuelle et ne peuvent être reprises et utilisées à d’autres fins que la consultation privée par l’Utilisateur directement connecté sur le Site.</b> La Société est et sera particulièrement vigilante, et engagera toute action appropriée contre toute reprise quelconque effectuée en violation des droits de propriété intellectuelle et/ou de la loi et /ou des Conditions Générales du site.")),
            m("p", m.trust("Si l’Utilisateur souhaite utiliser ou diffuser les services, contenus et fonctionnalités du Site dans un autre cadre, il devra contacter la Société par l’intermédiaire du formulaire de contact ou à l’adresse e-mail contact@prolyrix.com.")),
            m("p", m.trust("Les noms, images, logos ou tout autre signe distinctif présenté sur le Site identifiant aussi bien PRÔlyrix et Ôlyrix que ses partenaires ou des tiers sont des contenus protégés au titre des droits de propriété intellectuelle.")),
            m("p", m.trust("Les Services et leurs contenus, la structure générale du Site, des Services, les contenus éditoriaux, images, photographies, sons, vidéos ou multimédias, les logiciels et les bases de données utilisés et tous les autres contenus présentés sur PRÔlyrix et dans les Services sont protégés au titre du droit d’auteur, des droits voisins et/ou du droit sui generis du producteur de base de données, et d’une manière générale, par le droit commun de la responsabilité civile ainsi que par les conventions internationales applicables.")),
            m("p", m.trust("La Société et/ou ses partenaires sont seuls titulaires des droits de propriété intellectuelle susmentionnés sur l’ensemble des contenus présentés sur le Site et dans les Services.")),
            m("p", m.trust("A ce titre, et sous peine de poursuites civiles et/ou pénales, sauf accord express de PRÔlyrix, l’Utilisateur s’engage notamment à ne pas :")),
            m("ul", [
                m("li", " • Reproduire, modifier, publier, adapter sur quelque support que ce soit, par quelque moyen que ce soit, l’ensemble des éléments présents sur le Site et les Services ;"),
                m("li", " • Procéder à une quelconque atteinte aux systèmes informatisés mis en œuvre pour la fourniture des Services, notamment par toute intrusion ou tentative d’intrusion ;"),
                m("li", " • Procéder à une quelconque opération d’ingénierie inversée de tout ou partie des Services ;"),
                m("li", " • Compiler, décompiler ou désassembler tout ou partie des Services ;"),
                m("li", " • Distribuer et/ou diffuser des copies de tout ou partie des Services ;"),
                m("li", " • Enlever ou supprimer toute mention et/ou toute information propriétaire présente sur tout ou partie des Services ;"),
                m("li", " • Revendre, louer, sous-louer ou transférer de quelque manière que ce soit à un tiers les Services."),
            ]),
            m("br"),
            m("p", m.trust("Toute utilisation des informations, données et des éléments du Site et des Services, à quelque titre que ce soit en dehors de la simple consultation directe sur le Site par l’utilisateur, est strictement interdite, sauf autorisation préalable et par écrit d’Ôlyrix.")),
            m("p", m.trust("En aucun cas l’accès possible aux informations du Site et à certaines données ne peut permettre à l’utilisateur de considérer qu’il peut extraire, de manière substantielle ou non, et/ou utiliser en dehors de la consultation du Site, une quelconque donnée du Site ou de tout élément du Site.")),
            m("p", m.trust("Toute reproduction et/ou représentation, totale ou partielle, sur un quelconque support, des signes distinctifs ou contenus présentés sur le Site, y compris les informations et/ou données, sans l’autorisation écrite préalable de la Société est interdite et constitutive notamment de contrefaçon et susceptible à ce titre d’engager la responsabilité civile et pénale de son auteur.")),
            m("h4", "2.4. Informations et contenus ajoutés sur PRÔlyrix par l’Utilisateur"),
            m("br"),
            m("p", m.trust("Ôlyrix dispose d’une base de données référençant des artistes évoluant dans le secteur du spectacle vivant, et en particulier de l’opéra. Chaque artiste identifié dans la base de données dispose d’une page dédiée, sur laquelle sont publiés divers contenus (rédactionnels, picturaux, audiovisuels, etc.) qui relèvent de choix éditoriaux exclusifs d’Ôlyrix.")),
            m("p", m.trust("PRÔlyrix propose aux utilisateurs d’ajouter, de supprimer ou de modifier certaines informations, sons, photos, vidéos, et autres contenus afin de tenir à jour la page Ôlyrix qui leur est dédiée. Toutefois, tous les contenus, rubriques ou informations disponibles sur la page Ôlyrix ne sont pas modifiables sur PRÔlyrix. De même, PRÔlyrix se réserve le droit d’accepter ou de refuser sans justification et sans notification toute proposition d’ajout, de modification ou de suppression exécutée depuis PRÔlyrix.")),
            m("p", m.trust("Chaque Utilisateur est responsable des contenus postés sur le Site. A ce titre, il s’engage :")),
            m("ul", [
                m("li", " • A ne produire sur PRÔlyrix que des informations exactes et apportées de bonne foi."),
                m("li", " • A n’ajouter que des photos et vidéos dont il détient les droits, ou qui sont libres de droit, ou pour lesquelles il a reçu une autorisation de diffusion de la part de l’ayant droit. "),
                m("li", " • A ne pas diffuser de contenus illicites, contrefaisants, nuisibles, menaçants, diffamatoires, injurieux, abusifs, constitutifs de harcèlement, indécents, obscènes, menaçants pour la vie privée d'autrui, haineux, racistes, excessifs ou autrement répréhensibles ou portant atteinte aux intérêts et/ou aux droits de la Société, de ses marques, des autres Utilisateur du Site ou plus généralement de tout autre tiers. "),
                m("li", " • A ne publier aucun contenu constituant une violation des dispositions légales applicables, stipulations contractuelles ou autres droits de tiers, notamment des droits de la personnalité ou des droits de propriété intellectuelle."),
            ]),
            m("br"),
            m("p", m.trust("La Société se réserve le droit de supprimer le compte des utilisateurs qui ne respecteraient pas ces dispositions.")),
            m("p", m.trust("La Société se réserve également le droit de communiquer aux autorités judiciaires, sur demande de celles-ci, tous les éléments en sa possession permettant d'identifier l'auteur d'agissements illégaux réalisés sur le Site.")),
            m("p", m.trust("En proposant des contenus quels qu’ils soient sur PRÔlyrix, l’Utilisateur accorde à la Société à titre gracieux un droit et une licence irrévocable pour le monde entier, pour la durée de protection des droits d’auteur telle que prévue dans le code de la propriété intellectuelle, en vue d’exploiter ces contenus en tout ou partie, et de les utiliser, reproduire, modifier, publier, traduire, sous-licencier ou incorporer dans quelque forme que ce soit via toute technologie connue ou inconnue à ce jour, notamment pour faire la promotion d'Ôlyrix, de PRÔlyrix ou de ses services, et donne son accord pour toute utilisation à ces fins.")),
            m("h3", {class: "text-to-left"}, [
                m("span", "3. DONNÉES PERSONNELLES"),
            ]),
            m("br"),
            m("p", m.trust("Ôlyrix s'engage à ce que la collecte et le traitement d'informations personnelles effectués sur le Site soient effectués conformément à la loi n°78-17 du 6 janvier 1978 dite \"Informatique et Libertés\". A ce titre, le Site fait l'objet d'une déclaration à la CNIL sous le numéro 1884184.")),
            m("p", m.trust("La Société collecte des données à caractère personnel sur les Utilisateurs lorsqu'ils s'inscrivent sur le site en remplissant le formulaire de contact prévu à cet effet.")),
            m("p", m.trust("Les données collectées ont pour finalité de permettre à la Société d'identifier les Utilisateurs et de leurs permettre d'utiliser ses Services. Elles pourront être transmises aux partenaires techniques de la Société pour les besoins du fonctionnement du Site.")),
            m("p", m.trust("Lors de l'inscription en ligne, la Société demande aux Utilisateurs de compléter un certain nombre de mentions, certaines étant requises à titre obligatoire, d'autres à titre facultatif. Le caractère obligatoire des réponses est signalé au moyen d'un astérisque (*) figurant devant la case correspondante.")),
            m("p", m.trust("Ôlyrix s'engage à ne pas communiquer ni céder à tout autre tiers les données personnelles communiquées par les Utilisateurs sans leur autorisation préalable, à moins d'y être contraint par des dispositions légales ou par décision de justice.")),
            m("p", m.trust("La Société s'engage à faire ses meilleurs efforts pour protéger les données personnelles qui lui ont été communiquées par les Utilisateurs afin, notamment, d'empêcher que ces données ne soient déformées, endommagées ou communiquées à des tiers non autorisés.")),
            m("p", m.trust("Conformément aux articles 38, 39 et 40 de la Loi n° 78-17 du 6 janvier 1978 relative à l'informatique et aux libertés modifiée par la loi n°2004-801 du 6 août 2004, Ôlyrix garantit aux Utilisateurs un droit d'accès, de rectification et de suppression des données nominatives le concernant.")),
            m("p", m.trust("L'Utilisateur a la possibilité d'exercer ce droit via son espace personnel ou en utilisant le formulaire de contact.")),
            m("h3", {class: "text-to-left"}, [
                m("span", "4. COOKIES"),
            ]),
            m("br"),
            m("p", m.trust("Le cookie est un fichier texte transféré sur l’ordinateur de l’Utilisateur via son navigateur Internet. Il permet à PRÔlyrix de rendre sa navigation plus conviviale mais aussi de personnaliser les informations publiées (relatives à la langue d’affichage, par exemple).")),
            m("p", m.trust("L’Utilisateur peut à tout moment supprimer ou refuser ces cookies, comme indiqué dans la section « Aide » des navigateurs. Cette action rendra cependant inopérantes certaines fonctionnalités du Site.")),
            m("p", m.trust("Lorsque les cookies sont actifs, il est conseillé de se déconnecter avant de partager son ordinateur avec d'autres personnes.")),
            m("h3", {class: "text-to-left"}, [
                m("span", "5. RÉVISION DES CONDITIONS GÉNÉRALES"),
            ]),
            m("br"),
            m("p", m.trust("Ôlyrix se réserve la possibilité d'adapter ou de modifier à tout moment les présentes Conditions Générales. Les nouvelles conditions générales seront, le cas échéant, portées à la connaissance de l'Utilisateur par modification en ligne.")),
            m("h3", {class: "text-to-left"}, [
                m("span", "6. VALIDITÉ DES CONDITIONS GÉNÉRALES"),
            ]),
            m("br"),
            m("p", m.trust("Si une ou plusieurs stipulations des présentes Conditions Générales sont tenues pour non valides ou déclarées telles en application d'une loi, d'un règlement ou à la suite d'une décision définitive d'une juridiction compétente, les autres stipulations garderont toute leur force et leur portée.")),
            m("p", m.trust("Les parties conviennent alors de remplacer la clause déclarée nulle et non valide par une nouvelle stipulation qui se rapprochera le plus, quant à son contenu et quant à l'équilibre de la clause, à celle initialement arrêtée.")),
            m("h3", {class: "text-to-left"}, [
                m("span", "7. LOI APPLICABLE ET JURIDICTION COMPÉTENTE"),
            ]),
            m("br"),
            m("p", m.trust("La Société, ses sites et les présentes Conditions Générales sont soumis à la loi française. Tout litige né de leur interprétation ou de leur exécution sera soumis au tribunal compétent situé dans le ressort géographique de la Cour d’Appel de Paris.")),
            m("br"),
            m("br"),
            m("br"),
            m("span", {class : "italic text-to-right"} ,"Dernière mise à jour le 01/06/2020")
        
        
        
        
        
        ])
    },
    view: function() {
        return m("div", {class:user.isLogged() ? "multi-statut-route" : ""}, [
            m("div", {class:"flex-center-container"}, [
                m("div", {class:"title-page m-d-none"}, [
                    m("div", {class:"title-container"}, [
                        m("div", {class:"main-title"}, 
                            m("h1", "CGU/CGV")
                        ),
                        m("div", {class:"second-title"}, 
                            m("h2", "Conditions générales d'utilisation")
                        )
                    ])
                ]),
            ]),
            m("div", {class: "content"},[
                Therms.getContent()
            ])
        ])
    }
}

module.exports = Therms
var m = require('mithril')
var JsonLang = require('../models/LocalData')
var Loader = require('../components/WidgetLoader')
var eventHelper = require('../helpers/EventHelper')
var model = require('../models/Contact')
var user = require('../models/User')
var i18n = require('../models/TranslationModel')
var utils = require('../helpers/Utils')


let Contact = {
    oncreate : function(){
        utils.scrollToTop(500, 0) // animation during in ms, timeout in ms
    },
    getLoader : function(){
        return eventHelper.widgetLoader.get() ? m(Loader) : ''
    },
    toggleLoader : function(){
        eventHelper.widgetLoader.set(!eventHelper.widgetLoader.get())
    },
    getSelectPhone: function(){
        return m('span', {class: 'select-input-container'} ,[
            m('select', {name : 'selectLang', class : 'select-your-country-phone',
            onchange : function(e){
                model.setCurrentCode(e.target.value)
            }},[
                JsonLang.countries.map(function(x){
                    if(model.getCurrentCode() === x.code){
                        return m('option', {value : x.code, selected : 'selected'}, x.country)
                    }else{
                        return m('option', {value : x.code}, x.country)
                    }
                })
            ]),
            m('i', {class:'fas fa-caret-down'})
        ])
    },
    getContact : function(){
        return m
        ("div", {class: "box-shadow widget contact"},[
            m("h3", {class: "center-title"}, [
                m("span", i18n.cur.contact_w_title),
            ]),
            m("div", {class:"contact-form"},[
                m('form', { onsubmit: model.Post, name:"contact-form"},[

                    // LASTNAME

                    m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.contact_in_lastname)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-user'})
                            ]),
                            m('input', {
                                type : 'text',
                                placeholder : 'Debussy',
                                autocomplete : 'family-name',
                                class : 'input_lastname',
                                name : 'lastname',
                                onfocusout: function(e) {
                                    model.check.lastName(e.target.value)
                                }
                            }),
                            model.getInputStatesIndicator('lastName')
                        ]),
                        model.getInputErrorMessage('lastName')
                    ]),

                    // FIRSTNAME

                    m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.contact_in_firstname)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-user'})
                            ]),
                            m('input', {
                                type : 'text',
                                placeholder : 'Claude',
                                autocomplete: 'given-name',
                                class : 'input_firstname',
                                name : 'firstname',
                                onfocusout: function(e) {
                                    model.check.firstName(e.target.value)
                                }
                            }),
                            model.getInputStatesIndicator('firstName')
                        ]),
                        model.getInputErrorMessage('firstName')
                    ]),

                    // EMAIL

                    m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.contact_in_email)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-at'})
                            ]),
                            m('input', {
                                type : 'text',
                                placeholder : 'claude.debussy@gmail.com',
                                autocomplete : 'email',
                                class : 'input_email',
                                name : 'email',
                                onfocusout: function(e) {
                                    model.check.email(e.target.value)
                                }
                            }),
                            model.getInputStatesIndicator('email')
                        ]),
                        model.getInputErrorMessage('email')
                    ]),

                    // OBJECT 

                    m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.contact_in_subject)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-thumbtack'})
                            ]),
                            m('input', {
                                type : 'text',
                                placeholder : i18n.cur.contact_in_subject_ph,
                                class : 'input_object',
                                name : 'object',
                                onfocusout: function(e) {
                                    model.check.object(e.target.value)
                                }
                            }),
                            model.getInputStatesIndicator('object')
                        ]),
                        model.getInputErrorMessage('object')
                    ]),

                    // TEXT AREA

                    m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', "Votre message")
                        ]),
                        m('div', {class: 'input-item'},[
                            m('textarea', {
                                placeholder : i18n.cur.contact_in_message_placeholder,
                                class : 'input_message textarea',
                                name : 'message',
                                onfocusout: function(e) {
                                    model.check.message(e.target.value)
                                }
                            }),
                            model.getInputStatesIndicator('message')
                        ]),
                        model.getInputErrorMessage('message')
                    ]),

                    // FILES

                    m('div', {class:"input-section border-bottom-input-file input-type-file"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.contact_in_files),
                            m('span', {class:"small"}, " " + i18n.cur.contact_not_required)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('input[multiple]', {
                                type : 'file',
                                class : 'input_file_contact file_input',
                                name : 'input_file_contact',
                                onchange: function(e) {
                                    model.check.file(e)
                                }
                            }),
                            m('label', {for:'input_file_contact'},[

                                m('div', {class:"browse"}, [
                                    m('span', i18n.cur.contact_browse)
                                ]),
                                m('span', {class:"file-name"}, [
                                    m('span', {class:'italic files-name'}, i18n.cur.contact_required)
                                ])
                            ]),
                            model.getInputStatesIndicator('file')
                        ]),
                        model.getInputErrorMessage('file')
                    ]),
                    

                    // SUBMIT BUTTON

                    m('div', {class:"input-section submit-section"}, [
                        m('div', {class:"submit-button right"},[
                            m('input', {
                                id: 'register-input',
                                type: 'submit',
                                value: i18n.cur.contact_submit,
                                onclick: function(e){
                                    e.preventDefault()
                                    Contact.post(e)
                                }
                            })
                        ])
                    ])
                ])
            ]),
            Contact.getSuccesMessages(),
            model.getAllBackendError(),
            Contact.getLoader()
        ])
    },
    getSuccesMessages : function(){
        if(Contact.showSuccessMessage){
            return m("div", {class:"success-message-container"}, [
                m("span", {class:"success"}, i18n.cur.contact_success)
            ])
        }else{
            return ''
        }
    },
    onbeforeremove: function(){
        Contact._IsShow = true
        model.cleanAllForm()
    },
    post : function(e){
        Contact.toggleLoader()
        model.post(e).then(function(res){
            console.log("it's ok")
            eventHelper.widgetLoader.setProgress(20)
            // FAKE LOADER
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(40)
                
            }, 500)
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(100)
                
            }, 1100)
            setTimeout(function(){
                Contact.showSuccessMessage = true
                Contact.toggleLoader()
            }, 1600)
        }).catch(function(err){
            console.log("it's not ok")
            Contact.toggleLoader()
        })
    },
    view: function() {
        return m("div", {class:user.isLogged() ? "multi-statut-route" : ""}, [
            m("div", {class:"flex-center-container"}, [
                m("div", {class:"title-page m-d-none"}, [
                    m("div", {class:"title-container"}, [
                        m("div", {class:"main-title"}, 
                            m("h1", i18n.cur.contact_title.formatUnicorn({prolyrix: "PRÔlyrix"}))
                        ),
                        m("div", {class:"second-title"}, 
                            m("h2", i18n.cur.contact_sub_title)
                        )
                    ])
                ]),
            ]),
            m("div", {class: "content"},[
                Contact.getContact()
            ])
        ])
    }
}

module.exports = Contact
var m = require('mithril')

var InfoDiv = {
    oninit : function(vnode){
        this.text = vnode.attrs.text
    },
    onbeforeremove : function(){
        //
    },
    view: function(ctrl) {
        var that = this
        return m("div", {class:"info-component"}, [
            m("span", {class:"left-border-indication"}),
            m("div", {class:"info-content"}, [
                m("span", {class:"ico-container"},[
                    m("i", {class:"fas fa-info"})
                ]),
                m("span", {class:"message-container"}, [
                    m("span", {class:"message-text"}, m.trust(this.text))
                ])
            ])
        ])
    }
}

module.exports = InfoDiv

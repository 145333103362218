var m = require('mithril')
var navModel = require('../models/Nav')
var global = require('../Global')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')
var user = require('../models/User')
var model = require('../models/AddCreditToAvatarModel')
var infoDiv = require('../components/InfoDiv')
var Loader = require('../components/WidgetLoader')
var i18n = require('../models/TranslationModel')

var AddCreditToAvatar = {
    isClickable : true,
    oninit: function(vnode){
        AddCreditToAvatar.isShow = vnode.attrs.isShow
        AddCreditToAvatar.blob = vnode.attrs.blob
        if(vnode.attrs.fileSelected !== null || vnode.attrs.fileSelected !== undefined){
            AddCreditToAvatar.fileSelected = vnode.attrs.fileSelected
        }
    },
    onbeforeremove : function(e){
        model.cleanAllForm()
        var bgElement = document.getElementById('background-popup-k')
        bgElement.classList.add('fadeOut')
        var popup = e.dom.getElementsByClassName("popup-widget-bg")[0]
        popup.classList.add("big-popup-exit")
        return Promise.all([
            utils.waitBeforeElementUpdate(popup),
            utils.waitBeforeElementUpdate(bgElement)
        ])
    },
    oncreate : function(vnode){
        if(vnode.attrs.fileSelected !== null){
            var form = vnode.dom.getElementsByClassName("send-form")[0]
            m.redraw()
        }
    },
    view: function() {
        return m("div", {class:"plain-container"},[
            m("div", {class:"relative-div"}, [
                m("div", {class:"popup-widget-bg"},[
                    m("div", {class:"header"}, [
                        m("span", {class:"title-popup"},[
                            m("h3", {class:"text"}, i18n.cur.home_myinfo_avatar_title)
                        ]),
                        m("span", {
                            class:"ico-container",
                            onclick : function(){
                                AddCreditToAvatar.isShow(false)
                            }
                        },[
                            m("i", {class:"fas fa-times"})
                        ])
                    ]),
                    m("div", {class:"send-form"},[
                        m('form', { onsubmit: model.Post, name:"send-form"},[
        
                            // QUICK AVATAR VIEW

                            m('div', {class:"quick-view-avatar"}, [
                                m('div', {class:"img-circle box-shadow"}, [
                                    m("img", {
                                        class: "img-user-avatar",
                                        alt: user.current.personne.fullname,
                                        src: user.current.personne.photoIdentity,
                                    })
                                ])
                            ]),

                            // CREDITS

                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('span', "Crédits")
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-copyright'})
                                    ]),
                                    m('input', {
                                        type : 'text',
                                        placeholder : i18n.cur.home_mypic_ppp_plh_credit,
                                        class : 'input_credit',
                                        name : 'credit',
                                        onfocusout: function(e) {
                                            model.check.credit(e.target.value)
                                        }
                                    }),
                                    model.getInputStatesIndicator('credit')
                                ]),
                                model.getInputErrorMessage('credit')
                            ]),                        
        
                            // SUBMIT BUTTON
        
                            m('div', {class:"input-section clearfix submit-section"}, [
                                m('div', {class:"submit-button right"},[
                                    m('input', {
                                        id: 'register-input',
                                        type: 'submit',
                                        value: i18n.cur.pending_button_save,
                                        onclick: function(e){
                                            e.preventDefault()
                                            if(AddCreditToAvatar.isClickable){
                                                AddCreditToAvatar.isClickable = false
                                                AddCreditToAvatar.post(e)
                                            }
                                        }
                                    })
                                ])
                            ])
                        ])
                    ]),
                    model.getAllBackendError(),
                    m("div", {}, [
                        m(infoDiv, {text : i18n.cur.home_mypic_ppp_info_add})
                    ]),
                    AddCreditToAvatar.getLoader()
                ]),
                m(AddCreditToAvatar.BackgroundNav)
            ])
        ])
    },
    post : function(e){
        AddCreditToAvatar.toggleLoader()
        model.post(e, AddCreditToAvatar.blob).then(function(res){
            eventHelper.widgetLoader.setProgress(5)
            // FAKE LOADER
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(40)
            }, 500)
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(100)
                
            }, 1200)
            setTimeout(function(){
                AddCreditToAvatar.toggleLoader()
                AddCreditToAvatar.isShow(false)
                AddCreditToAvatar.isClickable = true
            }, 1400)
        }).catch(function(err){
            console.log(err)
            console.log("error occured")
            AddCreditToAvatar.isClickable = true
            AddCreditToAvatar.toggleLoader()
        })
    },
    getLoader : function(){
        return eventHelper.widgetLoader.get() ? m(Loader) : ''
    },
    toggleLoader : function(){
        eventHelper.widgetLoader.set(!eventHelper.widgetLoader.get())
    },
}

AddCreditToAvatar.BackgroundNav = {
    view : function(ctrl){
        return m("div", {
            id: 'background-popup-k',
            class: 'fadeIn', //by default we show with fadeIn
            onclick : function(){
                AddCreditToAvatar.isShow(false)
            }
        })
    }
}

module.exports = AddCreditToAvatar

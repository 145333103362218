var m = require('mithril')
var utils = require('../helpers/utils')
var eventHelper = require('../helpers/EventHelper')
var youtube = require('../services/Youtube')
var i18n = require('../models/TranslationModel')
var global = require('../Global')
var auth = require('../services/Auth')


var model = {
    current : {},
    _backendErrorMessages : [],
    getAllBackendError : function(){
        var messages = model._backendErrorMessages
        if(messages !== [] && messages !== undefined){
            return m("div", {class: "backend-errors-container"},[
                messages.map(x => 
                    m('span', {class:"error"}, x))
            ])
        }
        return ''
    },
    _formStates : {// state -1 = initial / state 0 = error / state 1 = valid
        link : {state : -1},
        title : {state : -1},
        description : {state : -1},
        video_cat : {state: -1}
    },
    checkAllInput : function(e){ //TODO
        var form = utils.findAncestor(e.target, "send-form")

        var title = form.getElementsByClassName('input_title_video')[0].value
        var description = form.getElementsByClassName('input_description')[0].value
        var videoLink = form.getElementsByClassName('input_link')[0].value

        model.check.link(videoLink, false)
        model.check.title(title)
        model.check.description(description)
        return !model.registerHaveError()

    },
    registerHaveError(){
        var errorUccured = true

        if(model._formStates.link.state === 1
        && model._formStates.description.state === 1
        && model._formStates.title.state === 1
        && model._formStates.video_cat.state === 1)
        {
            errorUccured = false
        }

        return errorUccured
    },
    getStates : function (){
        return model._formStates
    },
    setFormStates : function (newFormStates){
        model._formStates = newFormStates
    },
    post : function(e){
        return new Promise(function(resolve, reject){
            if(model.checkAllInput(e)){
                console.log(e)
                var form = e.target.form
                var videoCatEle = form.getElementsByClassName('select-video-cat')[0]


                var videoCatId = videoCatEle.options[videoCatEle.selectedIndex].id
                var title = form.getElementsByClassName('input_title_video')[0].value
                var description = form.getElementsByClassName('input_description')[0].value
                var videoLink = form.getElementsByClassName('input_link')[0].value

                var body = Object.create(null)
                body.category = videoCatId
                body.title = title
                body.description = description.split('\n').join('<br />');
                body.url = videoLink
                body.photo_url = model.current.imageUrl
                body.duration = model.current.duration
                m.request({
                    method: "POST",
                    url: global.api.request.videoAdd,
                    body: body,
                    headers : {
                        'Content-Type' : 'application/json', 
                        Authorization: "Bearer " + auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                    extract : function(xhr) {return {status: xhr.status, body: JSON.parse(xhr.responseText)}},
                }).then(function(res) {
                    resolve(res)
                }).catch(function(err) {
                    console.log(err)
                    reject(err)
                })
            }else{
                reject()
            }
        })
    },
    getInputErrorMessage : function(stringEnum){
        var message = model.getInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getInputStatesIndicator : function(stringEnum){
        var state = model.getInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getInputStates : function(stringEnum){
        var o
        switch (stringEnum) {
            case 'link':
                o = model._formStates.link
            break;
            case 'title':
                o = model._formStates.title
            break;
            case 'description':
                o = model._formStates.description
            break;
            case 'video_cat':
                o = model._formStates.video_cat
            break;
            default:
                o = null
        }
        return o
    },
    clearFileInput(input){
        input.value = ""
        var element = input.parentNode.getElementsByClassName('files-name')[0]
        element.innerHTML = i18n.cur.home_mypic_ppp_files_download
    },
    cleanAllForm: function(){

        model._formStates = { // state -1 = initial / state 0 = error / state 1 = valid
            title : {state : -1},
            description : {state : -1},
            link : {state : -1},
            video_cat: {state : -1}
        }
        model.current = {}
    },
    check : {
        link : function(url, autoComplet = true){
            var State = model.getStates()
            if(!youtube.getVideoIdByUrl(url)){
                State.link.message = i18n.cur.error_youtube_only
                State.link.state = 0
            }else{
                State.link.message = null
                State.link.state = 1
                if(autoComplet){
                    var videoId = youtube.getVideoIdByUrl(url)

                    youtube.getTitle(videoId).then(title => {
                        document.getElementsByClassName('input_title_video')[0].value = title
                        model.check.title(title)
                    }).catch(() => {
                        State.link.message = i18n.cur.error_youtube_only
                        State.link.state = 0
                    })
                    youtube.getDesc(videoId).then(desc => {
                        document.getElementsByClassName('input_description')[0].value = desc
                        model.check.description(desc)
                    }).catch(() => {
                        State.link.message = i18n.cur.error_youtube_only
                        State.link.state = 0
                    })
                    youtube.getThumbnail(videoId).then(x => {
                        model.current.imageUrl = x.url
                    }).catch(() => {
                        State.link.message = i18n.cur.error_youtube_only
                        State.link.state = 0
                    })
                    youtube.getDuration(videoId).then(x => {
                        model.current.duration = x
                    }).catch(() => {
                        State.link.message = i18n.cur.error_youtube_only
                        State.link.state = 0
                    })
                }
                
            }
            model.setFormStates(State)

            State.link.state === 1 ? model.check.category() : null // auto valid the categorie
        },
        title : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 0, 250)){// min-mac carac
                State.title.state = 0
                State.title.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "5", MaxCharac : "80"})
            }else if(e.length === 0){
                State.title.state = -1
            }else{
                State.title.message = null
                model.current.title = e
                State.title.state = 1
            }
            model.setFormStates(State)
        },
        description : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 0, 5000)){// min-mac carac
                State.description.state = 0
                State.description.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "30", MaxCharac : "5000"})
            }else if(e.length === 0){
                State.description.state = -1
            }else{
                State.description.message = null
                model.current.description = e
                State.description.state = 1
            }
            model.setFormStates(State)
        },
        category : function(e){
            var State = model.getStates()
            State.video_cat.message = null
            State.video_cat.state = 1
            model.setFormStates(State)
        },
        validateLengthCarac: function(e, minCarac, maxCarac){
            var length = e.length
            return !(length >= minCarac && length <= maxCarac)
        },
    }
}

module.exports = model
var m = require('mithril')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')
var user = require('../models/User')

var MyVideo = {
    _indexStartToShow : 0,
    _pictureToShow : 2*3,
    oninit : function(vnode){
        MyVideo.setIndexToShow(0)
    },
    onbeforeremove : function(e){
        e.dom.classList.add("basic-exit-scale")
        return utils.waitBeforeElementUpdate(e.dom)
        
    },
    checkPicturesEndLoad : function(ib){
        var flexItem = utils.findAncestor(ib.target, "flex-item")
        flexItem.style.opacity = "1"
    },
    setIndexToShow : function(index){
        MyVideo._indexStartToShow = index
        var videos = user.current.artiste.videos
        var minSlice = MyVideo._indexStartToShow
        var maxSlice = MyVideo._indexStartToShow + this._pictureToShow
        MyVideo.pictures = videos.slice(minSlice,maxSlice)
    },
    oncreate : function(e){
        var pictures = e.dom.getElementsByTagName("img")
        var that = this
        for (var i = 0; i < pictures.length; i++) {
            var index = i
            pictures[i].onload = function(e){
                that.checkPicturesEndLoad(e)
            }
        }
    },
    view: function(ctrl) {
        var that = this
        return m("div", {class:"element-table-container", id:"table-pictures-container"},[
            MyVideo.pictures.map(function(item, i){
                var selected = item.selected
                var src = item.photo
                return m("div", {
                    class: selected ? "flex-item selected" : "flex-item",
                    onclick: function(e){
                        var index = 0;
                        user.current.artiste.videos.map(function(x, ide){ // Limit the selection to 1 picture
                            if(x.selected === true){
                                user.current.artiste.videos[ide].selected = false
                            }
                            x.id === item.id ? index = ide : null
                        })

                        user.current.artiste.videos[index].selected = !selected
                    }
                },[
                    m("div", {class: "rounded-container picture"},[
                        that.getBgOnSelectedPictures(selected),
                        m("img", { 
                            alt: user.current.personne.fullname,
                            src: src
                        }),
                    ])
                ]) 
            })
        ])
    },
    getBgOnSelectedPictures(selected){
        if(selected){
            return m("span", {class:"container-on-select"},[
                m("span", {class:"select-ico-container"},[
                    m("i", {class:"fas fa-check"})
                ]),
                m("span", {class:"black-bg-100"}),
            ])
        }
    }
}

module.exports = MyVideo

var m = require('mithril')
var utils = require('../helpers/utils')
var eventHelper = require('../helpers/EventHelper')
var i18n = require('../models/TranslationModel')
var auth = require('../services/Auth')
var global = require('../Global')
var user = require('../models/User')

var model = {
    current : {},
    _backendErrorMessages : [],
    getAllBackendError : function(){
        var messages = model._backendErrorMessages
        if(messages !== [] && messages !== undefined){
            return m("div", {class: "backend-errors-container"},[
                messages.map(x => 
                    m('span', {class:"error"}, x))
            ])
        }
        return ''
    },
    _formStates : {// state -1 = initial / state 0 = error / state 1 = valid
        titlePicture : {state : -1},
        credit : {state : -1},
        description : {state : -1},
        file : {state : -1}
    },
    checkAllInput : function(e){ //TODO
        var form = utils.findAncestor(e.target, "send-form")
        model.check.file(form.getElementsByClassName('input_pictures')[0].files, form)
        /*model.check.firstName(form.getElementsByClassName('input_firstname')[0].value)
        model.check.credit(form.getElementsByClassName('input_credit')[0].value)
        model.check.email(form.getElementsByClassName('input_email')[0].value)
        model.check.description(form.getElementsByClassName('input_message')[0].value)*/
        return !model.registerHaveError()
        

    },
    registerHaveError(){
        var errorUccured = true

        if(model._formStates.file.state === 1)
        {
            errorUccured = false
        }

        return errorUccured
    },
    getStates : function (){
        return model._formStates
    },
    setFormStates : function (newFormStates){
        model._formStates = newFormStates
    },
    post : function(e){
        return new Promise(function(resolve, reject){
            if(model.checkAllInput(e)){
                console.log(e.target.form);
                var form = e.target.form
                var credit = form.getElementsByClassName('input_credit')[0].value
                var title = form.getElementsByClassName('input_title_picture')[0].value
                var description = form.getElementsByClassName('input_description')[0].value
                if(credit == ""){
                    credit = "DR"
                }
                if(title == ""){
                    title = user.current.user.fullname
                }
                var formData = new FormData()
                formData.append('credits', credit)
                formData.append('title', title)
                formData.append('description', description)

                var count = form.getElementsByClassName('input_pictures')[0].files.length
                for (var x = 0; x < count; x++) {
                    formData.append("images[]", form.getElementsByClassName('input_pictures')[0].files[x]);
                }

                m.request({
                    method: "POST",
                    url: "api/requests/photos",
                    body: formData,
                    serialize: function(value) {return value},
                    headers : {
                        Authorization: "Bearer " + auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                    config: function(xhr) {
                        xhr.upload.addEventListener("progress", function(e) {
                            var progress = e.loaded / e.total
                            console.log(progress)
                        })
                    }
                }).then(function(res) {
                    console.log(res)
                    resolve(res)
                }).catch(function(err) {
                    console.log(err)
                    reject(err)
                })
            }else{
                reject()
            }
        })
    },
    getInputErrorMessage : function(stringEnum){
        var message = model.getInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getInputStatesIndicator : function(stringEnum){
        var state = model.getInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getInputStates : function(stringEnum){
        var o
        switch (stringEnum) {
            case 'titlePicture':
                o = model._formStates.titlePicture
            break;
            case 'credit':
                o = model._formStates.credit
            break;
            case 'description':
                o = model._formStates.description
            break;
            case 'file':
                o = model._formStates.file
            break;
            default:
                o = null
        }
        return o
    },
    clearFileInput(input){
        input.value = ""
        var element = input.parentNode.getElementsByClassName('files-name')[0]
        element.innerHTML = i18n.cur.home_mypic_ppp_files_download
    },
    cleanAllForm: function(){

        model._formStates = { // state -1 = initial / state 0 = error / state 1 = valid
            titlePicture : {state : -1},
            description : {state : -1},
            credit : {state : -1},
            file : {state : -1}
        }
        model.current = {}
    },
    check : {
        titlePicture : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 0, 250)){// min-mac carac
                State.titlePicture.state = 0
                State.titlePicture.message = "Ce champ doit avoir 250 caractères maximum."
            }else if(e.length === 0){
                State.titlePicture.state = -1
            }else{
                State.titlePicture.message = null
                model.current.titlePicture = e
                State.titlePicture.state = 1
            }
            model.setFormStates(State)
        },
        credit : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 0, 255)){// min-mac carac
                State.credit.state = 0
                State.credit.message = "Ce champ doit avoir entre 10 et 255 caractères."
            }else if(e.length === 0){
                State.credit.state = -1
            }
            else{
                State.credit.message = null
                model.current.credits = e
                State.credit.state = 1
            }
            model.setFormStates(State)
        },
        description : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 0, 255)){// min-mac carac
                State.description.state = 0
                State.description.message = "Ce champ doit avoir entre 30 et 255 caractères."
            }else if(e.length === 0){
                State.description.state = -1
            }else{
                State.description.message = null
                model.current.description = e
                State.description.state = 1
            }
            model.setFormStates(State)
        },
        validateLengthCarac: function(e, minCarac, maxCarac){
            var length = e.length
            return !(length >= minCarac && length <= maxCarac)
        },
        validateEmail(e) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return !(re.test(String(e).toLowerCase()))
        },
        file: function(e, form){
            var signupState = model.getStates()
            var files = e
            if(files.length >= 1){
                if(files.length > 20){
                    model.clearFileInput(form.getElementsByClassName("input_pictures")[0])
                    signupState.file.state = 0
                    signupState.file.message = "Vous pouvez nous envoyer maximum 20 images"
                }else if(utils.checkLimitFileSize(files)){
                    model.clearFileInput(form.getElementsByClassName("input_pictures")[0])
                    signupState.file.state = 0
                    signupState.file.message = "Votre pièce d'identité doit faire 15mo maximum"
            
                }else if(utils.checkIfFormatIsAuthorize(files)){
                    model.clearFileInput(form.getElementsByClassName("input_pictures")[0])
                    signupState.file.state = 0
                    signupState.file.message = "Nous acceptons seulement les fichiers JPG,PNG"
                }else{
                    if(files.length === 1){
                        var element = form.getElementsByClassName('files-name')[0]
                        element.innerHTML = files[0].name
                    }else{
                        var element = form.getElementsByClassName('files-name')[0]
                        element.innerHTML = String(files.length + " éléments sélectionnés")
                    }
                    signupState.file.state = 1
                    signupState.file.message = null
                }
            }else if(files.length == 0){
                model.clearFileInput(form.getElementsByClassName("input_pictures")[0])
                    signupState.file.state = 0
                    signupState.file.message = "Merci de séléctionner au moins une photo."
                
            }
            model.setFormStates(signupState)
        }
    }
}

module.exports = model
var m = require('mithril')
var global = require('../../Global')
var i18n = require('../../models/TranslationModel')


var BNS = {
    view : function(){
        return m("div", {class:"full-screen-container bns"}, [
            m("div", {class:"logo-container"}, [
                m("img", {"src": global.url.assets + "images/prolyrix.svg"})
            ]),
            m("div", {class:"centered-container"},[
                m("div", {class: "explain-text"},[
                    m("p", m.trust(i18n.cur.ie_h1.formatUnicorn([{prolyrix: "<a target='_blank' href='/'><b>PRÔlyrix</b></a>."}])))
                ]),
                m("div", {class: "explain-text"},[
                    m("p", m.trust(i18n.cur.ie_h2.formatUnicorn([{prolyrix: "<a target='_blank' href='/'><b>PRÔlyrix</b></a>."}])))
                ]),
                m("div", {class:"button-navigateur"}, [
                    m("div", {class:"button-redirect-browsers"}, [
                        m("a", {
                            class:"button-download-container",
                            href:"https://www.google.com/chrome/",
                            target:"_blank"
                        }, [
                            m("div", {class:"chrome"}, [
                                m("div", {class:"ico-rounded-container"},[
                                    m("i", {class:"fab fa-chrome"})
                                ]),
                                m("span", i18n.cur.ie_btn_chrome)
                            ]),
                        ]),
                        m("a", {
                            class:"button-download-container",
                            href:"https://www.mozilla.org/firefox/new/",
                            target:"_blank"
                        }, [
                            m("div", {class:"firefox"}, [
                                m("div", {class:"ico-rounded-container"},[
                                    m("i", {class:"fab fa-firefox"})
                                ]),
                                m("span", i18n.cur.ie_btn_firefox)
                            ])
                        ])
                    ])
                ])
            ])
        ])
    }
}


module.exports = BNS
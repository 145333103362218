var m = require('mithril')
var utils = require('../helpers/Utils')
var auth = require('../services/Auth')
var updateNotif = require('../components/UpdateWidgetNotif')
var i18n = require('../models/TranslationModel')

let Mselect = {
    _researchItem : [], //fakeSearchItem
    all : [],
    _showInput : false,
    _showUpdateNotif : false,
    researchInProgress : false,
    researchTimeOut : null,
    _currentInputText : "",
    researchTimer : null,
    remove : () => null,
    oninit: function(vnode){
        this.isSolo = vnode.attrs.isSolo
        this.ukey = vnode.attrs.ukey
        this.remove = vnode.attrs.remove // take id to remove as params
        this.research = vnode.attrs.research
        this.add = vnode.attrs.add // take item to arg
        this.all = vnode.attrs.all
        this.inputTitle = vnode.attrs.inputTitle
        if(vnode.attrs.minCarac !== null || vnode.attrs.minCarac !== undefined){
            this.minCarac = vnode.attrs.minCarac
        }else{
            this.minCarac = 0
        }
    },
    onupdate: function(){
    },
    view: function(vnode) {
        var that = this
        return m('div', {class:"input-section", /*key : utils.getUniqKey()*/},[
            m('div', {class:"title home"},[
                m('span', vnode.attrs.inputTitle)
            ]),
            m('div', {
                class: 'input-item tags-container multi-select-field',
                onclick : function(e){
                    e.preventDefault()
                    if(e.target.tagName.toUpperCase() != "I"){
                        that.showSelector(e)
                    }
                },  
            }, [
                this.getAllTagsViews(),
                this.getShowInput() ? this.getSearchInput() : null,
                this.getUpdateNotif() ? m(updateNotif, {isAuto : false}) : null
            ]),
            this.getShowInput() ? this.getAutoCompletionResult() : null
        ])
    },
    getAllTagsViews : function(){
        var that = this
        var c = 0
        this.all().forEach(function(item){
            if(!item.__proto__.__proto__){
                c++;
            }
        })
        if(c > 0){
            return this.all().map(function(item) {
                return m("span", {
                    class: "tag",
                    id: item.id,
                    title: item.value,
                    key: item.id + item.value
                },[
                    m('span', {
                        class: "text-tag-value"
                    }, item.value),
                    m('span', {
                        class: 'ico-container',
                        onclick: function(e){
                            e.preventDefault()
                            that.removeItem(item.id, e)
                        }
                    },[
                        m('i', {class: 'fas fa-times'})
                    ]),
                ])
            })
        }else{
            return m("span", {class:"empty-mutli-select-text-container"}, i18n.cur.select_plh)
        }
    },


    // logic

    setResearchItem(x){
        this._researchItem = x
    },
    getResearchItem(){
        return this._researchItem
    },
    showSelector : function(e){
        this.setShowInput(true, e)
        setTimeout(() => {
            var element = document.getElementsByClassName('dynamic-input')[0]
            element == undefined ? null : element.focus()
        }, 200);
    },
    getAllItemList : function(){
        var that = this
        if(this._currentInputText.length < this.minCarac){
            return m("li", {
                class:"item-list",
                tabindex : '100',
                onfocusout : function(e){
                    that.focusIsLost(e)
                }
            },[
                m("span", {class:"tag"}, [
                    m("span", {class:"ico-container"},[
                        m("i", {class:'fas fa-info'})
                    ]),
                    m("span", {class:"text-value"}, i18n.cur.select_min_carac.formatUnicorn({nbrCharac:this.minCarac}))
                ])
            ])
        }else if(this.researchInProgress){
            return m("li", {
                class:"item-list",
                tabindex : '100',
                onfocusout : function(e){
                    that.focusIsLost(e)
                }
            },[
                m("div", {class:"full-w-centering"},[
                    m("div", {class:"lds-ellipsis"}, [
                        m("div"),
                        m("div"),
                        m("div"),
                        m("div")
                    ])
                ])
            ])
        }else if(this.getResearchItem().length === 0){
            return m("li", {
                class:"item-list",
                tabindex : '100',
                onfocusout : function(e){
                    that.focusIsLost(e)
                }
            },[
                m("span", {class:"tag"}, [
                    m("span", {class:"ico-container"},[
                        m("i", {class:'fas fa-info'})
                    ]),
                    m("span", {class:"text-value"}, i18n.cur.select_no_result)
                ])
            ])
        }else{
            return this.getResearchItem().map(function(x){
                return m("li", {
                    class:"item-list",
                    tabindex : '100',
                    onfocusout : function(e){
                        that.focusIsLost(e)
                    },
                    onkeypress : utils.withKey("13", (e) => that.addItem(x,e)),
                    onclick : (e) => that.addItem(x,e)
                },[
                    m("span", {class:"tag"}, [
                        m("span", {class:"ico-container"},[
                            m("i", {class:'fas fa-plus'})
                        ]),
                        m("span", {class:"text-value"}, x.value)
                    ])
                ])
            })
        }
        
    },
    setUpdateNotif : function(x){
        this._showUpdateNotif = x
    },
    getUpdateNotif : function(){
        return this._showUpdateNotif
    },
    getAutoCompletionResult : function(){
        var that = this
        return m('div', {
            class: 'input-item tags-container view-more',
            onclick : function(e){
                e.preventDefault()
            },  
        }, [
            m("ul", {class:"list-container"},[
                that.getAllItemList()
            ])
        ])
    },

    getSearchInput : function(){
        var that = this
        return m("span", {class:"dynamic-input-container tag"},[
            m('input', {
                type : 'text',
                class : 'dynamic-input',
                tabindex : '100',
                id : 'uniq-dynamic-input',
                name : 'dynamic-input',
                autocomplete : 'off',
                onkeydown: function(e) {
                    that.updateInput(e, false)
                },
                onkeyup: function(e) {
                    that.updateInput(e, true)
                },
                onfocusout : function(e){
                    that.focusIsLost(e)
                }
            })
        ])
    },
    getShowInput(){
        return this._showInput
    },
    setShowInput(x, e){
        this._currentInputText = ""
        var that = this
        if(x === false){
            var element = e.target.parentNode
            element.classList.add("basic-exit-scale")
            utils.waitBeforeElementUpdate(e.target.parentNode).then(function(){
                that.setResearchItem([])
                var elements = document.getElementsByClassName("border-top-only")
                Array.from(elements).forEach(x => x.classList.remove("border-top-only"))
                that._showInput = false
                m.redraw()
            })
        }else{
            if(!(this._currentInputText.length < this.minCarac)){
                this.research().then(function(res){
                    that.setResearchItem(res)
                    that._showInput = x
                    setTimeout(() => {
                        if (e.target.classList.contains("tags-container")){
                            e.target.classList.add("border-top-only")
                        }else{
                            if(e.target.closest(".tags-container") !== null){
                                e.target.closest(".tags-container").classList.add("border-top-only")
                            }
                        }
                    }, 150)
                    //m.redraw()
    
                })
            }else{
                that._showInput = x
                setTimeout(() => {
                    if (e.target.classList.contains("tags-container")){
                        e.target.classList.add("border-top-only")
                    }else{
                        if(e.target.closest(".tags-container") !== null){
                            e.target.closest(".tags-container").classList.add("border-top-only")
                        }
                    }
                }, 150)
            }
            
        }
    },
    removeItem : function(item){
        var that = this
        this.remove(item, auth.getJWT()).then(function(){
            that.setUpdateNotif(true)
            setTimeout(() => {
                that.setUpdateNotif(false)
            }, 2000);
        })
    },
    addItem(item,e){
        var that = this
        this.add(item, auth.getJWT()).then(function(){
            that.setUpdateNotif(true)
            setTimeout(() => {
                that.setUpdateNotif(false)
            }, 2000);
        }).catch(err => {
            console.log(err)
        })
        this.setShowInput(false,e)
    },
    updateInput : function(e, updateItemList){
        var that = this
        this._currentInputText = e.target.value.replace(/^\s+|\s+$/g, '')
        if(updateItemList && this._currentInputText.length >= this.minCarac){
            this.researchInProgress = true
            if(this.researchTimer !== null){
                clearTimeout(this.researchTimer)
            }
            // Needed to not start a request at each updateInput() triggered
            this.researchTimer = setTimeout(function(){
                that.setAReasearch(e)
            }, 250)
            
        }
        e.target.style.width = (this.getWidthOfInput(e) + 4) + "px" // first we edit the width of the input
    },
    setAReasearch : function(e){
        var that = this
        this.research(e.target.value.replace(/^\s+|\s+$/g, ''))
        .then(function(matchResult){
            that.researchInProgress = false
            m.redraw()
            that.setResearchItem(matchResult)
            that.researchTimer = null
        })
        
    },
    getWidthOfInput : function(e) { // we create a fake input to calcul the size of each carac
        var x = document.createElement("span")
        x.className = "input-element hidden-element"
        x.innerHTML = e.target.value.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;')
        document.body.appendChild(x)
        var w = x.getBoundingClientRect().width
        document.body.removeChild(x)
        return w
    },
    focusIsLost : function(e){// TODO: check if we lost the focus on component (onfocusout+tabindex is out ouf range of .view-more .list-container)
        var elements = e.target.parentNode.parentNode.parentNode
        .getElementsByClassName("list-container")[0].getElementsByTagName("li")
        var isFocus = false
        Array.from(elements).forEach(x => x === document.activeElement ? isFocus = !isFocus : isFocus)
        if(e === document.activeElement){}
        else if(isFocus){}
        else if(e.relatedTarget !== null && e.relatedTarget.className === "item-list"){}
        else{
            this.setShowInput(false, e)
        }
    }

}


module.exports = Mselect
var m = require('mithril')
var leftNav = require('../components/LeftNav')
var global = require('../Global')
var eventHelper = require('../helpers/EventHelper')
var langNav = require('../components/LangNav')
var footer = require('../components/Footer')
var user = require('../models/User')
var userNav = require('../components/UserNav')
var utils = require('../helpers/Utils')
var cookieView = require('../components/CookieView')

var Layout = {
    _oldTitleVnode : null,
    getSideNav : function(){
        return eventHelper.leftNav.get() ? m(leftNav, {forceLangNav : false}) : '' 
    },
    getCookieView : function(){
        return user.showCookieMessage ? m(cookieView) : '' 
    },
    toggleLeftNav : function(){
        eventHelper.leftNav.set(!eventHelper.leftNav.get())
    },
    getLangNav : function(){
        return eventHelper.langNav.get() ? m(langNav) : ''
    },
    toggleLangNav : function(){
        eventHelper.langNav.set(!eventHelper.langNav.get())
    },
    getUserNav : function(){
        if(user.isLogged()){// normally the user can toggle this nav only if has logged but we check again..
            return eventHelper.userNav.get() ? m(userNav) : ''
        }else{
            return ''
        }
    },
    onupdate : function(vnode){
        if(user.isLogged() && user.getStatus === -1){
            var element = document.getElementsByClassName("right-section")[0].getElementsByClassName("user-ico")[0].getElementsByClassName("fas")[0]
            if(eventHelper.userNav.get() && element.classList[1] === "fa-user"){
                element.classList.add("exit-i")
                utils.waitBeforeElementUpdate(element).then(function(){
                    element.classList.remove("fa-user")
                    element.classList.add("fa-times")
                    element.classList.remove("exit-i")
                })
            }else if(!eventHelper.userNav.get() && element.classList[1] === "fa-times"){ // && is needed cause we dont want any animation on load of the view
                element.classList.add("exit-i")
                utils.waitBeforeElementUpdate(element).then(function(){
                    element.classList.remove("fa-times")
                    element.classList.add("fa-user")
                    element.classList.remove("exit-i")
                })
            }
        }
    },
    toggleUserNav : function(e){
        eventHelper.userNav.set(!eventHelper.userNav.get())
    },
    getRightSection : function(){
        if(!user.isLogged()){
            return m
            ('div', {class: "lang-box lang-defaut"},[
                m('div', {class: 'abs-container'} ,[
                    m('div', {
                        class: "current-lang lang-section clearfix",
                        onclick: function(){
                            Layout.toggleLangNav()
                        }}, 
                        [
                            m('span', {class: "lang-selected"}, [
                                m('span', {class: "lang-text"}, langNav.model.getCurrent().code)
                            ]),
                            m('span', {class: "ico-container"}, [
                                m('i', {class : "fas fa-chevron-down"})
                            ])
                        ]
                    )
                ]),
                Layout.getLangNav()
            ])
        }else{
            return m("div", {class : "right-section"}, [
                m("div", {
                    class:"user-ico"
                }, 
                [
                    user.getStatus() === -1 ? m("i", {
                        class:"fas fa-user",
                        onclick: function(e){
                            Layout.toggleUserNav(e)
                        }
                    }) : ''
                ])
            ])
        }
    },
    view: function(vnode) {
        return m("div", {
            id:"mithril-app"
        },
        [
            m("div", {id:"main-app"},
            [
                Layout.getCookieView(),
                Layout.getSideNav(),
                m("div", {class:"m-d-none left-desktop-nav"},[
                    m(leftNav, {forceLangNav : true})
                ]),
                m("section", {"id":"main-area"}, [
                    m("div", {id: "header-mobile", class:"d-d-none"},[
                        m("span", {class: "left-section"}, [
                            m("img", {
                                "src": global.url.assets + "images/burger-nav.svg",
                                "alt": "Bouton de navigation",
                                id:"burger-nav",
                                onclick: function(){
                                    Layout.toggleLeftNav()
                                }
                            })
                        ]),
                        m("span", {class: "middle-section"}, [
                            m("span", {class : "logo-container"}, [
                                m("img", {"alt" : "Logo PRÔlyrix","src": global.url.assets + "images/prolyrix.svg"})
                            ])
                        ]),
                        m("span", {class: "right-section"}, [
                            Layout.getRightSection()
                        ])
                    ]),
                    m("div", {class:"header-desktop m-d-none"}, [
                        Layout.getDesktopHeader(vnode)
                    ]),
                    Layout.getUserNav(),
                    m("div", {id : "mobile-content"}, [vnode.children])
                ])
            ]),
            m(footer),
            m('script', {src:"https://www.google.com/recaptcha/api.js?render=6LccgW0kAAAAAKDv4Gkb9YYdI3-A08Ms_ZCcpev2"})
        ])
    },
    getDesktopHeader : function(vnode){
        
        if(user.isLogged()){
            return m("div", {id:"login-nav"},[
                m("div", {class:"left-side"}, [
                    vnode.attrs != undefined ? m("div", {class:"title-view-container"}, [
                        m("h1", vnode.attrs.titlePage)
                    ]) : ''
                ]),
                user.getStatus() === -1 ? m("div", {class:"right-side"}, [ // we just check that the user status is valid and not pending, verify etc. 
                    m("div", {
                        class:"user-dropdown-nav",
                        onclick : function(){
                            Layout.toggleUserNav()
                        }
                    }, [
                        m("div", {class:"img-circle box-shadow"}, [
                            m("img", {
                                class: "img-user-avatar",
                                alt: user.current.personne.fullname,
                                src: user.current.personne.photoIdentity,
                            })
                        ]),
                        m("div", {class:"user-name-container"},[
                            m("div", {class:"user-name"}, user.current.personne.fullname),
                        ]),
                        m("div", {class:"ico-dropdown-container"}, [
                            m("i", {class:"fas fa-caret-down"})
                        ])
                    ])
                ]) : ''
            ])
        }else{
            return m("div", {class:"disconnect-menu"},[
                m("div", {class:"right-section clearfix"},[
                    
                ])
            ])
        }
    }
}

module.exports = Layout;
var m = require('mithril')
var utils = require('../helpers/Utils')
var auth = require('../services/Auth')
var user = require('../models/User')
var global = require('../Global')
var model = require('../models/Activation')
var i18n = require('../models/TranslationModel')

var Activation = {
    _emailIsReadOnly : true,
    toggleReadOnly: function(){
        var x = Activation._emailIsReadOnly
        var icoIndication = document.getElementById("ico-lock-at-toggle")
        var input = document.getElementById("input_email")
        var buttonText = document.getElementById("edit-email-input")
        var buttonIco = document.getElementById("fa-edit-save-toggle")
        var bgDiv = document.getElementsByClassName("hide-background-element")[0]
        if(x){
            icoIndication.classList.remove("fa-lock")
            icoIndication.classList.add("fa-at")
            buttonText.innerHTML = "SAUVEGARDER"

            bgDiv.classList.remove("hide-background")

            buttonIco.classList.remove('fa-edit')
            buttonIco.classList.add('fa-paper-plane')

            input.removeAttribute('readonly')
            input.style.color = "#000000"

        }else{
            icoIndication.classList.remove("fa-at")
            icoIndication.classList.add("fa-lock")

            buttonText.innerHTML = "MODIFIER"

            bgDiv.classList.add("hide-background")

            buttonIco.classList.add('fa-edit')
            buttonIco.classList.remove('fa-paper-plane')

            input.setAttribute('readonly', true)
            input.style.color = "#757575"
        }
        Activation._emailIsReadOnly = !x
    },
    oninit: function(){
    },
    oncreate: function(){
        var input = document.getElementsByClassName('input_email')[0]
        input.value = user.current.user.email
    },
    view: function(){
        return m
        ("div", {class: "content pending-view uniq-widget"},[
            m("div", {class: "box-shadow widget"},[
                m("h3", {class: "center-title"}, [
                    m("span", [
                        m('i', {class:'fas fa-check'}),
                        m("span", i18n.cur.activ_w_title)
                    ]),
                ]),
                m("div", {class:"step-section"}, [
                    m('p', {class:"description"}, i18n.cur.activ_desc_1),
                    //m('div', {class:"user-email"}, /*User.current.email*/'GiacomoDaVinci@exemple.com'),
                    
                    // FORM 
                    m("div", {class:""},[
                        m('form', { onsubmit: model.post},[
                            // EMAIL
    
                            m('div', {class:"input-section"},[
                                m('div', {class: 'input-item hide-background-element hide-background'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-lock', id:"ico-lock-at-toggle"})
                                    ]),
                                    m('input[readonly]', {
                                        type : 'email',
                                        placeholder : user.current.email,
                                        autocomplete : 'email',
                                        class : 'input_email',
                                        id : 'input_email',
                                        name : 'email',
                                        onfocusout: function(e) {
                                            !Activation._emailIsReadOnly ? model.check.email(e.target.value) : null
                                        }
                                    }),
                                    model.getInputStatesIndicator('email')
                                ]),
                                model.getInputErrorMessage('email')
                            ]),
    
                            // SUBMIT BUTTON
    
                            m('div', {class:"input-section submit-section"}, [
                                m('div', {
                                    class:"submit-button edit-button right",
                                    onclick: function(e){
                                        e.preventDefault()
                                        if(!Activation._emailIsReadOnly){
                                            model.postAction(e).then(function(){
                                                Activation.toggleReadOnly()
                                            }).catch(function(){
                                                Activation.toggleReadOnly()
                                            })
                                        }else{
                                            Activation.toggleReadOnly()
                                        }
                                    }
                                },[
                                    m('label', {class:"align-label"},[
                                        m('i', {class:"fas fa-edit", id: "fa-edit-save-toggle"})
                                    ]),
                                    m('div', { id: 'edit-email-input'}, i18n.cur.activ_button_edit)
                                ])
                            ])
                        ])
                    ]),
                    model.getBackend1(),

                    m('p', {class:"description"}, i18n.cur.activ_desc_2),
                    m('div', {class:'button-container'},[
                        m('button', {
                            class:"button-action send-new-email",
                            onclick:function(){
                                model.needANewEmail().then(function(res){
                                    model.setBackendSuccessMessages2(["Nous venons de vous renvoyer l'email !"])
                                }).catch(function(err) {
                                    console.log(err)
                                })
                            }
                        }, [
                            m('span', {class:"text-button"}, i18n.cur.activ_send_email)
                        ])
                    ]),
                    model.getBackend2(),
                ])
            ]),
        ])
    }
}

module.exports = Activation
var m = require('mithril')
var global = require('../Global')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')
var user = require('../models/User')
var auth = require('../services/Auth')
var i18n = require('../models/TranslationModel')

var Popup = {
    onbeforeremove : function(){
        /*var bgElement = document.getElementById('nav-background')
        bgElement.classList.add('fadeOut')*/
        var navElement = document.getElementById('plain-popup')
        navElement.classList.add('exit-fade')
        return Promise.all([
            utils.waitBeforeElementUpdate(navElement),
            /*utils.waitBeforeElementUpdate(bgElement)*/
        ])
    },
    view: function(ctrl) {
        return m("div", {id : "plain-popup"}, [
            m("div", {class:"popup-box"}, [
                m("div", {class:"popup-container"}, [
                    m("div", {class:"top-border danger"}),
                    m("div", {class:"header-popup danger-color"},[
                        m("span", {class:"ico-info-container"}, [
                            m('i', {class: 'fas fa-exclamation-triangle'})
                        ]),
                        m("span", {class:"title-popup"}, i18n.cur.setting_ppp_rmv_acc_title),
                        m("span", {class:"ico-exit-popup"}, [
                            m('i', {
                                class: 'fas fa-times',
                                onclick : function(){
                                    eventHelper.removeAccountPopup.set(false)
                                }
                            })
                        ]),
                    ]),
                    m("div", {class:"body-popup"}, [
                        m("div", {class:"section"},[
                            m("span", i18n.cur.setting_ppp_rmv_acc_desc_1),
                            m("span", {class:"bold"}, "Ôlyrix.")
                        ]),
                        m("div", {class:"section"},[
                            m("span", i18n.cur.setting_ppp_rmv_acc_desc_2)
                        ])
                    ]),
                    m("div", {class:"footer-popup"}, [
                        m("div", {class:"button-container"}, [
                            m("button", {
                                class:"button-with-ico brown",
                                onclick: function(){
                                    eventHelper.removeAccountPopup.set(false)
                                }
                            }, [
                                m('span', {class: 'left-ico'},[
                                    m('i', {class: 'fas fa-long-arrow-alt-left'})
                                ]),
                                m("span", {class:"text-button"}, i18n.cur.setting_ppp_rmv_acc_back)
                            ]),
                            m("button", {
                                class:"button-with-ico danger",
                                onclick: function(){
                                    user.removeAccount(auth.getJWT()).then(function(res){
                                        user.setLogged(false)
                                        auth.removeJWT()
                                        eventHelper.removeAccountPopup.set(false)
                                        m.route.set("/login")
                                        
                                    })
                                }
                            }, [
                                m('span', {class: 'left-ico'},[
                                    m('i', {class: 'fas fa-user-slash'})
                                ]),
                                m("span", {class:"text-button"}, i18n.cur.setting_ppp_rmv_acc_confirm)
                            ])
                        ])
                    ])
                ]),
            ]),
            m(Popup.Background)
        ])
    }
}

Popup.Background = {
    view : function(ctrl){
        return m("div", {
            id: 'nav-background',
            class: 'fadeIn',
            onclick : function(){
                eventHelper.removeAccountPopup.set(false)
            }
        })
    }
}

module.exports = Popup

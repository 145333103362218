var m = require('mithril')
var utils = require('../helpers/Utils')
var auth = require('../services/Auth')
var Loader = require('../components/WidgetLoader')
var eventHelper = require('../helpers/EventHelper')
var model = require('../models/Verify')
var i18n = require('../models/TranslationModel')
var infoDiv = require('../components/InfoDiv')

var Verify = {
    getLoader : function(){
        return eventHelper.widgetLoader.get() ? m(Loader) : ''
    },
    toggleLoader : function(){
        eventHelper.widgetLoader.set(!eventHelper.widgetLoader.get())
    },
    view : function(){
        return m
        ("div", {class: "content verify-view uniq-widget"},[
            m("div", {class: "box-shadow widget"},[
                m("h3", {class: "center-title"}, [
                    m("span", [
                        m('i', {class:'fas fa-lock'}),
                        m("span", i18n.cur.verify_w_title)
                    ]),
                ]),
                m("div", {class:"verify-form"}, [
                    m('form', { onsubmit: model.post},[
                        m('script', {src:"https://www.google.com/recaptcha/api.js?render=6LccgW0kAAAAAKDv4Gkb9YYdI3-A08Ms_ZCcpev2"}),
                        
                        // file id 

                        m('div', {class:"input-section border-bottom-input-file input-type-file"},[
                            m('div', {class:"title"},[
                                m('span', i18n.cur.verify_in_identity)
                            ]),
                            m('div', {class: 'input-item'},[
                                m('input[multiple]', {
                                    type : 'file',
                                    name : "attachment[]",
                                    class : 'input_identity file_input',
                                    onchange: function(e) {
                                        model.check.file1(e)
                                    }
                                }),
                                m('label', {for:'input_identity'},[

                                    m('div', {class:"browse"}, [
                                        m('span', i18n.cur.verify_in_browse)
                                    ]),
                                    m('span', {class:"file-name"}, [
                                        m('span', {class:'italic files-name'}, i18n.cur.verify_in_upload)
                                    ])
                                ]),
                                model.getInputStatesIndicator('file1')
                            ]),
                            model.getInputErrorMessage('file1')
                        ]),

                        // file attestation

                        /*m('div', {class:"input-section input-type-file"},[
                            m('div', {class:"title"},[
                                m('span', i18n.cur.verify_in_work)
                            ]),
                            m('div', {class: 'input-item'},[
                                m('input[multiple]', {
                                    type : 'file',
                                    class : 'input_attestation file_input',
                                    name : "attachment[]",
                                    onchange: function(e) {
                                        model.check.file2(e)
                                    }
                                }),
                                m('label', {for:'input_identity'},[

                                    m('div', {class:"browse"}, [
                                        m('span', i18n.cur.verify_in_browse)
                                    ]),
                                    m('span', {class:"file-name"}, [
                                        m('span', {class:'italic files-name'}, i18n.cur.verify_in_upload)
                                    ])
                                ]),
                                model.getInputStatesIndicator('file2')
                            ]),
                            model.getInputErrorMessage('file2')
                        ]),*/
                        m("div", {class:"verify-infodiv-container"}, [
                            m(infoDiv, {text : i18n.cur.verify_justify})
                        ]),
                        // SUBMIT BUTTON

                        m('div', {class:"input-section submit-section"}, [
                            m('div', {class:"submit-button right"},[
                                m('input', {
                                    id: 'register-input',
                                    type: 'submit',
                                    value: i18n.cur.verify_submit,
                                    onclick: function(e){
                                        e.preventDefault()
                                        Verify.toggleLoader()
                                        model.postAction(e).then(function(mes){
                                            Verify.toggleLoader()
                                        }).catch(function(err){
                                            console.log(err)
                                            Verify.toggleLoader()
                                        })
                                    }
                                })
                            ])
                        ])
                    ])
                ]),
                model.getBackendResponse(),
                Verify.getLoader()
            ])
        ])
    }
}

module.exports = Verify
var m = require('mithril')
var utils = require('../helpers/Utils')
var eventHelper = require('../helpers/EventHelper')
var i18n = require('../models/TranslationModel')


var model = {
    current : {},
    _backendErrorMessages : [],
    getAllBackendError : function(){
        var messages = model._backendErrorMessages
        if(messages !== [] && messages !== undefined){
            return m("div", {class: "backend-errors-container"},[
                messages.map(x => 
                    m('span', {class:"error"}, x))
            ])
        }
        return ''
    },
    submit : function(){
        if(model.checkAllInput()){
            utils.getRecaptcha().then(function(token){ //here we check the token
                model.current.token_recaptcha = token
                model.post()
            })
        }
    },
    _formStates : {// state -1 = initial / state 0 = error / state 1 = valid
        firstName : {state : -1},
        lastName : {state : -1},
        email : {state : -1},
        object : {state : -1},
        message : {state : -1},
        file : {state : -1}
    },
    checkAllInput : function(){
        var form = document.getElementsByClassName('contact-form')[0]

        model.check.lastName(form.getElementsByClassName('input_lastname')[0].value)
        model.check.firstName(form.getElementsByClassName('input_firstname')[0].value)
        model.check.object(form.getElementsByClassName('input_object')[0].value)
        model.check.email(form.getElementsByClassName('input_email')[0].value)
        model.check.message(form.getElementsByClassName('input_message')[0].value)

        return !model.registerHaveError()
        

    },
    registerHaveError(){
        var errorUccured = true

        if(model._formStates.lastName.state === 1
        && model._formStates.firstName.state === 1
        && model._formStates.email.state === 1
        && model._formStates.message.state === 1
        && model._formStates.object.state === 1)
        {
            errorUccured = false
        }

        return errorUccured
    },
    getStates : function (){
        return model._formStates
    },
    setFormStates : function (newFormStates){
        model._formStates = newFormStates
    },
    post : function(e){
        return new Promise(function(resolve, reject){
            if(model.checkAllInput()){
                utils.getRecaptcha().then(function(token){ //here we check the token
                    model.current.token_recaptcha = token

                    var form = e.target.form

                    var formData = new FormData()
                    formData.append('email', model.current.email)
                    formData.append('firstname', model.current.firstname)
                    formData.append('lastname', model.current.lastname)
                    formData.append('object', model.current.object)
                    formData.append('message', model.current.message)
                    formData.append('token_recaptcha', model.current.token_recaptcha)


                    var count = form.getElementsByClassName('input_file_contact')[0].files.length
                    for (var x = 0; x < count; x++) {
                        formData.append("file[]", form.getElementsByClassName('input_file_contact')[0].files[x]);
                    }

                    m.request({
                        method: "POST",
                        url: "api/email/contact",
                        body: formData,
                        headers : {
                            'X-CSRF-TOKEN': utils.getCsrf()
                        },
                        serialize: function(value) {return value},
                        config: function(xhr) {
                            xhr.upload.addEventListener("progress", function(e) {
                                var progress = e.loaded / e.total
                                console.log(progress)
                            })
                        }
                    }).then(function(res) {
                        form.reset()
                        model.cleanAllForm()
                        model.clearFileInput(form.getElementsByClassName('input_file_contact')[0])
                        resolve(res)
                    }).catch(function(err) {
                        console.log('erreur sur la requête post');
                        console.log(err)
                        reject(err)
                        //reject(err)
                    })
                }).catch(function(err){
                    console.log('Erreur getRecaptcha');
                    console.log(err);
                    reject(err);
                })
            }else{
                reject()
            }
        })
    },
    getInputErrorMessage : function(stringEnum){
        var message = model.getInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getInputStatesIndicator : function(stringEnum){
        var state = model.getInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getInputStates : function(stringEnum){
        var o
        switch (stringEnum) {
            case 'firstName':
                o = model._formStates.firstName
            break;
            case 'lastName':
                o = model._formStates.lastName
            break;
            case 'email':
                o = model._formStates.email
            break;
            case 'object':
                o = model._formStates.object
            break;
            case 'message':
                o = model._formStates.message
            break;
            case 'file':
                o = model._formStates.file
            break;
            default:
                o = null
        }
        return o
    },
    clearFileInput(input){
        console.log(input)
        input.value = ""
        var element = input.parentNode.getElementsByClassName('files-name')[0]
        element.innerHTML = i18n.cur.contact_required
    },
    cleanAllForm: function(){

        model._formStates = {// state -1 = initial / state 0 = error / state 1 = valid
            firstName : {state : -1},
            lastName : {state : -1},
            email : {state : -1},
            object : {state : -1},
            message : {state : -1},
            file : {state : -1}
        }

        model.current = {}
    },
    check : {
        firstName : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 2, 80)){// min-mac carac
                State.firstName.state = 0
                State.firstName.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "2", MaxCharac : "80"})
            }else{
                State.firstName.message = null
                model.current.firstname = e
                State.firstName.state = 1
            }
            model.setFormStates(State)
        },
        lastName : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 2, 80)){// min-mac carac
                State.lastName.state = 0
                State.lastName.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "2", MaxCharac : "80"})
            }else{
                State.lastName.message = null
                model.current.lastname = e
                State.lastName.state = 1
            }
            model.setFormStates(State)
        },
        email : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 5, 255)){// min-mac carac
                State.email.state = 0
                State.email.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "5", MaxCharac : "255"})
            }else if(model.check.validateEmail(e)){
                State.email.state = 0
                State.email.message = i18n.cur.error_email_not_valid
            }else{
                State.email.message = null
                model.current.email = e
                State.email.state = 1
            }
            model.setFormStates(State)
        },
        object : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 6, 255)){// min-mac carac
                State.object.state = 0
                State.object.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "6", MaxCharac : "255"})
            }else{
                State.object.message = null
                model.current.object = e
                State.object.state = 1
            }
            model.setFormStates(State)
        },
        message : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 10, 2000)){// min-mac carac
                State.message.state = 0
                State.message.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "10", MaxCharac : "2000"})
            }else{
                State.message.message = null
                model.current.message = e
                State.message.state = 1
            }
            model.setFormStates(State)
        },
        validateLengthCarac: function(e, minCarac, maxCarac){
            var length = e.length
            return !(length >= minCarac && length <= maxCarac)
        },
        validateEmail(e) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return !(re.test(String(e).toLowerCase()))
        },
        file: function(e){
            var signupState = model.getStates()
            var files = e.target.files
            if(files.length >= 1){
                if(files.length > 3){
                    model.clearFileInput(e.target)
                    signupState.file.state = 0
                    signupState.file.message = i18n.cur.error_too_many_files.formatUnicorn({n : "3"})
                }else if(utils.checkLimitFileSize(files)){
                    model.clearFileInput(e.target)
                    signupState.file.state = 0
                    signupState.file.message = i18n.cur.error_ic_too_big.formatUnicorn({n : "15"})
            
                }else if(utils.checkIfFormatIsAuthorize(files)){
                    model.clearFileInput(e.target)
                    signupState.file.state = 0
                    signupState.file.message = i18n.cur.error_wrong_files_type.formatUnicorn({filesType : " PDF,JPG,PNG"})//PDF,JPG,PNG"
                }else{
                    if(files.length === 1){
                        var element = e.target.parentNode.getElementsByClassName('files-name')[0]
                        element.innerHTML = files[0].name
                    }else{
                        var element = e.target.parentNode.getElementsByClassName('files-name')[0]
                        element.innerHTML = i18n.cur.contact_input_files_info.formatUnicorn({n : String(files.length)})
                    }
                    signupState.file.state = 1
                    signupState.file.message = null
                }
            }
            model.setFormStates(signupState)
        }
    }
}

module.exports = model




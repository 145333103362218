var m = require('mithril')
var auth = require('./services/Auth')
var utils = require('./helpers/Utils')
var browsers = require('./helpers/Browsers')
var user = require('./models/User')
/* VIEWS SECTION */
var homeView = require('./views/Home')
var lostPasswordView = require('./views/LostPassword')
var lostPasswordWithKeyView = require('./views/LostPasswordWithToken')
var verifyView = require('./views/Verify')
var activationView = require('./views/Activation')
var signView = require('./views/Login')
var pendingView = require('./views/Pending')
var layoutView = require('./views/Layout')
var browsersNotSupported = require('./views/errors/BrowsersNotSupported')
var contactView = require('./views/Contact')
var settingView = require('./views/Setting')
var i18n = require('./models/TranslationModel')
var thermsView = require('./views/Therms')
var legalNoticeView = require('./views/LegalNotice')

var app = {
    init : function(){
        return new Promise((resolve, reject) => {
            var BrowsersErrors = browsers.check()
            user.loadCookieMessage() // check in storage if user has already close the cookie view
            if(BrowsersErrors !== false){
                if(BrowsersErrors === "IE"){
                    m.route.set("/browsers-not-supported")
                    app.setRouter()
                }
            }else{
                i18n.loadAll()
                .then(function(){
                    auth.init(i18n)
                    .then(function(res){
                        app.setResizeWindowEvent()
                        app.setRouter()
                        
                    }).catch(function(err){
                        // here check status and redirect
                        if(auth.getJWT()){
                            app.setRouter()
                        }else{
                            app.setRouter()
                        }
                    })
                })
                .catch((err) =>
                    reject()
                )
            }
            resolve()
        })
    },
    setRouter : function() {
        m.route(document.getElementById('app'), "/account/home", {
            // only access when no logged : 
            "/browsers-not-supported": {
                render: () => m(browsersNotSupported)
            },
            "/login": {
                onmatch: function(){
                    return app.checkLoginRoute(signView)
                },
                render: (vnode) => m(layoutView, vnode)
            },
            "/lost-password" : {
                onmatch: function(){
                    if(!user.isLogged()){
                        return lostPasswordView
                    }else{
                        m.route.set("/account/home")
                    }
                },
                render: (vnode) => m(layoutView, vnode)
            },
            "/lost-password/:token": {
                onmatch: function(){
                    if(!user.isLogged()){
                        return lostPasswordWithKeyView
                    }else{
                        m.route.set("/account/home")
                    }
                },
                render: (vnode) => m(layoutView, vnode)
            },

            // access all the user state

            "/contact" : {
                onmatch: function(){
                    return contactView
                },
                render: (vnode) => m(layoutView, {titlePage : "CONTACT"}, vnode)
            },

            "/legal-notice" : {
                onmatch: function(){
                    return legalNoticeView
                },
                render: (vnode) => m(layoutView, {titlePage : "Mentions légales"}, vnode)
            },

            "/therms-of-service" : {
                onmatch: function(){
                    return thermsView
                },
                render: (vnode) => m(layoutView, {titlePage : "CGU/CGV"}, vnode)
            },


            "/account/verify" : {
                onmatch: function(){
                    return app.checkLoggedRoute(verifyView, '/account/verify')
                },
                render: (vnode) => m(layoutView, {titlePage : i18n.cur.verify_w_title}, vnode)
            },
            "/account/pending" : {
                onmatch: function(){
                    return app.checkLoggedRoute(pendingView, '/account/pending')
                },
                render: (vnode) => m(layoutView, {titlePage : i18n.cur.pending_title}, vnode)
            },
            "/account/activation" : {
                onmatch: function(){
                    return app.checkLoggedRoute(activationView, '/account/activation')
                },
                render: (vnode) => m(layoutView, {titlePage : i18n.cur.activ_w_title}, vnode)
            },
            "/account/home" : {
                onmatch: function(){
                    return app.checkLoggedRoute(homeView, "/account/home")
                },
                render: function(vnode) {
                    return m(layoutView, {titlePage : i18n.cur.left_nav_public_data} , vnode)
                }
            },
            "/account/setting" : {
                onmatch: function(){
                    return app.checkLoggedRoute(settingView, "/account/setting")
                },
                render: (vnode) => m(layoutView, {titlePage : i18n.cur.left_nav_settings}, vnode)
            },
        })
    },
    checkLoggedRoute(viewIfMatch, trust = "/login"){
        if(user.isLogged()){
            if(trust === "/account/home" || trust === "/account/setting"){
                return viewIfMatch
            }else{
                m.route.set('/account/home')
            }
        }else if(!user.isLogged() && user.getRStatus() == 2 ){
            if(trust == '/account/activation'){
                return viewIfMatch
            }else{
                m.route.set('/account/activation')
            }
        }else if(!user.isLogged() && user.getRStatus() == 0){
            if(trust == '/account/verify'){
                return viewIfMatch
            }else{
                m.route.set('/account/verify')
            }
        }else if(!user.isLogged() && user.getRStatus() == 1){
            if(trust == '/account/pending'){
                return viewIfMatch
            }else{
                m.route.set('/account/pending')
            }
        }else if(!user.isLogged() && user.getRStatus() == undefined){
            m.route.set("/login")
        }
        else{
            m.route.set(trust)
        }
        
    },
    checkLoginRoute(viewIfMatch, trust = "/login"){
        if(!user.isLogged() && user.getRStatus() === undefined){
            return viewIfMatch
        }else if(!user.isLogged() && user.getRStatus() == 2 ){
            if(trust == '/account/activation'){
                return viewIfMatch
            }else{
                m.route.set('/account/activation')
            }
        }else if(!user.isLogged() && user.getRStatus() == 0){
            if(trust == '/account/verify'){
                return viewIfMatch
            }else{
                m.route.set('/account/verify')
            }
        }else if(!user.isLogged() && user.getRStatus() == 1){
            if(trust == '/account/pending'){
                return viewIfMatch
            }else{
                m.route.set('/account/pending')
            }
        }else if(user.isLogged() && trust == "/login"){
            m.route.set("/account/home")
        }else{
            m.route.set(trust)
        }
        
    },
    setResizeWindowEvent : function(){
        utils.onDesktop()
        window.onresize = () => utils.onDesktop()
    }
}

app.init().then(function() {
    //console.log('The application\'s loaded correctly')
}, function(err) {
    console.log(err)
    console.error('An error occurred while loading the application')
})
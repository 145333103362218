var m = require('mithril')
var Global = require('../Global')
var localData = require('../models/LocalData')
var utils = require('../helpers/Utils')
var auth = require('../services/Auth')

var Entity = {}

Entity.oeuvre = {
    researchByName : function(x){
        return new Promise(function(resolve, reject){
            m.request({
                method: 'GET',
                url: Global.api.data.oeuvre,
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " +  auth.getJWT(),
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                var returnedData = []
                Object.entries(res).map(function(item){
                    if(item[0] !== '0'){
                        returnedData.push({id : item[0], value : item[1].name})
                    }
                })
                resolve(returnedData)
            })
            .catch(function(err) {
                console.log(err)
            })
            
        })
    },
    tags : {
        searchXHR : null,
        abortPreviousSearch : function() {
            if (this.searchXHR !== null) this.searchXHR.abort()
            this.searchXHR = null
        },
        researchByName : function(x){
            this.abortPreviousSearch()
            var that = this
            return new Promise(function(resolve, reject){
                m.request({
                    method: 'GET',
                    url: Global.api.data.oeuvreTag + "?q=" + x,
                    headers : {
                        'Content-Type' : 'application/json', 
                        Authorization: "Bearer " +  auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                    config: function(xhr) {that.searchXHR = xhr}
                })
                .then(function(res) {
                    var returnedData = []
                    var itemToBuild= []
                    Object.entries(res).map(function(item){
                        if(item[0] === "searchresults"){
                            item[1].map(function(item2){
                                itemToBuild.push({id : item2.id, value : item2.text})
                            })
                        }
                    })
                    Object.entries(res).map(function(itemF){
                        if(itemF[0] === "fullresultset"){
                            var index = 0
                            itemF[1].map(function(item2){
                                itemToBuild[index].all = item2
                                index++
                            })
                        }
                    })
                    if(!(itemToBuild === null)){
                        returnedData = itemToBuild 
                    }
                    resolve(returnedData)
                })
                .catch(function(err) {
                    console.log(err)
                })
                
            })
        }
    }
}
Entity.personne = {
    researchByName : function(x){
        return new Promise(function(resolve, reject){
            m.request({
                method: 'GET',
                url: Global.api.data.personneTag + "?q=" + x,
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " +  auth.getJWT(),
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                var personnes = []
                Object.entries(res).map(function(item){
                    personnes.push({id : item[1].id, value : item[1].text})
                })
                resolve(personnes)
            })
            .catch(function(err) {
                console.log(err)
            })
            
        })
    },
    tags : {
        searchXHR : null,
        abortPreviousSearch : function() {
            if (this.searchXHR !== null) this.searchXHR.abort()
            this.searchXHR = null
        },
        researchByName : function(x){
            this.abortPreviousSearch()
            var that = this
            return new Promise(function(resolve, reject){
                m.request({
                    method: 'GET',
                    url: Global.api.data.personneTag + "?q=" + x,
                    headers : {
                        'Content-Type' : 'application/json', 
                        Authorization: "Bearer " +  auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf(),
                        config: function(xhr) {that.searchXHR = xhr}
                    },
                })
                .then(function(res) {
                    var personnes = []
                    Object.entries(res).map(function(item){
                        personnes.push({id : item[1].id, value : item[1].text})
                    })
                    resolve(personnes)
                })
                .catch(function(err) {
                    console.log(err)
                })
                
            })
        },
    }
}
Entity.production = {
    tags : {
        searchXHR : null,
        abortPreviousSearch : function() {
            if (this.searchXHR !== null) this.searchXHR.abort()
            this.searchXHR = null
        },
        researchByName : function(x, oid){
            this.abortPreviousSearch()
            var that = this
            return new Promise(function(resolve, reject){
                m.request({
                    method: 'GET',
                    url: Global.api.data.productionTag + "?q=" + x + "&oid=" + oid,
                    headers : {
                        'Content-Type' : 'application/json', 
                        Authorization: "Bearer " +  auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                    config: function(xhr) {that.searchXHR = xhr}
                })
                .then(function(res) {
                    var returnedData = []
                    var itemToBuild= []
                    Object.entries(res).map(function(item){
                        if(item[0] === "searchresults"){
                            item[1].map(function(item2){
                                itemToBuild.push({id : item2.id, value : item2.text})
                            })
                        }
                    })
                    Object.entries(res).map(function(itemF){
                        if(itemF[0] === "fullresultset"){
                            var index = 0
                            itemF[1].map(function(item2){
                                itemToBuild[index].all = item2
                                index++
                            })
                        }
                    })
                    if(!(itemToBuild === null)){
                        returnedData = itemToBuild 
                    }
                    resolve(returnedData)
                })
                .catch(function(err) {
                    console.log(err)
                })
                
            })
        }
    }
}
Entity.reprise = {
    tags : {
        researchByName : function(x, oid, pid){
            var that = this
            return new Promise(function(resolve, reject){
                m.request({
                    method: 'GET',
                    url: Global.api.data.repriseTag + "?q=" + x + "&oid=" + oid + "&pid=" + pid,
                    headers : {
                        'Content-Type' : 'application/json', 
                        Authorization: "Bearer " +  auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                })
                .then(function(res) {
                    var returnedData = []
                    var itemToBuild= []
                    Object.entries(res).map(function(item){
                        if(item[0] === "searchresults"){
                            item[1].map(function(item2){
                                itemToBuild.push({id : item2.id, value : item2.text})
                            })
                        }
                    })
                    Object.entries(res).map(function(itemF){
                        if(itemF[0] === "fullresultset"){
                            var index = 0
                            itemF[1].map(function(item2){
                                itemToBuild[index].all = item2
                                index++
                            })
                        }
                    })
                    if(!(itemToBuild === null)){
                        returnedData = itemToBuild 
                    }
                    resolve(returnedData)
                })
                .catch(function(err) {
                    reject(err)
                })
                
            })
        }
    }
}
Entity.distribution = {
    tags : {
        researchByName : function(x, oid, pid, rid){
            var that = this
            return new Promise(function(resolve, reject){
                m.request({
                    method: 'GET',
                    url: Global.api.data.distributionTag + "?q=" + x + "&oid=" + oid + "&pid=" + pid + "&rid=" + rid,
                    headers : {
                        'Content-Type' : 'application/json', 
                        Authorization: "Bearer " +  auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                })
                .then(function(res) {
                    var returnedData = []
                    var itemToBuild= []
                    Object.entries(res).map(function(item){
                        if(item[0] === "searchresults"){
                            item[1].map(function(item2){
                                itemToBuild.push({id : item2.id, value : item2.text})
                            })
                        }
                    })
                    Object.entries(res).map(function(itemF){
                        if(itemF[0] === "fullresultset"){
                            var index = 0
                            itemF[1].map(function(item2){
                                itemToBuild[index].all = item2
                                index++
                            })
                        }
                    })
                    if(!(itemToBuild === null)){
                        returnedData = itemToBuild 
                    }
                    resolve(returnedData)
                })
                .catch(function(err) {
                    reject(err)
                })
                
            })
        }
    }
}
Entity.location = {
    tags : {
        researchByName : function(x){
            var that = this
            return new Promise(function(resolve, reject){
                m.request({
                    method: 'GET',
                    url: Global.api.data.lieuTag + "?q=" + x,
                    headers : {
                        'Content-Type' : 'application/json', 
                        Authorization: "Bearer " +  auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                })
                .then(function(res) {
                    var data = []
                    Object.entries(res).map(function(item){
                        data.push({id : item[1].id, value : item[1].text})
                    })
                    resolve(data)
                })
                .catch(function(err) {
                    reject(err)
                })
                
            })
        }
    }
}
Entity.season = {}


module.exports = Entity;
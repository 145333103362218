var m = require('mithril')
var utils = require('../helpers/utils')
var eventHelper = require('../helpers/EventHelper')
var i18n = require('../models/TranslationModel')
var auth = require('../services/Auth')
var global = require('../Global')
var user = require('../models/User')

var model = {
    current : {},
    _backendErrorMessages : [],
    getAllBackendError : function(){
        var messages = model._backendErrorMessages
        if(messages !== [] && messages !== undefined){
            return m("div", {class: "backend-errors-container"},[
                messages.map(x => 
                    m('span', {class:"error"}, x))
            ])
        }
        return ''
    },
    _formStates : {// state -1 = initial / state 0 = error / state 1 = valid
        credit : {state : -1},
    },
    checkAllInput : function(e){ //TODO
        var form = utils.findAncestor(e.target, "send-form")
        /*model.check.firstName(form.getElementsByClassName('input_firstname')[0].value)*/
        model.check.credit(form.getElementsByClassName('input_credit')[0].value)
        /*model.check.email(form.getElementsByClassName('input_email')[0].value)
        model.check.description(form.getElementsByClassName('input_message')[0].value)*/
        return !model.registerHaveError()
        

    },
    registerHaveError(){
        var errorUccured = false

        if(model._formStates.credit.state == 0)
        {
            errorUccured = true
        }

        return errorUccured
    },
    getStates : function (){
        return model._formStates
    },
    setFormStates : function (newFormStates){
        model._formStates = newFormStates
    },
    post : function(e, blob){
        return new Promise(function(resolve, reject){
            if(model.checkAllInput(e)){
                var formData = new FormData()
                var form = e.target.form
                var credit = form.getElementsByClassName('input_credit')[0].value
                if(credit == ""){
                    credit = "DR"
                }
                formData.append('credits', credit)
                formData.append('image', blob, user.current.personne.fullname + '.png')

                m.request({
                    method: "POST",
                    url: "/api/requests/personne/avatar",
                    body: formData,
                    serialize: function(value) {return value},
                    headers : {
                        Authorization: "Bearer " + auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                    config: function(xhr) {
                        xhr.upload.addEventListener("progress", function(e) {
                            var progress = e.loaded / e.total
                        })
                    }
                }).then(function(res) {
                    resolve(res)
                }).catch(function(err) {
                    console.log(err)
                    reject(err)
                })
            }else{
                reject()
            }
        })
    },
    getInputErrorMessage : function(stringEnum){
        var message = model.getInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getInputStatesIndicator : function(stringEnum){
        var state = model.getInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getInputStates : function(stringEnum){
        var o
        switch (stringEnum) {
            case 'credit':
                o = model._formStates.credit
            break;
            default:
                o = null
        }
        return o
    },
    clearFileInput(input){
        input.value = ""
    },
    cleanAllForm: function(){

        model._formStates = { // state -1 = initial / state 0 = error / state 1 = valid
            credit : {state : -1},
        }
        model.current = {}
    },
    check : {
        credit : function(e){
            var State = model.getStates()
            if(model.check.validateLengthCarac(e, 0, 255)){// min-mac carac
                State.credit.state = 0
                State.credit.message = "Ce champ doit avoir entre 10 et 255 caractères."
            }else if(e.length === 0){
                State.credit.state = -1
            }
            else{
                State.credit.message = null
                model.current.credits = e
                State.credit.state = 1
            }
            model.setFormStates(State)
        },
        validateLengthCarac: function(e, minCarac, maxCarac){
            var length = e.length
            return !(length >= minCarac && length <= maxCarac)
        }
    }
}

module.exports = model
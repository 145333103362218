var m = require('mithril')
var Utils = require('../helpers/Utils')
var Global = require('../Global')
var model = require('../models/LostPasswordWithToken')
var i18n = require('../models/TranslationModel')

var LostPasswordWithKey = {
    oninit: function(vnode){
        model.token = vnode.attrs.token
    },
    view : function(vnode){
        return m
        ("div", {class: "content lost-password-view"},[
            m("div", {class: "box-shadow widget"},[
                m("h3", {class: "center-title"}, [
                    m("span", m.trust(i18n.cur.n_pwd_title.formatUnicorn({prolyrix : "<span class='text-grey'>PRÔlyrix</span>"})))
                ]),
                m("div", {class:"description-lost-password"},[
                    m("p", i18n.cur.n_pwd_desc)
                ]),
                m("div", {class:"lost-password-form"},[
                    m('form', { onsubmit: model.post},[
                        
                        // PASSWORD

                        m('div', {class:"input-section"},[
                            m('div', {class:"title"},[
                                m('span', i18n.cur.n_pwd_in_password)
                            ]),
                            m('div', {class: 'input-item'},[
                                m('span', {class: 'left-ico'},[
                                    m('i', {class: 'fas fa-key'})
                                ]),
                                m('input', {
                                    type : 'password',
                                    placeholder : '****************',
                                    autocomplete : 'new-password',
                                    class : 'input_password',
                                    name : 'password',
                                    onfocusout: function(e) {
                                        model.check.password(e.target.value)
                                    }
                                }),
                                model.getInputStatesIndicator('password')
                            ]),
                            model.getInputErrorMessage('password')
                        ]),

                        // PASSWORD_VERIF

                        m('div', {class:"input-section"},[
                            m('div', {class:"title"},[
                                m('span', i18n.cur.n_pwd_in_password_confirm)
                            ]),
                            m('div', {class: 'input-item'},[
                                m('span', {class: 'left-ico'},[
                                    m('i', {class: 'fas fa-key'})
                                ]),
                                m('input', {
                                    type : 'password',
                                    placeholder : '****************',
                                    autocomplete : 'new-password',
                                    id : 'input_register_password_confirmation',
                                    class : 'input_password_confirmation',
                                    name : 'password_confirmation',
                                    onfocusout: function(e) {
                                        model.check.password_confirmation(e.target.value)
                                    }
                                }),
                                model.getInputStatesIndicator('password_confirmation')
                            ]),
                            model.getInputErrorMessage('password_confirmation')
                        ]),


                        // SUBMIT BUTTON

                        m('div', {class:"input-section submit-section"}, [
                            m('div', {class:"submit-button right"},[
                                m('input', {
                                    id: 'register-input',
                                    type: 'submit',
                                    value: i18n.cur.n_pwd_in_submit,
                                    onclick: function(e){
                                        e.preventDefault()
                                        model.submit(vnode.attrs.token)
                                    }
                                })
                            ])
                        ])
                    ])
                ]),
                model.getBackendResponse()
            ])
        ])
    }
}

module.exports = LostPasswordWithKey
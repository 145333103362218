var m = require('mithril')
var utils = require('../helpers/Utils')
var eventHelper = require('../helpers/EventHelper')
var auth = require('../services/Auth')
var user = require('../models/User')
var i18n = require('../models/TranslationModel')

var model = {
    getUser : function(){
        return user
    }
}
model.general = {
    current : {},
    _backendErrorMessages : [],
    getAllBackendError : function(){
        var messages = model.general._backendErrorMessages
        if(messages !== [] && messages !== undefined){
            return m("div", {class: "backend-errors-container"},[
                messages.map(x => 
                    m('span', {class:"error"}, x))
            ])
        }
        return ''
    },
    submit : function(){
        if(model.general.checkAllInput()){
            utils.getRecaptcha().then(function(token){ //here we check the token
                model.general.current.token_recaptcha = token
                model.general.post()
            })
        }
    },
    _formStates : {// state -1 = initial / state 0 = error / state 1 = valid
        email : {state : -1},
        phone_number : {state : -1},
        syntaxe : {state : -1},
        address : {state : -1},
        postal_code : {state : -1},
        city : {state : -1}
    },
    checkAllInput : function(){
        var form = document.getElementsByClassName('contact-form')[0]

        /*model.general.check.lastName(form.getElementsByClassName('input_lastname')[0].value)
        model.general.check.firstName(form.getElementsByClassName('input_firstname')[0].value)
        model.general.check.object(form.getElementsByClassName('input_object')[0].value)
        model.general.check.email(form.getElementsByClassName('input_email')[0].value)
        model.general.check.message(form.getElementsByClassName('input_message')[0].value)*/

        return !model.general.registerHaveError()
        

    },
    registerHaveError(){
        var errorUccured = true

        if(model.general._formStates.email.state === 1
        && model.general._formStates.phone_number.state === 1
        && model.general._formStates.syntaxe.state === 1
        && model.general._formStates.address.state === 1)
        {
            errorUccured = false
        }

        return errorUccured
    },
    getCurrentCode : function (){
        return this._currentCode
    },
    setCurrentCode : function (dc){
        this._currentCode = dc
    },
    getStates : function (){
        return model.general._formStates
    },
    setFormStates : function (newFormStates){
        model.general._formStates = newFormStates
    },
    post : function(){
        return new Promise(function(resolve, reject){
            if(model.general.checkAllInput()){
                utils.getRecaptcha().then(function(token){ //here we check the token
                    console.log(token)
                    model.general.current.token_recaptcha = token
                    resolve()
                })
            }else{
                reject()
            }
        })
    },
    getInputErrorMessage : function(stringEnum){
        var message = model.general.getInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getInputStatesIndicator : function(stringEnum){
        var state = model.general.getInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getInputStates : function(stringEnum){
        var o;
        switch (stringEnum) {
            case 'syntaxe':
                o = model.general._formStates.syntaxe
            break;
            case 'phone_number':
                o = model.general._formStates.phone_number
            break;
            case 'email':
                o = model.general._formStates.email
            break;
            case 'address':
                o = model.general._formStates.address
            break;
            case 'city':
                o = model.general._formStates.city
            break;
            case 'postal_code':
                o = model.general._formStates.postal_code
            break;
            default:
                o = null
        }
        return o
    },
    check : {
        email : function(e){
            if(e != user.current.personne.email){
                var State = model.general.getStates()
                if(utils.validateEmail(e)){
                    State.email.state = 0
                    State.email.message = i18n.cur.error_email_not_valid
                }else{
                    State.email.message = null
                    // TODO : send new email to backend
                    model.general.current.email = e
                    State.email.state = -1
                }
                model.general.setFormStates(State)
            }
        },
        syntaxe : function(e){
            var State = model.general.getStates()
            if(utils.validateLengthCarac(e, 3, 300)){// min-mac carac
                State.syntaxe.state = 0
                State.syntaxe.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "3", MaxCharac : "300"})
            }else{
                State.syntaxe.message = null
                model.general.current.syntaxe = e
                State.syntaxe.state = 1
            }
            model.general.setFormStates(State)
        },
        selectNumberLang : function(e){
            return new Promise(function(resolve, reject){
                user.update(auth.getJWT(),{country_code_phone_number : e})
                .then(function(){
                    eventHelper.updateWidgetNotif.set(true)
                    user.current.country_code_phone_number = e
                })
            })
        },
        phone_number : function(e){
            if(e != user.current.phone_number){
                var states = model.general.getStates()
                var stringNumber = '';
                var code = document.getElementsByClassName('select-your-country-phone')[0].value
                if(code === '33'){// we just remove the common error to add the '0' after the country code for french user. 
                    stringNumber = ('+' + code + (e.charAt(0) === '0' ? e.slice(1,e.length) : e)).replace(/\s/g,'') //remove the white space
                }else{
                    stringNumber = '+' + code + e
                }
                //var re = /%^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$%i/
            
                if(utils.validateLengthCarac(e, 5, 20)){// small verif to check if the user have a minimum wrote a good tel-number
                    states.phone_number.state = 0
                    states.phone_number.message = i18n.cur.error_phone_number
                }else{
                    user.update(auth.getJWT(),{phone_number : e})
                    .then(function(){
                        states.phone_number.state = -1
                        states.phone_number.message = null
                        eventHelper.updateWidgetNotif.set(true)
                    })
                    .catch(function(err){
                        console.log(err.response.error)
                        states.phone_number.state = 0
                        states.phone_number.message = err.response.error
                    })
                }
                model.general.setFormStates(states)
            }
        },
        address : function(e){
            //if(e != user.current.address.line1){
                var states = model.general.getStates()
                user.update(auth.getJWT(),{line1 : e})
                .then(function(){
                    states.address.state = -1
                    states.address.message = null
                    eventHelper.updateWidgetNotif.set(true)
                })
                .catch(function(err){
                    console.log(err)
                    states.address.state = 0
                    states.address.message = err.response.error
                })
                model.general.setFormStates(states)
            //}
        },
        postal_code : function(e){
            //if(e != user.current.address.line1){
                var states = model.general.getStates()
                user.update(auth.getJWT(),{postal_code : e})
                .then(function(){
                    states.postal_code.state = -1
                    states.postal_code.message = null
                    eventHelper.updateWidgetNotif.set(true)
                })
                .catch(function(err){
                    console.log(err)
                    states.postal_code.state = 0
                    states.postal_code.message = err.response.error
                })
                model.general.setFormStates(states)
            //}
        },
        city : function(e){
            //if(e != user.current.address.line1){
                var states = model.general.getStates()
                user.update(auth.getJWT(),{city : e})
                .then(function(){
                    states.city.state = -1
                    states.city.message = null
                    eventHelper.updateWidgetNotif.set(true)
                })
                .catch(function(err){
                    console.log(err)
                    states.city.state = 0
                    states.city.message = err.response.error
                })
                model.general.setFormStates(states)
            //}
        },
    }
}


// EDIT PASSWORD


model.editPassword = {
    current : {},
    _backendErrorMessages : [],
    getAllBackendError : function(){
        var messages = model.editPassword._backendErrorMessages
        if(messages !== [] && messages !== undefined){
            return m("div", {class: "backend-errors-container"},[
                messages.map(x => 
                    m('span', {class:"error"}, x))
            ])
        }
        return ''
    },
    _formStates : {// state -1 = initial / state 0 = error / state 1 = valid
        password : {state : -1},
        password_current : {state : -1},
        password_confirmation : {state : -1}
    },
    checkAllInput : function(){
        var form = document.getElementsByClassName('edit-password-form')[0]

        model.editPassword.check.password(form.getElementsByClassName('input_password')[0].value)
        model.editPassword.check.password_confirmation(form.getElementsByClassName('input_password_confirmation')[0].value)
        model.editPassword.check.password_current(form.getElementsByClassName('input_password_current')[0].value)

        return !model.editPassword.haveError()
    },
    haveError(){
        var errorUccured = true

        if(model.editPassword._formStates.password.state === 1
        && model.editPassword._formStates.password_current.state === 1
        && model.editPassword._formStates.password_confirmation.state === 1)
        {
            errorUccured = false
        }

        return errorUccured
    },
    getCurrentCode : function (){
        return this._currentCode
    },
    setCurrentCode : function (dc){
        this._currentCode = dc
    },
    getStates : function (){
        return model.editPassword._formStates
    },
    setFormStates : function (newFormStates){
        model.editPassword._formStates = newFormStates
    },
    post : function(){
        //return new Promise(function(resolve, reject){
        if(model.editPassword.checkAllInput()){
            model.editPassword.checkCurrentPassword()
            .then(function(){
                model.editPassword.cleanAllForm()
                eventHelper.updatePassWidgetNotif.set(true)
            }).catch(function(){
                var states = model.editPassword.getStates()
                states.password_current.state = 0
                states.password_current.message = i18n.cur.error_wrong_password
                model.editPassword.setFormStates(states)
            })
        }
        //})
    },
    checkCurrentPassword : function(){
        return new Promise(
            function(resolve, reject){
                utils.getRecaptcha().then(function(token){
                    console.log(user.current)
                    auth.signin({
                        email : user.current.user.email,
                        password : model.editPassword.current.password_current,
                        token_recaptcha : token
                    })
                    .then(function(res){
                        user.update(auth.getJWT(), {
                            old_password : model.editPassword.current.password_current,
                            password : model.editPassword.current.password,
                            password_confirmation : model.editPassword.current.password_confirmation
                        }).then(function(res){
                            resolve(res)
                        }).catch(function(err){
                            reject(err)
                        })
                    }) // good password
                    .catch(() => reject()) // wrong password
                })
            }
        )
    },
    getInputErrorMessage : function(stringEnum){
        var message = model.editPassword.getInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getInputStatesIndicator : function(stringEnum){
        var state = model.editPassword.getInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getInputStates : function(stringEnum){
        var o;
        switch (stringEnum) {
            case 'password':
                o = model.editPassword._formStates.password
            break;
            case 'password_current':
                o = model.editPassword._formStates.password_current
            break;
            case 'password_confirmation':
                o = model.editPassword._formStates.password_confirmation
            break;
            default:
                o = null
        }
        return o
    },
    cleanAllForm: function(e){
        var form = document.getElementsByClassName("edit-password-form")[0].firstChild
        form.reset();
        var states = model.editPassword.getStates()
        states.password_current.state = -1
        states.password_current.message = null
        states.password.state = -1
        states.password.message = null
        states.password_confirmation.state = -1
        states.password_confirmation.message = null
        model.editPassword.current = {}
        model.editPassword.setFormStates(states)
    },
    check : {
        password_current : function(e){
            var states = model.editPassword.getStates()
            var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/
            if(utils.validateLengthCarac(e, 6, 60)){
                states.password_current.state = 0
                states.password_current.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "6", MaxCharac : "60"})
            }else{//no errors
                model.editPassword.current.password_current = e
                states.password_current.state = 1
                states.password_current.message = null
            }
            
            model.editPassword.setFormStates(states)
        },
        password : function(e){
            var states = model.editPassword.getStates()
            var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/
            if(utils.validateLengthCarac(e, 6, 60)){
                states.password.state = 0
                states.password.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "6", MaxCharac : "60"})
            }else if(!regex.test(String(e))){
                states.password.state = 0
                states.password.message = i18n.cur.error_format_pwd
            }else{//no errors
                model.editPassword.current.password = e
                states.password.state = 1
                states.password.message = null
            }
            var txtInputPwdConf = document.getElementsByClassName('input_password_confirmation')[0].value
            if(txtInputPwdConf !== ''){//required to check if the user has changed the password when the password_confirmation's already wrote
                model.editPassword.check.password_confirmation(txtInputPwdConf)
            }
            
            model.editPassword.setFormStates(states)
        },
        password_confirmation : function(e){
            var states = model.editPassword.getStates()
            if(e !== model.editPassword.current.password){
                states.password_confirmation.state = 0
                states.password_confirmation.message = i18n.cur.error_not_same_pwd
            }else{
                states.password_confirmation.state = 1
                states.password_confirmation.message = null
                model.editPassword.current.password_confirmation = e
            }
            model.editPassword.setFormStates(states)
        },
    }
}

module.exports = model




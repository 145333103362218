var m = require('mithril')
var global = require('../Global')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')
var user = require('../models/User')
var recordAudio = require('../helpers/AudioRecorder')
var i18n = require('../models/TranslationModel')
var auth = require('../services/Auth')

var Recorder = {
    _audioLink : undefined,
    _recordInProgress : false,
    _popupRemovePronunciation : false,
    audioRecorder : undefined,
    oninit : function(){
        var audioLink = user.current.personne.prononciation
        if(audioLink != undefined && audioLink != ""){
            Recorder.setAudioLink(audioLink)
        }
    },
    oncreate : function(){
        //console.log(RecorderJS)
    },
    getAudioTag : function(){
        if(Recorder.getAudioLink() !== undefined){
            return m("span", {class:"audio-tag"}, [
                m("audio[controls]", {id:"audio-tag-wav", class:"basic-scale"} ,[
                    m("source", {
                        id:"src-audio-wav",
                        type: "audio/wav",
                        src: Recorder.getAudioLink()
                    })
                ])
            ])
        }else{
            return ""
        }
    },
    getInfoUserRecorder : function(){
        if(this.getRecordInProgress()){
            return m('span',{id:"recording-text-indication"}, i18n.cur.home_myinfo_recorder_stop)
        }else{
            return m('span',{id:"recording-text-indication"}, i18n.cur.home_myinfo_recorder_start)
        }
    },
    getRecorder : function(){
        var that = this
        return m("div", {class:"item-section-option basic-scale"},[
            m("span", {class:"recording-left"},[
                m("span", {
                    class:"rounded-ico-button", 
                    id:"button-recording-name",
                    onclick : function(e){
                        that.toggleRecording(e)
                    }
                },[
                    m('i', {class:"fas fa-microphone"})
                ]),
            ]),
            m("span", {class:"text-container"},[
                this.getInfoUserRecorder()
            ])
        ])
    },
    getTrashSection(){
        var that = this
        if(Recorder.getAudioLink()){
            return m("div", {class:"item-section-option basic-scale trash"},[
                m("span", {class:"recording-left"},[
                    m("span", {
                        class:"rounded-ico-button", 
                        id:"button-trash-recording-name",
                        onclick : function(e){
                            eventHelper.removePrononciationPopup.set(true)
                        }
                    },[
                        m('i', {class:"fas fa-trash"})
                    ]),
                ]),
                m("span", {class:"text-container"},[
                    m("span", i18n.cur.home_myinfo_recorder_remove)
                ])
            ])
        }else{
            return ''
        }
    },
    trashRecording : function(){
        eventHelper.removePrononciationPopup.set(false)
        Recorder.setAudioLink(undefined)
        // somthing like user.removePronunciation()
    },
    toggleRecording : function(e){
        if(this.getRecordInProgress() === false){
            this.startRecording()

        }else{
            this.stopRecording()
        }
    },
    startRecording : function(){
        let that = this
        recordAudio.start().then(function(){
            setTimeout(() => that.stopRecording(), 10000) // automatic stop recording after 10 secondes
            that.animateIcoRecordingInfo(true)
            that.setRecordInProgress(true)
            m.redraw()
        }).catch(function(err){
            console.log(err)
        })
    },
    stopRecording : function(){
        if(this.getRecordInProgress()){ // prevent settimeout set in startRecording()
            var that = this
            var textSpan = document.getElementById("recording-text-indication")
            textSpan.innerHTML = i18n.cur.home_myinfo_recorder_load
            recordAudio.stop().then(function(data){
                that.animateIcoRecordingInfo(false)
                that.setRecordInProgress(false)
                Recorder.sendToBackend(data.file)
                Recorder.setAudioLink(data.link)
                that.refreshAudioTag()
            })
        }
    },
    sendToBackend : function(mp3File){
        var formData = new FormData()
        formData.append('file', mp3File)

        m.request({
            method: "POST",
            url: "api/requests/personne/prononciation",
            body: formData,
            serialize: function(value) {return value},
            headers : {
                Authorization: "Bearer " + auth.getJWT(),
                'X-CSRF-TOKEN': utils.getCsrf()
            },
            config: function(xhr) {
                xhr.upload.addEventListener("progress", function(e) {
                    var progress = e.loaded / e.total
                    console.log(progress)
                })
            }
        }).then(function(res) {
            //console.log(res)
        }).catch(function(err) {
            console.log(err)
        })
    },
    refreshAudioTag : function(){
        m.redraw.sync()
        var audioEl = document.getElementById("audio-tag-wav")
        audioEl.pause()
        audioEl.load()
    },
    animateIcoRecordingInfo(x){
        var element = document.getElementById("button-recording-name")
        var ico = element.getElementsByTagName("i")[0]
        if(x){
            element.classList.add("recording-flashes")
            ico.classList.add("basic-exit-scale")
            utils.waitBeforeElementUpdate(ico).then(function(){
                ico.classList.remove("basic-exit-scale")
                ico.classList.remove("fa-microphone")
                ico.classList.add("fa-circle")
            })
            this.setRecordInProgress(true)

        }else{
            element.classList.remove("recording-flashes")
            ico.classList.add("basic-exit-scale")
            utils.waitBeforeElementUpdate(ico).then(function(){
                ico.classList.remove("basic-exit-scale")
                ico.classList.add("fa-microphone")
                ico.classList.remove("fa-circle")
            })
            this.setRecordInProgress(false)
        }
    },
    onbeforeremove : function(vnode){
        vnode.dom.classList.add('exit-widget-loader')
        return utils.waitBeforeElementUpdate(vnode.dom)
        
    },
    view: function() {
        return [m('div', {class:"input-section"},[
                m('div', {class:"title home"},[
                    m('span', i18n.cur.home_myinfo_pronunciation)
                ]),
                m("div", {class:"recorder-container"},[
                    m("div", {class:"record-section"}, [
                        Recorder.getAudioTag(),
                        this.getRecorder(),
                        // FOR P2
                        //this.getTrashSection(),
                    ]),
                ]),
            ]),
            m('script', {src:"/assets/prolyrix/js/lib/WebAudioRecorder.min.js"})
        ]
    },

    // setter, getter

    setAudioLink : function(x){// set this to undefined to hidden the audio section
        user.current.personne.prononciation = x
        Recorder._audioLink = x
    },
    getAudioLink : function(){
        return Recorder._audioLink
    },
    setRecordInProgress : function(x){
        this._recordInProgress = x
    },
    getRecordInProgress : function(){
        return this._recordInProgress
    },
}

module.exports = Recorder

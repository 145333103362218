var Polyfill = {
    check : function(){
        if(navigator.userAgent.indexOf('MSIE') !==-1 || navigator.appVersion.indexOf('Trident/') > -1){
            return "IE"
        }else{
            return false
        }
    }
}

module.exports = Polyfill
var m = require('mithril')
var user = require('../models/User')
var Global = require('../Global')
var router = require('../services/Router')
var utils = require('../helpers/Utils')

var Youtube = {
    getThumbnail(videoId){
        var url = "https://www.googleapis.com/youtube/v3/videos?id=" + videoId + "&key=" + Global.api.youtube + "&part=snippet,contentDetails,statistics,status"
        return new Promise(function(resolve, reject){
            m.request({
                method: "GET",
                url: url,
            })
            .then(function(data) {
                if(data.pageInfo.totalResults > 0){
                    resolve(data.items[0].snippet.thumbnails.medium)
                }else{
                    reject()
                }
            })
        })
    },
    getVideoIdByUrl(url){
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
        var match = url.match(regExp)
        if (match && match[2].length == 11) {
            return match[2]
        }else{
            return false
        }
    },
    getDuration(videoId){
        var url = "https://www.googleapis.com/youtube/v3/videos?id=" + videoId + "&key=" + Global.api.youtube + "&part=snippet,contentDetails,statistics,status"
        return new Promise(function(resolve, reject){
            m.request({
                method: "GET",
                url: url,
            })
            .then(function(data) {
                if(data.pageInfo.totalResults > 0){
                    var d = data.items[0].contentDetails.duration
                    var duration = utils.parseDuration(d)
                    resolve(duration)
                }else{
                    reject()
                }
            })
        })
    },
    getTitle(videoId){
        var url = "https://www.googleapis.com/youtube/v3/videos?id=" + videoId + "&key=" + Global.api.youtube + "&part=snippet,contentDetails,statistics,status"
        return new Promise(function(resolve, reject){
            m.request({
                method: "GET",
                url: url,
            })
            .then(function(data) {
                if(data.pageInfo.totalResults > 0){
                    resolve(data.items[0].snippet.title)
                }else{
                    reject()
                }
            })
        })
    },
    getDesc(videoId){
        var url = "https://www.googleapis.com/youtube/v3/videos?id=" + videoId + "&key=" + Global.api.youtube + "&part=snippet,contentDetails,statistics,status"
        return new Promise(function(resolve, reject){
            m.request({
                method: "GET",
                url: url,
            })
            .then(function(data) {
                if(data.pageInfo.totalResults > 0){
                    resolve(data.items[0].snippet.description)
                }else{
                    reject()
                }
            })
        })
    }
}


module.exports = Youtube
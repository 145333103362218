var m = require('mithril')
var JsonLang = require('../models/LocalData')
var model = require('../models/Login')
var utils = require('../helpers/Utils')
var infoDiv = require('../components/InfoDiv')
var i18n = require('../models/TranslationModel')

let Login = {
    _signupIsShow : true,
    loginIsLoading : false,
    getSelectPhone: function(){
        return m('span', {class: 'select-input-container'} ,[
            m('label', {for:"select-lang-1", class: "hidden-label"}, "Phone number"),
            m('select', {id:"select-lang-1", name : 'select-lang-1', class : 'select-your-country-phone',
            onchange : function(e){
                model.setCurrentCode(e.target.value)
            }},[
                JsonLang.countries.map(function(x){
                    if(model.getCurrentCode() === x.code){
                        return m('option', {value : x.code, selected : 'selected'}, x.country)
                    }else{
                        return m('option', {value : x.code}, x.country)
                    }
                })
            ]),
            m('i', {class:'fas fa-caret-down'})
        ])
    },
    getSignupIsShow:function(){
        return Login._signupIsShow
    },
    toggleActiveSignNav : function(target){
        model.cleanAllForm()
        if(Login.getSignupIsShow() === false){

            target.classList.add('active')
            document.getElementById('switch-sign-in').classList.remove('active')
        }else{
            target.classList.add('active')
            document.getElementById('switch-sign-up').classList.remove('active')
        }
    },
    showSignUp : function(target){
        if(Login._signupIsShow === false){
            Login.toggleActiveSignNav(target)
            Login._signupIsShow = true
        }
    },
    showSignIn : function(target){
        if(Login._signupIsShow === true){
            Login.toggleActiveSignNav(target)
            Login._signupIsShow = false
        }
    },
    getPasswordVerif: function(){
        if(!model.getQuickOlyrixConnect()){
            return m('div', {class:"input-section"},[
                m('div', {class:"title"},[
                    m('label',{for: "input_register_password_confirmation"}, i18n.cur.login_r_in_vpwd)
                ]),
                m('div', {class: 'input-item'},[
                    m('span', {class: 'left-ico'},[
                        m('i', {class: 'fas fa-key'})
                    ]),
                    m('input', {
                        type : 'password',
                        placeholder : '****************',
                        autocomplete : 'new-password',
                        id : 'input_register_password_confirmation',
                        class : 'input_password_confirmation',
                        name : 'input_register_password_confirmation',
                        onfocusout: function(e) {
                            model.check.password_confirmation(e.target.value)
                        }
                    }),
                    model.getSignupInputStatesIndicator('password_confirmation')
                ]),
                model.getSignupInputErrorMessage('password_confirmation')
            ])
        }
    },
    getFirstName : function(){
        if(!model.getQuickOlyrixConnect()){
            return m('div', {class:"input-section"},[
                m('div', {class:"title"},[
                    m('label',{for: "firstname-1"}, i18n.cur.login_r_in_firstname)
                ]),
                m('div', {class: 'input-item'},[
                    m('span', {class: 'left-ico'},[
                        m('i', {class: 'fas fa-user'})
                    ]),
                    m('input', {
                        type : 'text',
                        placeholder : 'Claude',
                        autocomplete: 'given-name',
                        class : 'input_firstname',
                        name : 'firstname-1',
                        id: 'firstname-1',
                        onfocusout: function(e) {
                            model.check.firstName(e.target.value)
                        }
                    }),
                    model.getSignupInputStatesIndicator('firstName')
                ]),
                model.getSignupInputErrorMessage('firstName')
            ])
        }
    },
    getLastName(){
        if(!model.getQuickOlyrixConnect()){
            return m('div', {class:"input-section"},[
                m('div', {class:"title"},[
                    m('label',{for: "lastname-1"}, i18n.cur.login_r_in_lastname)
                ]),
                m('div', {class: 'input-item'},[
                    m('span', {class: 'left-ico'},[
                        m('i', {class: 'fas fa-user'})
                    ]),
                    m('input', {
                        type : 'text',
                        placeholder : 'Debussy',
                        autocomplete : 'family-name',
                        class : 'input_lastname',
                        name : 'lastname-1',
                        id: 'lastname-1',
                        onfocusout: function(e) {
                            model.check.lastName(e.target.value)
                        }
                    }),
                    model.getSignupInputStatesIndicator('lastName')
                ]),
                model.getSignupInputErrorMessage('lastName')
            ])
        }
    },
    getForgotPassword : function(){
        // FOR P2
        if(model.getQuickOlyrixConnect()){
            return m(m.route.Link, {class:'lost-password', href:"/lost-password"}, [
                m('span', i18n.cur.login_l_lost_pwd)
            ])
        }
    },
    getCenteredTitle : function(){
        if(!model.getQuickOlyrixConnect()){
            return m("h3", {class: "center-title"}, [
                m("span", i18n.cur.login_r_title),
                m("span", {class:"text-grey"}, " PRÔlyrix "),
                /*m("span", "en tant "),
                m("span", {class:"bold"},"qu'artiste"),*/
            ])
        }else{
            return m("h3", {class: "center-title"}, [
                m("span", m.trust(i18n.cur.login_with_olyrix_title.formatUnicorn({
                    prolyrix:"<span class='text-grey'>PRÔlyrix</span>", 
                    olyrix: "<span class='bold'>Ôlyrix</span>"})
                )),
            ])
        }
    },
    getSignupOrSignin : function(){
        var that = this
        if(Login.getSignupIsShow()){
            return [m
                ("div", {class: "box-shadow widget"},[ //inscription
                    Login.getCenteredTitle(),
                    m("div", {class:"signup-form"},[
                        m('form', { onsubmit: model.signupPost, name:"signupForm"},[

                            // EMAIL

                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('label',{for: "email-1"}, i18n.cur.login_r_in_email)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-at'})
                                    ]),
                                    m('input', {
                                        type : 'text',
                                        placeholder : 'claude.debussy@gmail.com',
                                        autocomplete : 'email',
                                        class : 'input_email',
                                        id : 'email-1',
                                        name : 'email-1',
                                        onfocusout: function(e) {
                                            model.check.email(e.target.value).then(function(res){
                                                model.setQuickOlyrixConnect(res.email)
                                                if(res.email){
                                                    var parent = e.target.parentElement.parentElement.parentElement // get the form
                                                    parent.getElementsByClassName("input-phone")[0].select()
                                                }
                                            })
                                        }
                                    }),
                                    model.getSignupInputStatesIndicator('email')
                                ]),
                                model.getSignupInputErrorMessage('email')
                            ]),

                            // LASTNAME

                            Login.getLastName(),

                            // FIRSTNAME

                            Login.getFirstName(),

                            // PHONE 

                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('label', {for:"select-lang-3"}, i18n.cur.login_r_in_phone)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-phone-alt'})
                                    ]),
                                    Login.getSelectPhone(),
                                    m('input', {
                                        type : 'tel',
                                        placeholder : '623232323',
                                        autocomplete : 'tel',
                                        class : 'input-phone',
                                        name : 'select-lang-3',
                                        id : 'select-lang-3',
                                        onfocusout: function(e) {
                                            model.check.phone_number(e.target.value)
                                        }
                                    }),
                                    model.getSignupInputStatesIndicator('phone_number')
                                ]),
                                model.getSignupInputErrorMessage('phone_number')
                            ]),

                            // PASSWORD

                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('label',{ for:"password-1"}, model.getQuickOlyrixConnect() ? i18n.cur.login_r_in_pwd + " Olyrix" : i18n.cur.login_r_in_pwd)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-key'})
                                    ]),
                                    m('input', {
                                        type : 'password',
                                        placeholder : '****************',
                                        autocomplete : 'new-password',
                                        class : 'input_password',
                                        name : 'password-1',
                                        id:'password-1',
                                        onfocusout: function(e) {
                                            model.check.password(e.target.value)
                                        }
                                    }),
                                    model.getSignupInputStatesIndicator('password')
                                ]),
                                model.getSignupInputErrorMessage('password')
                            ]),

                            Login.getForgotPassword(),

                            // PASSWORD_VERIF

                            Login.getPasswordVerif(),

                            // CHECKBOX CGU

                            m('div', {class:"input-section"},[
                                m('div', {class:'cgu-checkbox-container'},[
                                    m('label', {for:'checkbox_cgu', class: 'checkbox-container'}, [
                                        m('input', {
                                            type: 'checkbox',
                                            name:'checkbox_cgu',
                                            class : 'input_checkbox_cgu',
                                            onchange : function(e){
                                                model.check.checkbox(e.target.checked)
                                            }
                                        }),
                                        m('span', {
                                            class : 'checkmark',
                                        }),
                                        m('span', {class: 'text-checkbox'},[
                                            m('span', i18n.cur.login_r_in_cgu),
                                            m('span', {class: 'small'}, ' ' + i18n.cur.login_r_required),
                                        ]),
                                        model.getSignupInputErrorMessage('checkbox')
                                    ]),
                                ]),
                            ]),

                            model.getQuickOlyrixConnect() ? m("div", {class:"infoDiv-container additional-data"}, [
                                m(infoDiv, {text : i18n.cur.login_help_olyrix})
                            ]) : '',

                            // SUBMIT BUTTON

                            m('div', {class:"input-section clearfix submit-section"}, [
                                m('div', {class:"submit-button right"},[
                                    m('input', {
                                        type: 'submit',
                                        value: i18n.cur.login_r_submit,
                                        onclick: function(e){
                                            e.preventDefault()
                                            model.submitSignup(e).then().catch(err => {
                                                that.setErrorOnSignupWithOlyrixFailed(err)
                                            })
                                        }
                                    })
                                ])
                            ])
                        ])
                    ]),
                    model.getBackend()
                ]),
                m("div", {class: "box-shadow widget m-d-none"},[//connexion
                    m("h3", {class: "center-title"}, [
                        m("span", m.trust(i18n.cur.login_l_title.formatUnicorn({prolyrix : "<span class='text-grey'>PRÔlyrix</span>"}))),
                    ]),
                    m("div", {class:"signin-form"},[
                        m('form', { onsubmit: model.signinPost},[

                            // EMAIL

                            m('div', {class:"input-section", name:"signinForm"},[
                                m('div', {class:"title"},[
                                    m('label',{for:"email-2"}, i18n.cur.login_r_in_email)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-at'})
                                    ]),
                                    m('input', {
                                        type : 'text',
                                        placeholder : 'claude.debussy@gmail.com',
                                        autocomplete : 'email',
                                        class : 'input_email',
                                        name : 'email-2',
                                        id : 'email-2',
                                        onfocusout: function(e) {
                                            model.check.emailSignin(e.target.value)
                                        }
                                    }),
                                    model.getSigninInputStatesIndicator('email')
                                ]),
                                model.getSigninInputErrorMessage('email')
                            ]),

                            // PASSWORD

                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('label',{for:"password-2"}, i18n.cur.login_l_in_pwd)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-key'})
                                    ]),
                                    m('input', {
                                        type : 'password',
                                        placeholder : '****************',
                                        autocomplete : 'new-password',
                                        class : 'input_password',
                                        name : 'password-2',
                                        id : 'password-2',
                                        onfocusout: function(e) {
                                            model.check.passwordSignin(e.target.value)
                                        }
                                    }),
                                    model.getSigninInputStatesIndicator('password')
                                ]),
                                model.getSigninInputErrorMessage('password')
                            ]),

                            // SUBMIT BUTTON

                            m('div', {class:"input-section submit-section"}, [
                                // FOR P2
                                m(m.route.Link, {class:'lost-password', href:"/lost-password"}, [
                                    m('span', i18n.cur.login_l_lost_pwd)
                                ]),
                                m('div', {class:"submit-button right"},[
                                    m('input', {
                                        type: 'submit',
                                        value: i18n.cur.login_signin,
                                        onclick: function(e){
                                            e.preventDefault()
                                            that.setLoginIsLoading(true)
                                            model.submitSignin(e).then(function(res){
                                                //m.route.set("/account/home")
                                                //that.setLoginIsLoading(false) // We want to continu to show the loader on /account/home loading
                                            }).catch(function(err){
                                                that.setErrorOnLoginFailed(err, 0) // 0 to say desktop error
                                            })
                                        }
                                    })
                                ])
                            ]),
                            
                            this.getLoginIsLoading() ? this.getLoaderOnLoading() : '',
                            // Password forms should have (optionally hidden) username fields for accessibility: (More info: https://goo.gl/9p2vKq)
                            m('input', {
                                type : 'text',
                                placeholder : '',
                                autocomplete : 'username',
                                class : 'input_username important-hide',
                                name : 'username',
                            })
                        ])
                    ])
                ])]
        }else{
            return [
                m("div", {class: "box-shadow widget m-d-none"},[ //inscription
                    Login.getCenteredTitle(),
                    m("div", {class:"signup-form"},[
                        m('form', { onsubmit: model.signupPost, name:"signupForm"},[
    
                            // EMAIL
    
                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('label',{for: "email-3"}, i18n.cur.login_r_in_email)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-at'})
                                    ]),
                                    m('input', {
                                        type : 'text',
                                        placeholder : 'claude.debussy@gmail.com',
                                        autocomplete : 'email',
                                        class : 'input_email',
                                        name : 'email-3',
                                        id:'email-3',
                                        onfocusout: function(e) {
                                            model.check.email(e.target.value).then(function(res){
                                                model.setQuickOlyrixConnect(res.email)
                                                if(res.email){
                                                    var parent = e.target.parentElement.parentElement.parentElement // get the form
                                                    parent.getElementsByClassName("input-phone")[0].select()
                                                }
                                            })
                                        }
                                    }),
                                    model.getSignupInputStatesIndicator('email')
                                ]),
                                model.getSignupInputErrorMessage('email')
                            ]),
    
                            // LASTNAME
    
                            Login.getLastName(),
    
                            // FIRSTNAME
    
                            Login.getFirstName(),
    
                            // PHONE 
    
                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('label', {for:"select-lang-1"}, i18n.cur.login_r_in_phone)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-phone-alt'})
                                    ]),
                                    Login.getSelectPhone(),
                                    m('input', {
                                        type : 'tel',
                                        placeholder : '623232323',
                                        autocomplete : 'tel',
                                        class : 'input-phone',
                                        name : 'select-lang-1',
                                        onfocusout: function(e) {
                                            model.check.phone_number(e.target.value)
                                        }
                                    }),
                                    model.getSignupInputStatesIndicator('phone_number')
                                ]),
                                model.getSignupInputErrorMessage('phone_number')
                            ]),
    
                            // PASSWORD
    
                            m('div', {class:"input-section"},[
                                m('div', {class:"title"},[
                                    m('label',{for: "password"}, model.getQuickOlyrixConnect() ? i18n.cur.login_r_in_pwd + " Olyrix" : i18n.cur.login_r_in_pwd)
                                ]),
                                m('div', {class: 'input-item'},[
                                    m('span', {class: 'left-ico'},[
                                        m('i', {class: 'fas fa-key'})
                                    ]),
                                    m('input', {
                                        type : 'password',
                                        placeholder : '****************',
                                        autocomplete : 'new-password',
                                        class : 'input_password',
                                        name : 'password',
                                        onfocusout: function(e) {
                                            model.check.password(e.target.value)
                                        }
                                    }),
                                    model.getSignupInputStatesIndicator('password')
                                ]),
                                model.getSignupInputErrorMessage('password')
                            ]),
    
                            Login.getForgotPassword(),
    
                            // PASSWORD_VERIF
    
                            Login.getPasswordVerif(),
    
                            // CHECKBOX CGU
    
                            m('div', {class:"input-section"},[
                                m('div', {class:'cgu-checkbox-container'},[
                                    m('label', {for:'checkbox_cgu', class: 'checkbox-container'}, [
                                        m('input', {
                                            type: 'checkbox',
                                            name:'checkbox_cgu',
                                            class : 'input_checkbox_cgu',
                                            onchange : function(e){
                                                model.check.checkbox(e.target.checked)
                                            }
                                        }),
                                        m('span', {
                                            class : 'checkmark',
                                        }),
                                        m('span', {class: 'text-checkbox'},[
                                            m('span', i18n.cur.login_r_in_cgu),
                                            m('span', {class: 'small'}, ' ' + i18n.cur.login_r_required),
                                        ]),
                                        model.getSignupInputErrorMessage('checkbox')
                                    ]),
                                ]),
                            ]),

                            model.getQuickOlyrixConnect() ? m("div", {class:"infoDiv-container"}, [
                                m(infoDiv, {text : i18n.cur.login_help_olyrix})
                            ]) : '',
    
                            // SUBMIT BUTTON
    
                            m('div', {class:"input-section clearfix"}, [
                                m('div', {class:"submit-button right"},[
                                    m('input', {
                                        type: 'submit',
                                        value: i18n.cur.login_r_submit,
                                        onclick: function(e){
                                            e.preventDefault()
                                            model.submitSignup(e).then().catch(err => {
                                                that.setErrorOnSignupWithOlyrixFailed(err)
                                            })
                                        }
                                    })
                                ])
                            ])
                        ])
                    ]),
                    model.getBackend()
                ]),
                m("div", {class: "box-shadow widget"},[//connexion
                    m("h3", {class: "center-title"}, [
                        m("span", m.trust(i18n.cur.login_l_title.formatUnicorn({prolyrix : "<span class='text-grey'>PRÔlyrix</span>"}))),
                    ]),
                m("div", {class:"signin-form"},[
                    m('form', { onsubmit: model.signinPost},[

                        // EMAIL

                        m('div', {class:"input-section", name:"signinForm"},[
                            m('div', {class:"title"},[
                                m('label',{for: "email-4"}, i18n.cur.login_l_in_email)
                            ]),
                            m('div', {class: 'input-item'},[
                                m('span', {class: 'left-ico'},[
                                    m('i', {class: 'fas fa-at'})
                                ]),
                                m('input', {
                                    type : 'text',
                                    placeholder : 'claude.debussy@gmail.com',
                                    autocomplete : 'email',
                                    class : 'input_email',
                                    name : 'email-4',
                                    id : 'email-4',
                                    onfocusout: function(e) {
                                        model.check.emailSignin(e.target.value)
                                    }
                                }),
                                model.getSigninInputStatesIndicator('email')
                            ]),
                            model.getSigninInputErrorMessage('email')
                        ]),

                        // PASSWORD

                        m('div', {class:"input-section"},[
                            m('div', {class:"title"},[
                                m('label',{for: "password"}, i18n.cur.login_l_in_pwd)
                            ]),
                            m('div', {class: 'input-item'},[
                                m('span', {class: 'left-ico'},[
                                    m('i', {class: 'fas fa-key'})
                                ]),
                                m('input', {
                                    type : 'password',
                                    placeholder : '****************',
                                    autocomplete : 'new-password',
                                    class : 'input_password',
                                    name : 'password',
                                    onfocusout: function(e) {
                                        model.check.passwordSignin(e.target.value)
                                    }
                                }),
                                model.getSigninInputStatesIndicator('password')
                            ]),
                            model.getSigninInputErrorMessage('password')
                        ]),

                        // SUBMIT BUTTON

                        m('div', {class:"input-section submit-section"}, [
                            // FOR P2
                            m(m.route.Link, {class:"lost-password", href:"/lost-password"},[
                                m('span', i18n.cur.login_l_lost_pwd)
                            ]),
                            m('div', {class:"submit-button right"},[
                                m('input', {
                                    type: 'submit',
                                    value: i18n.cur.login_signin,
                                    onclick: function(e){
                                        e.preventDefault()
                                        that.setLoginIsLoading(true)
                                        model.submitSignin(e).then(function(res){
                                            //m.route.set("/account/home")
                                        }).catch(function(err){
                                            that.setErrorOnLoginFailed(err, 1) // 1 to say mobile error
                                        })
                                    }
                                })
                            ])
                        ]),

                        this.getLoginIsLoading() ? this.getLoaderOnLoading() : '',
                        // Password forms should have (optionally hidden) username fields for accessibility: (More info: https://goo.gl/9p2vKq)
                        m('input', {
                            type : 'text',
                            placeholder : '',
                            autocomplete : 'username',
                            class : 'input_username important-hide',
                            name : 'username',
                        })
                    ])
                ])
                ]),
            ]
        }
    },
    setErrorOnSignupWithOlyrixFailed(err){
        var signupState = model.getSignupStates()
        if(err.error === "invalid_credentials"){
            signupState.password.state = 0
            signupState.password.message = i18n.cur.error_wrong_olyrix_pwd
        }
        model.setFormSignupStates(signupState)
    },
    setErrorOnLoginFailed(err, device){
        // divice -> 0 = desktop, 1 = mobile
        this.setLoginIsLoading(false)
        var signinState = model.getSigninStates()
        if (err.hasOwnProperty('error') && err.error === 'invalid_credentials'){
            // TODO, check why after disconnection we cant set user.current.user.id
            signinState.password.state = 0
            signinState.email.state = 0
            signinState.password.message = i18n.cur.error_wrong_email_or_pwd
        }else if(err.hasOwnProperty('status') && err.status === -1){
            // account doesn't have subscribe to prolyrix
            signinState.password.state = 0
            signinState.email.state = 0
            signinState.password.message = device === 1 ? i18n.cur.error_login_olyrix_on_prolyrix_mobile : i18n.cur.error_login_olyrix_on_prolyrix_desktop
            
        }
        model.setFormSigninStates(signinState)
    },
    getLoginIsLoading : function(){
        return Login.loginIsLoading
    },
    setLoginIsLoading : function(x){
        Login.loginIsLoading = x
    },
    getLoaderOnLoading : function(){
        return m("div", {class:"centered-text"}, [
            m("div", {class:"lds-ellipsis"}, [
                m("div"),
                m("div"),
                m("div"),
                m("div")
            ])
        ])
    },
    onbeforeremove: function(){
        this.setLoginIsLoading(false)
        model.setQuickOlyrixConnect(false)
        Login._signupIsShow = true
        model.cleanAllForm()
    },
    view: function() {
        return [
        m("div", {class:"flex-center-container"}, [
            m("div", {class:"title-page m-d-none"}, [
                m("div", {class:"title-container"}, [
                    m("div", {class:"main-title"}, 
                        m("h1", i18n.cur.login_title + " PRÔlyrix")
                    ),
                    m("div", {class:"second-title"}, 
                        m("h2", i18n.cur.login_sub_title)
                    )
                ])
            ]),
        ]),
        m("div", {id:"auth-view"},[
            m("div", {class: "switch-button box-shadow d-d-none"},[
                m("div", {
                    id: "switch-sign-up", 
                    class: "col-50 active",
                    onclick: function(e){
                        Login.showSignUp(e.target)
                    }
                },[
                    i18n.cur.login_signup
                ]),
                m("div", {
                    id: "switch-sign-in", 
                    class: "col-50",
                    onclick : function(e){
                        Login.showSignIn(e.target)
                    }
                },[
                    i18n.cur.login_signin
                ])
            ]),
            m("div", {class: "content"},[
                Login.getSignupOrSignin()
            ])
        ])]
    }
}

module.exports = Login
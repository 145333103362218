var m = require('mithril')
var Global = require('../Global')
var localData = require('../models/LocalData')
var utils = require('../helpers/Utils')

var User = {
    _isLogged : false,
    showCookieMessage : false,
    current: {
        user : {},
        personne : {},
        artiste : {}
    },
    translation: {},
    isLogged : function(){
        return User._isLogged
    },
    getStatus : function(){
        return User.current.user.status
    },
    getRStatus : function(){
        return User.current.user.redirectStatus
    },
    getRedirectStatus : function(jwt){
        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'GET',
                url: "api/users/status",
                extract : function(xhr) {return {status: xhr.status, body: JSON.parse(xhr.responseText)}},
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " + jwt,
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                if(res.status < 400){
                    User.current.user.redirectStatus = res.body.status
                    resolve(res.body.status)
                }
            })
            .catch(function(err) {
                console.log(err)
                User.setLogged(false)
                reject(err)
            })
        })
    },
    redirectStatus : function(jwt){
        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'GET',
                url: "api/users/status",
                extract : function(xhr) {return {status: xhr.status, body: JSON.parse(xhr.responseText)}},
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " + jwt,
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                if(res.status < 400){
                    User.current.user.redirectStatus = res.body.status
                    User.getEmail(jwt).then(function(email){
                        User.current.user.email = email
                        User.redirectOnRoute(User.current.user.redirectStatus)
                    })
                    resolve()
                }
            })
            .catch(function(err) {
                console.log(err)
                User.setLogged(false)
                reject(err)
            })
        })
    },
    redirectOnRoute(status){
        console.log("redirect with status: " + status)
        switch(status){
            case "0":
                m.route.set('/account/verify')
                break
            case "1":
                m.route.set('/account/pending')
                break
            case "2":
                m.route.set('/account/activation')
                break
        }
    },
    getEmail : function(jwt){
        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'GET',
                url: "api/users/" + User.current.user.id + "/email",
                extract : function(xhr) {return {status: xhr.status, body: JSON.parse(xhr.responseText)}},
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " + jwt,
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                if(res.status < 400){
                    resolve(res.body.message)
                }
            })
            .catch(function(err) {
                console.log(err)
                reject(err)
            })
        })
    },

    setLogged : function(x){
        User._isLogged = x
    },

    load : function(jwt, i18n){
        var body = Object.create(null)
        body.user = {
            'lastname' : {},
            'firstname' : {},
            'email' : {},
            'age' : {},
            'phone' : {},
            'address' : {},
            'fullname' : {},
            'slug' : {},
            'avatar' : {}
        }
        body.personne = {
            'slug' : {},
            'fullname' : {},
            'firstname' : {},
            'lastname' : {},
            'id' : {},
            'biography' : {},
            'nationality' : {},
            'birthdate' : {},
            'tessiture' : {},
            'job' : {},
            'age' : {},
            'ageMort' : {},
            'spokenLanguages' : {},
            'photoIdentity' : {},
            'see_date_of_birth' : {},
            'prononciation' : {}
        }
        body.artiste = {
            /*'experiences' : {
                locale: "fr"
            },*/
            'photos': {
                size : 'small'
            },
            'videos': {
                size : 'HomepageVideo'
            }
        }
        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'POST',
                url: Global.api.user.show + String(User.current.user.id),
                extract : function(xhr) {return {status: xhr.status, body: JSON.parse(xhr.responseText)}},
                body: body,
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " + jwt,
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                if(res.status <= 400){
                    res = res.body
                    User.current.user.status = -1
                    User.bidingUser(res.user)
                    User.bidingPersonne(res.personne)
                    User.bidingArtiste(res.artiste)


                    User.current.href = Global.url.olyrix + "artistes/" + String(User.current.personne.id) + "/" + User.current.personne.slug
                    let loadPersonne = User.loadPersonne
                    Promise.all([loadPersonne(jwt, i18n)]).then(function(){
                        User.setLogged(true)
                        resolve(res)
                    }).catch(function(err){
                        console.log(err)
                    })
                }else{
                    User.getRedirectStatus(jwt).then(function(){
                        User.getEmail(jwt).then(function(email){
                            User.current.user.email = email
                            var status = User.current.user.redirectStatus
                            User.current.user.status = status
                            User.setLogged(false)
                            User.redirectOnRoute(status)
                            reject(res.body)
                        })
                    })
                    .catch(function(){
                        console.log("error")
                        User.setLogged(false)
                        reject()
                    })
                }
            })
            .catch(function(err) {
                console.log(err)
                User.setLogged(false)
                reject(err)
            })
        })
    },
    loadPersonne : function(jwt, i18n){
        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'GET',
                url: Global.api.user.showPersonne + String(User.current.personne.id) + "/" + User.current.personne.slug,
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " + jwt,
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                delete res.artiste_info.nationality
                delete res.artiste_info.spokenLanguages
                User.loadFakePersonneData()
                User.bidingPersonne(res.artiste_info)
                User.manageBirthdate(res.artiste_info)
                User.manageNation(i18n)
                User.manageSpoken(i18n)
                User.manageRange(i18n).then(function(){
                    resolve(res)
                })
            })
            .catch(function(err) {
                console.log(err)
                reject(err)
            })
        })
    },
    loadPersonnePictures : function(jwt){
        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'GET',
                url: Global.api.user.photo + String(User.current.personne.id) + "/" + User.current.personne.slug + "/photos",
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " + jwt,
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                User.current.personne.photos = res
                resolve(res)
                /*var allPicturePromise = []
                res.map(function(x){
                    allPicturePromise.push(User.loadPersonnePicture(jwt, x.id))
                })
                Promise.all(allPicturePromise).then(function(){
                    resolve(res)
                })*/
            })
            .catch(function(err) {
                reject(err)
            })
        })
    },
    loadPersonnePicture : function(jwt, pictureId){
        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'GET',
                url: Global.api.user.photo + String(User.current.personne.id) + "/" + User.current.personne.slug + "/photos/" + pictureId,
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " + jwt,
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                console.log(res)
                resolve(res)
            })
            .catch(function(err) {
                reject(err)
            })
        })
    },
    manageBirthdate : function(data){
        if(data.birthdate !== null && data.birthdate.includes("-")){
            User.current.personne.birthdate = data.birthdate
        }
    },
    manageNation: function(i18n){
        i18n.getFRListTranslation("lcountry_").then(countryInFr => { // LOAD ALL FR NATION, cause we send to backend the FR translation only
            User.translation.nationFR = countryInFr

            User.nation.loadAll(i18n).then(r => {
                var userNation = User.current.personne.nationality.split(',')
                userNation.map(nation => {
                    countryInFr.map(cur => {
                        if(nation.toLowerCase().trim() == cur.value.toLowerCase().trim()){
                            var index = r.findIndex(i => i.id === String(cur.id))
                            User.current.personne.nation.push({id: r[index].id, value : r[index].value})
                        }
                    })
                })
            })
        })
    },
    manageSpoken: function(i18n){
        i18n.getFRListTranslation("lspoken_").then(spokenInFR => { // LOAD ALL FR NATION, cause we send to backend the FR translation only
            User.translation.spokenFR = spokenInFR

            User.language.loadAll(i18n).then(r => {
                var spokenLanguages = User.current.personne.spokenLanguages.split(',')
                spokenLanguages.map(spoken => {
                    spokenInFR.map(cur => {
                        if(spoken.toLowerCase().trim() == cur.value.toLowerCase().trim()){
                            var index = r.findIndex(i => i.id === String(cur.id))
                            User.current.personne.language.push({id: r[index].id, value : r[index].value})
                        }
                    })
                })
            })
        })
    },
    manageRange: function(i18n){
        return new Promise(function(resolve, reject) {
            User.range.loadAll(i18n).then(r => {
                if(User.current.personne.range === null){
                    User.current.personne.range = []
                    resolve()
                }else{
                    var userRange = User.current.personne.range
                    r.map(cur => {
                        if(userRange == 0){
                            User.current.personne.range = []
                        }
                        if(userRange == cur.id){
                            User.current.personne.range = []
                            userRange = {id: cur.id, value : cur.value}
                            User.current.personne.range.push({id: cur.id, value : cur.value})
                        }
                    })
                    resolve()
                }
            })
        })
    },
    loadFakePersonneData : function(){
        //User.current.personne.pronunciationName = "https://file-examples.com/wp-content/uploads/2017/11/file_example_WAV_1MG.wav"
        //User.current.personne.hideMyBirthdate = false
        User.current.personne.nation = [

        ]
        User.current.personne.language = [

        ]
        
    },
    unselectAllPictures(){
        User.current.personne.pictures.map(function(item, i){
            User.current.personne.pictures[i].selected = false
        })
    },
    removeAccount : function(jwt){
        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'DELETE',
                url: Global.api.user.show + String(User.current.user.id),
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " + jwt,
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                resolve(res)
            })
            .catch(function(err) {
                reject(err)
            })
        })
    },
    logOut : function(jwt){
        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'POST',
                url: Global.api.user.disconnect,
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " + jwt,
                    'X-CSRF-TOKEN': utils.getCsrf()
                }
            })
            .then(function(res) {
                User.setLogged(false)
                resolve(res)
            })
            .catch(function(err) {
                reject(err)
            })
        })
    },
    unset : function(){
        User.current = {
            user : {},
            personne : {},
            artiste : {}
        }
    },
    update : function(jwt, data){
        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'PUT',
                url: Global.api.user.update + String(User.current.user.id),
                body: data,
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " + jwt,
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                resolve(res)
            })
            .catch(function(err) {
                reject(err)
            })
        })
    },
    bidingCurrent : function(data){
        User.current = {...User.current, ...data}
    },
    bidingPersonne : function(data){
        User.current.personne = {...User.current.personne, ...data}
    },
    bidingUser : function(data){
        User.current.user = {...User.current.user, ...data}
    },
    loadAndBindLyrico : function(jwt){
        var body = {}
        body.artiste = {
            'experiences' : {
                locale: "fr"
            }
        }
        m.request({
            method: 'POST',
            url: Global.api.user.show + String(User.current.user.id),
            extract : function(xhr) {return {status: xhr.status, body: JSON.parse(xhr.responseText)}},
            body: body,
            headers : {
                'Content-Type' : 'application/json', 
                Authorization: "Bearer " + jwt,
                'X-CSRF-TOKEN': utils.getCsrf()
            },
        })
        .then(function(res) {
            if(res.status <= 400){
                res = res.body
                User.current.artiste.experiences = res.artiste.experiences
                var bidingId = 0
                Object.entries(User.current.artiste.experiences).map(function(item){
                    var i = 0
                    Object.entries(item[1]).map(function(prod){
                        User.current.artiste.experiences[item[0]][i].isLoaded = false
                        User.current.artiste.experiences[item[0]][i].bidingId = bidingId
                        bidingId++
                        i++
                    })
                })
            }
        })
        .catch(function(err) {
            console.log(err)
        })
    },
    bidingArtiste : function(data){
        User.current.artiste = {...User.current.artiste, ...data}
        
        var allPictures = User.current.artiste.photos
        var newCleanArray = []
        var allVideos = User.current.artiste.videos
        var newVideoArray = []
        var bidingId = 0
        /*Object.entries(User.current.artiste.experiences).map(function(item){
            var i = 0
            Object.entries(item[1]).map(function(prod){
                User.current.artiste.experiences[item[0]][i].isLoaded = false
                User.current.artiste.experiences[item[0]][i].bidingId = bidingId
                bidingId++
                i++
            })
        })*/
        User.current.artiste.experiences = []
        Object.entries(allPictures).map(function(item){
            // PHOTO URL MANAGE
            var photoItem = item[1]
            photoItem.src = photoItem.url.replace('ProlyrixPagination','PhotoPage')
            // PHOTO ID MANAGE
            photoItem.id = item[0]
            // PHOTO SELECTED MANAGE
            photoItem.selected = false
            //PHOTO PERSONNE TAGS MANAGE
            var tagPersonneArray = Object.entries(photoItem.personne)
            var allPersonneTag = []
            if(tagPersonneArray[0]!= null){
                tagPersonneArray.map(function(item2){
                    allPersonneTag.push({id :item2[0], value: item2[1]})
                })
            }
            photoItem.personne = allPersonneTag
            //PHOTO OEUVRE TAGS MANAGE
            var tagOeuvreArray = Object.entries(photoItem.oeuvre)
            var allOeuvreTag = []
            if(tagOeuvreArray[0]!= null){
                tagOeuvreArray.map(function(item2){
                    allOeuvreTag.push({id :item2[0], value: item2[1]})
                })
            }
            photoItem.oeuvre = allOeuvreTag
            //PHOTO LIEU TAGS MANAGE
            var tagLieuArray = Object.entries(photoItem.lieu)
            var allLieuTag = []
            if(tagLieuArray[0]!= null){
                tagLieuArray.map(function(item2){
                    allLieuTag.push({id :item2[0], value: item2[1]})
                })
            }
            photoItem.lieu = allLieuTag
            //PHOTO PRODUCTION TAGS MANAGE
            var tagProductionArray = Object.entries(photoItem.production)
            var allProductionTag = []
            if(tagProductionArray[0]!= null){
                tagProductionArray.map(function(item2){
                    allProductionTag.push({id :item2[0], value: item2[1]})
                })
            }
            photoItem.production = allProductionTag
            newCleanArray.push(photoItem)
        })

        // VIDEO MANAGE

        Object.entries(allVideos).map(function(item){
            // PHOTO URL MANAGE
            var videoItem = item[1]
            videoItem.src = videoItem.photo
            // PHOTO ID MANAGE
            videoItem.id = item[0]
            // PHOTO SELECTED MANAGE
            videoItem.selected = false
            //PHOTO PERSONNE TAGS MANAGE
            var tagPersonneArray = Object.entries(videoItem.personne)
            var allPersonneTag = []
            if(tagPersonneArray[0]!= null){
                tagPersonneArray.map(function(item2){
                    allPersonneTag.push({id :item2[0], value: item2[1]})
                })
            }
            videoItem.personne = allPersonneTag
            //PHOTO OEUVRE TAGS MANAGE
            var tagOeuvreArray = Object.entries(videoItem.oeuvre)
            var allOeuvreTag = []
            if(tagOeuvreArray[0]!= null){
                tagOeuvreArray.map(function(item2){
                    allOeuvreTag.push({id :item2[0], value: item2[1]})
                })
            }
            videoItem.oeuvre = allOeuvreTag
            //PHOTO LIEU TAGS MANAGE
            var tagLieuArray = Object.entries(videoItem.lieu)
            var allLieuTag = []
            if(tagLieuArray[0]!= null){
                tagLieuArray.map(function(item2){
                    allLieuTag.push({id :item2[0], value: item2[1]})
                })
            }
            videoItem.lieu = allLieuTag
            //PHOTO PRODUCTION TAGS MANAGE
            var tagProductionArray = Object.entries(videoItem.production)
            var allProductionTag = []
            if(tagProductionArray[0]!= null){
                tagProductionArray.map(function(item2){
                    allProductionTag.push({id :item2[0], value: item2[1]})
                })
            }
            videoItem.production = allProductionTag
            newVideoArray.push(videoItem)

        })
        User.current.artiste.videos = newVideoArray
        User.current.artiste.photos = newCleanArray
    },
    getPictureSelected : function(){
        var picture = []
        User.current.artiste.photos.map(function(x){
            if(x.selected === true){
                picture = x
            }
        })
        return picture
    },
    getVideoSelected : function(){
        var video = []
        User.current.artiste.videos.map(function(x){
            if(x.selected === true){
                video = x
            }
        })
        return video
    },
    toggleDateViewable : function(jwt){
        var data = {}

        data.see_date_of_birth = User.current.personne.see_date_of_birth ? "0" : "1"

        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'POST',
                body : data,
                url: "api/personnes/" + String(User.current.personne.id),
                //body: data,
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " + jwt,
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                resolve(res)
            })
            .catch(function(err) {
                reject(err)
            })
        })
    },
    updatePersonne : function(jwt){
        // prepare all data we need to update
        var data = {}

        // NATION PART

        var nationArray = Array()
        User.current.personne.nation.map(x => {
            var i = User.translation.nationFR.findIndex(i => i.id === String(x.id))
            // nationFR -> list FR
            nationArray.push(User.translation.nationFR[i].value)
        })
        data.nationality = nationArray

        // SPOKEN LANGUAGE PART

        var spokenArray = Array()
        User.current.personne.language.map(x => {
            // spokenFR -> list FR
            var i = User.translation.spokenFR.findIndex(i => i.id === String(x.id))
            spokenArray.push(User.translation.spokenFR[i].value)
        })
        //console.log(User.translation.spokenFR)
        data.spoken_languages = spokenArray

        // birth_date PART

        if(User.current.personne.birthdate != undefined){
            data.birth_date = User.current.personne.birthdate
        }

        // RANGE (tessiture) PART
        if(User.current.personne.range.length > 0){
            data.range = User.current.personne.range[0].id
        }
        // SEND REQUEST
        return new Promise(
            function(resolve, reject) {
            m.request({
                method: 'POST',
                body : data,
                url: "api/personnes/" + String(User.current.personne.id),
                //body: data,
                headers : {
                    'Content-Type' : 'application/json', 
                    Authorization: "Bearer " + jwt,
                    'X-CSRF-TOKEN': utils.getCsrf()
                },
            })
            .then(function(res) {
                resolve(res)
            })
            .catch(function(err) {
                reject(err)
            })
        })
    },
    loadCookieMessage : function(){
        if(localStorage.hasOwnProperty('cookieMessage')){
            User.showCookieMessage = false
        }else{
            User.showCookieMessage = true
        }
    },
    setCookieMessage : function(){
        localStorage.setItem("cookieMessage", "accepted")
        User.loadCookieMessage()
    }
}

User.nation = {
    data : [],
    setData : function(data){
        console.log(data)
    },
    removeById : function(id, jwt){
        var that = this
        return new Promise(function(resolve, reject){
            var i = that.getAll().findIndex(i => i.id === id)
            i > -1 ? User.current.personne.nation.splice(i, 1) : null

            User.updatePersonne(jwt)
            .then(resolve())
            .catch(x => {
                console.log(x)
                reject(x)
            })
        })
    },
    setAll : function(x){
        User.current.personne.nation = x
    },
    getAll : function(){
        return User.current.personne.nation
    },
    getById : function(id){
        var i = this.getAll().findIndex(i => i.id === id)
        return this.getAll()[i]
    },
    add : function(select, jwt){
        return new Promise(function(resolve, reject){
            var duplicated = false
            User.current.personne.nation.map(x => {
                if(x.value == select.value){
                    duplicated = true
                }
            })
            if(!duplicated){
                User.current.personne.nation.push(select)
                User.updatePersonne(jwt)
                .then(resolve())
                .catch(x => {
                    console.log(x)
                    reject(x)
                })
            }
        })
    },
    research : function(x, getList){
        return new Promise(function(resolve, reject){
            if(User.nation.data.length !== 0){
                let allNation = User.nation.data
                var matchNation = []
                if(x === undefined || x === ''){
                    matchNation = allNation
                }else{
                    allNation.map(function(item){
                        if(item.value.toLowerCase().startsWith(x.toLowerCase())){
                            matchNation.push(item)
                        }
                    })
                }
                resolve(matchNation)
            }else{
                getList('lcountry_').then(x => {
                    User.nation.data = x
                    resolve(User.nation.data)
                })
            }
        })
    },
    loadAll : function(i18n){
        return new Promise(function(resolve, reject){ 
            i18n.getList('lcountry_').then(x => {
                User.nation.data = x
                resolve(User.nation.data)
            })
        })
    }
}
User.language = {
    data: [],
    removeById : function(id, jwt){
        var that = this
        return new Promise(function(resolve, reject){
            var i = that.getAll().findIndex(i => i.id === id)
            i > -1 ? User.current.personne.language.splice(i, 1) : null

            User.updatePersonne(jwt)
            .then(resolve())
            .catch(x => {
                console.log(x)
                reject(x)
            })
        })
    },
    setAll : function(x){
        User.current.personne.language = x
    },
    getAll : function(){
        return User.current.personne.language
    },
    add : function(select, jwt){
        return new Promise(function(resolve, reject){
            var duplicated = false
            User.current.personne.language.map(x => {
                if(x.value == select.value){
                    duplicated = true
                }
            })
            if(!duplicated){
                User.current.personne.language.push(select)
                User.updatePersonne(jwt)
                .then(resolve())
                .catch(x => {
                    console.log(x)
                    reject(x)
                })
            }
        })
    },
    getById : function(id){
        var i = this.getAll().findIndex(i => i.id === id)
        return this.getAll()[i]
    },
    research : function(x, getList){
        return new Promise(function(resolve, reject){
            if(User.language.data.length !== 0){
                let all = User.language.data
                var match = []
                if(x === undefined || x === ''){
                    match = all
                }else{
                    all.map(function(item){
                        if(item.value.toLowerCase().startsWith(x.toLowerCase())){
                            match.push(item)
                        }
                    })
                }
                resolve(match)
            }else{
                getList('lspoken_').then(x => {
                    User.language.data = x
                    resolve(User.language.data)
                })
            }
        })
    },
    loadAll : function(i18n){
        return new Promise(function(resolve, reject){
            i18n.getList('lspoken_').then(x => {
                User.language.data = x
                resolve(User.language.data)
            })
        })
    }
}
User.range = {
    data : [],
    remove : function(jwt){
        return new Promise(function(resolve){
            User.update(jwt, {range:''}).then(function(){
                User.current.personne.range = []
                resolve()
            })
        })
    },
    get : function(){
        return User.current.personne.range
    },
    set : function(select, jwt){
        User.current.personne.range = [select]
        return new Promise(function(resolve){
            User.updatePersonne(jwt).then(function(){
                resolve()
            })
        })
    },
    research : function(x, getList){
        return new Promise(function(resolve, reject){
            if(User.range.data.length !== 0){
                let allRange = User.range.data
                var matchRange = []
                if(x === undefined || x === ''){
                    matchRange = allRange
                }else{
                    allRange.map(function(item){
                        if(item.value.toLowerCase().startsWith(x.toLowerCase())){
                            matchRange.push(item)
                        }
                    })
                }
                resolve(matchRange)
            }else{
                getList('lrange_').then(x => {
                    User.range.data = x
                    resolve(User.range.data)
                })
            }
        })
    },
    loadAll : function(i18n){
        return new Promise(function(resolve, reject){ 
            i18n.getList('lrange_').then(x => {
                User.range.data = x
                resolve(User.range.data)
            })
        })
    },
    loadAll : function(i18n){
        return new Promise(function(resolve, reject){ 
            i18n.getList('lrange_').then(x => {
                User.range.data = x
                resolve(User.range.data)
            })
        })
    }
}

User.tag = {
    picture:{
        sync : function(photoId, data, jwt){
            return new Promise(
                function(resolve, reject) {
                m.request({
                    method: 'POST',
                    url: Global.api.request.photoTag.formatUnicorn({id: photoId}),
                    body: data,
                    headers : {
                        'Content-Type' : 'application/json',
                        Authorization: "Bearer " +  jwt,
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                })
                .then(function(res) {
                    resolve(res)
                })
                .catch(function(err) {
                    reject(err)
                })
            }) 
        },
        saveSelected(jwt){
            let pictureIndex = User.current.artiste.photos.indexOf(User.getPictureSelected())
            let picture = User.current.artiste.photos[pictureIndex]
            var data = Object.create(null)

            var arrayLieuId = Array()
            var arrayProductionId = Array()
            var arrayPersonneId = Array()
            var arrayOeuvreId = Array()

            picture.lieu.map(x => arrayLieuId.push(parseInt(x.id)))
            picture.production.map(x => arrayProductionId.push(parseInt(x.id)))
            picture.personne.map(x => arrayPersonneId.push(parseInt(x.id)))
            picture.oeuvre.map(x => arrayOeuvreId.push(parseInt(x.id)))

            data.lieux = arrayLieuId
            data.productions = arrayProductionId
            data.personnes = arrayPersonneId
            data.oeuvres = arrayOeuvreId
            console.log("saveLater picture")

            User.tag.picture.sync(picture.id, data, jwt).then(() => console.log("Picture tags sended to backend ✔️"))
        }
    },
    video:{
        sync : function(videoId, data, jwt){
            return new Promise(
                function(resolve, reject) {
                m.request({
                    method: 'POST',
                    url: Global.api.request.videoTag.formatUnicorn({id: videoId}),
                    body: data,
                    headers : {
                        'Content-Type' : 'application/json',
                        Authorization: "Bearer " +  jwt,
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                })
                .then(function(res) {
                    resolve(res)
                })
                .catch(function(err) {
                    reject(err)
                })
            }) 
        },
        saveSelected(jwt){
            let videoIndex = User.current.artiste.videos.indexOf(User.getVideoSelected())
            let video = User.current.artiste.videos[videoIndex]
            var data = Object.create(null)

            var arrayLieuId = Array()
            var arrayProductionId = Array()
            var arrayPersonneId = Array()
            var arrayOeuvreId = Array()

            video.lieu.map(x => arrayLieuId.push(parseInt(x.id)))
            video.production.map(x => arrayProductionId.push(parseInt(x.id)))
            video.personne.map(x => arrayPersonneId.push(parseInt(x.id)))
            video.oeuvre.map(x => arrayOeuvreId.push(parseInt(x.id)))

            data.lieux = arrayLieuId
            data.productions = arrayProductionId
            data.personnes = arrayPersonneId
            data.oeuvres = arrayOeuvreId

            User.tag.video.sync(video.id, data, jwt).then(() => console.log("Video tags sended to backend ✔️"))
        }
    }
}

//*******       PICTURES TAGS        *******//

User.tag.picture.lieu = {
    get : function(){
        var picture = User.getPictureSelected()
        return picture.lieu
    },
    add : function(itemToAdd, jwt, autoSave = true){
        return new Promise(function(resolve, reject){
            var pictureIndex = User.current.artiste.photos.indexOf(User.getPictureSelected())
            //
            // if index = -1 there seems to be no duplicate 
            //
            // Important to check with == and not === cause the id's sometime save in string
            //
            if(User.current.artiste.photos[pictureIndex].lieu.findIndex(x => x.id == itemToAdd.id) === -1){
                User.current.artiste.photos[pictureIndex].lieu.push(itemToAdd) // we add the lieu at the array
                if(autoSave === true){
                    var arrayId = Array()
                    var data = Object.create(null)
                    User.current.artiste.photos[pictureIndex].lieu.map(x => arrayId.push(parseInt(x.id)))
                    data.lieux = arrayId
                    User.tag.picture.sync(User.current.artiste.photos[pictureIndex].id, data, jwt)
                    .then(function(){
                        resolve()
                    })
                    .catch(function(err){
                        console.log(err)
                        reject(err)
                    })
                }else{
                    resolve()
                }
            }
        })
    },
    remove : function(id, jwt){
        return new Promise(function(resolve, reject){
            var picture = User.getPictureSelected()
            var pictureIndex = User.current.artiste.photos.indexOf(picture)
            picture.lieu.map(function(item,i){
                if(item.id === id){
                    User.current.artiste.photos[pictureIndex].lieu.splice(i, 1)
                    var arrayId = Array()
                    var data = Object.create(null)
                    User.current.artiste.photos[pictureIndex].lieu.map(x => arrayId.push(parseInt(x.id)))
                    data.lieux = arrayId
                    User.tag.picture.sync(User.current.artiste.photos[pictureIndex].id, data, jwt)
                    .then(function(){
                        resolve()
                    })
                    .catch(function(err){
                        console.log(err)
                        reject(err)
                    })
                }
            })
        })
    }
}
User.tag.picture.production = {
    get : function(){
        var picture = User.getPictureSelected()
        return picture.production
    },
    add : function(itemToAdd, jwt, autoSave = true){
        return new Promise(function(resolve, reject){
            var pictureIndex = User.current.artiste.photos.indexOf(User.getPictureSelected())
            if(User.current.artiste.photos[pictureIndex].production.findIndex(x => x.id == itemToAdd.id) === -1){ // if index = -1 there seems to be no duplicate
                User.current.artiste.photos[pictureIndex].production.push(itemToAdd)
                if(autoSave === true){
                    var arrayId = Array()
                    var data = Object.create(null)
                    User.current.artiste.photos[pictureIndex].production.map(x => arrayId.push(parseInt(x.id)))
                    data.productions = arrayId
                    User.tag.picture.sync(User.current.artiste.photos[pictureIndex].id, data, jwt)
                    .then(function(){
                        resolve()
                    })
                    .catch(function(err){
                        console.log(err)
                        reject(err)
                    })
                }else{
                    resolve()
                }
            }
        })
    }
}
User.tag.picture.oeuvre = {
    get : function(){
        var picture = User.getPictureSelected()
        return picture.oeuvre
    },
    remove : function(id, jwt){
        return new Promise(function(resolve, reject){
            var picture = User.getPictureSelected()
            var pictureIndex = User.current.artiste.photos.indexOf(picture)
            User.current.artiste.photos[pictureIndex].oeuvre.splice(
                picture.oeuvre.findIndex(item => item.id === id)
            , 1)

            var arrayId = Array()
            var data = Object.create(null)
            User.current.artiste.photos[pictureIndex].oeuvre.map(x => arrayId.push(parseInt(x.id)))
            data.oeuvres = arrayId
            User.tag.picture.sync(User.current.artiste.photos[pictureIndex].id, data, jwt)
            .then(function(){
                resolve()
            })
            .catch(function(err){
                console.log(err)
                reject(err)
            })
        })
    },
    add : function(itemToAdd, jwt, autoSave = true){
        return new Promise(function(resolve){
            var pictureIndex = User.current.artiste.photos.indexOf(User.getPictureSelected())
            if(User.current.artiste.photos[pictureIndex].oeuvre.findIndex(x => x.id == itemToAdd.id) === -1){ // if index = -1 there seems to be no duplicate
                User.current.artiste.photos[pictureIndex].oeuvre.push(itemToAdd)
                if(autoSave === true){
                    var arrayId = Array()
                    var data = Object.create(null)
                    User.current.artiste.photos[pictureIndex].oeuvre.map(x => arrayId.push(parseInt(x.id)))
                    data.oeuvres = arrayId
                    User.tag.picture.sync(User.current.artiste.photos[pictureIndex].id, data, jwt)
                    .then(function(){
                        resolve()
                    })
                    .catch(function(err){
                        console.log(err)
                        reject(err)
                    })
                }else{
                    resolve()
                }
            }
        })
    }
}
User.tag.picture.personne = {
    get : function(){
        var picture = User.getPictureSelected()
        return picture.personne
    },
    add : function(itemToAdd, jwt, autoSave = true){
        return new Promise(function(resolve, reject){
            var pictureIndex = User.current.artiste.photos.indexOf(User.getPictureSelected())
            if(User.current.artiste.photos[pictureIndex].personne.findIndex(x => x.id == itemToAdd.id) === -1){ // if index = -1 there seems to be no duplicate
                User.current.artiste.photos[pictureIndex].personne.push(itemToAdd)
                if(autoSave === true){
                    var arrayId = Array()
                    var data = Object.create(null)
                    User.current.artiste.photos[pictureIndex].personne.map(x => arrayId.push(parseInt(x.id)))
                    data.personnes = arrayId
                    User.tag.picture.sync(User.current.artiste.photos[pictureIndex].id, data, jwt)
                    .then(function(){
                        resolve()
                    })
                    .catch(function(err){
                        console.log(err)
                        reject(err)
                    })
                }else{
                    resolve()
                }
            }
            resolve()
        })
    },
    remove : function(id, jwt){
        return new Promise(function(resolve, reject){
            var picture = User.getPictureSelected()
            var pictureIndex = User.current.artiste.photos.indexOf(picture)
            picture.personne.map(function(item,i){
                if(item.id === id){
                    User.current.artiste.photos[pictureIndex].personne.splice(i, 1)

                    var arrayId = Array()
                    var data = Object.create(null)
                    User.current.artiste.photos[pictureIndex].personne.map(x => arrayId.push(parseInt(x.id)))
                    data.personnes = arrayId
                    User.tag.picture.sync(User.current.artiste.photos[pictureIndex].id, data, jwt)
                    .then(function(){
                        resolve()
                    })
                    .catch(function(err){
                        console.log(err)
                        reject(err)
                    })
                }
            })
            resolve()
        })
    },
}

//*******       VIDEOS TAGS        *******//

User.tag.video.lieu = {
    get : function(){
        var picture = User.getVideoSelected()
        return picture.lieu
    },
    add : function(itemToAdd, jwt, autoSave = true){
        return new Promise(function(resolve){
            var pictureIndex = User.current.artiste.videos.indexOf(User.getVideoSelected())
            //
            // if index = -1 there seems to be no duplicate 
            //
            // Important to check with == and not === cause the id is sometime save in string
            //
            if(User.current.artiste.videos[pictureIndex].lieu.findIndex(x => x.id == itemToAdd.id) === -1){
                User.current.artiste.videos[pictureIndex].lieu.push(itemToAdd)
                if(autoSave === true){
                    var arrayId = Array()
                    var data = Object.create(null)
                    User.current.artiste.videos[pictureIndex].lieu.map(x => arrayId.push(parseInt(x.id)))
                    data.lieux = arrayId
                    User.tag.video.sync(User.current.artiste.videos[pictureIndex].id, data, jwt)
                    .then(function(){
                        resolve()
                    })
                    .catch(function(err){
                        console.log(err)
                        reject(err)
                    })
                }else{
                    resolve()
                }
            }
            resolve()
        })
    },
    remove : function(id, jwt){
        return new Promise(function(resolve){
            var video = User.getVideoSelected()
            var videoIndex = User.current.artiste.videos.indexOf(video)
            video.lieu.map(function(item,i){
                if(item.id === id){
                    User.current.artiste.videos[videoIndex].lieu.splice(i, 1)

                    var arrayId = Array()
                    var data = Object.create(null)
                    User.current.artiste.videos[videoIndex].lieu.map(x => arrayId.push(parseInt(x.id)))
                    data.lieux = arrayId
                    User.tag.video.sync(User.current.artiste.videos[videoIndex].id, data, jwt)
                    .then(function(){
                        resolve()
                    })
                    .catch(function(err){
                        console.log(err)
                        reject(err)
                    })
                }
            })
            resolve()
        })
    },
}
User.tag.video.production = {
    add : function(itemToAdd, jwt, autoSave = true){
        return new Promise(function(resolve){
            var pictureIndex = User.current.artiste.videos.indexOf(User.getVideoSelected())
            //
            // if index = -1 there seems to be no duplicate 
            //
            // Important to check with == and not === cause the id's sometime save in string
            //
            if(User.current.artiste.videos[pictureIndex].production.findIndex(x => x.id == itemToAdd.id) === -1){
                User.current.artiste.videos[pictureIndex].production.push(itemToAdd)
                if(autoSave === true){
                    var arrayId = Array()
                    var data = Object.create(null)
                    User.current.artiste.videos[pictureIndex].production.map(x => arrayId.push(parseInt(x.id)))
                    data.productions = arrayId
                    User.tag.video.sync(User.current.artiste.videos[pictureIndex].id, data, jwt)
                    .then(function(){
                        resolve()
                    })
                    .catch(function(err){
                        console.log(err)
                        reject(err)
                    })
                }else{
                    resolve()
                }
            }
            resolve()
        })
    },
    get : function(){
        var picture = User.getVideoSelected()
        return picture.production
    },
}
User.tag.video.oeuvre = {
    get : function(){
        var picture = User.getVideoSelected()
        return picture.oeuvre
    },
    add : function(itemToAdd, jwt, autoSave = true){
        return new Promise(function(resolve){
            var pictureIndex = User.current.artiste.videos.indexOf(User.getVideoSelected())
            //
            // if index = -1 there seems to be no duplicate 
            //
            // Important to check with == and not === cause the id's sometime save in string
            //
            if(User.current.artiste.videos[pictureIndex].oeuvre.findIndex(x => x.id == itemToAdd.id) === -1){
                User.current.artiste.videos[pictureIndex].oeuvre.push(itemToAdd)
                if(autoSave === true){
                    var arrayId = Array()
                    var data = Object.create(null)
                    User.current.artiste.videos[pictureIndex].oeuvre.map(x => arrayId.push(parseInt(x.id)))
                    data.oeuvres = arrayId
                    User.tag.video.sync(User.current.artiste.videos[pictureIndex].id, data, jwt)
                    .then(function(){
                        resolve()
                    })
                    .catch(function(err){
                        console.log(err)
                        reject(err)
                    })
                }else{
                    resolve()
                }
            }
        })
    },
    remove : function(id, jwt){
        return new Promise(function(resolve){
            var video = User.getVideoSelected()
            var videoIndex = User.current.artiste.videos.indexOf(video)
            video.oeuvre.map(function(item,i){
                if(item.id === id){
                    User.current.artiste.videos[videoIndex].oeuvre.splice(i, 1)

                    var arrayId = Array()
                    var data = Object.create(null)
                    User.current.artiste.videos[videoIndex].oeuvre.map(x => arrayId.push(parseInt(x.id)))
                    data.oeuvres = arrayId
                    User.tag.video.sync(User.current.artiste.videos[videoIndex].id, data, jwt)
                    .then(function(){
                        resolve()
                    })
                    .catch(function(err){
                        console.log(err)
                        reject(err)
                    })
                }
            })
            resolve()
        })
    },
}
User.tag.video.personne = {
    get : function(){
        var video = User.getVideoSelected()
        return video.personne
    },
    add : function(itemToAdd, jwt, autoSave = true){
        return new Promise(function(resolve){
            var pictureIndex = User.current.artiste.videos.indexOf(User.getVideoSelected())
            //
            // if index = -1 there seems to be no duplicate 
            //
            // Important to check with == and not === cause the id's sometime save in string
            //
            if(User.current.artiste.videos[pictureIndex].personne.findIndex(x => x.id == itemToAdd.id) === -1){
                User.current.artiste.videos[pictureIndex].personne.push(itemToAdd)
                if(autoSave === true){
                    var arrayId = Array()
                    var data = Object.create(null)
                    User.current.artiste.videos[pictureIndex].personne.map(x => arrayId.push(parseInt(x.id)))
                    data.personnes = arrayId
                    User.tag.video.sync(User.current.artiste.videos[pictureIndex].id, data, jwt)
                    .then(function(){
                        resolve()
                    })
                    .catch(function(err){
                        console.log(err)
                        reject(err)
                    })
                }else{
                    resolve()
                }
            }
            resolve()
        })
    },
    remove : function(id, jwt){
        return new Promise(function(resolve){
            var video = User.getVideoSelected()
            var videoIndex = User.current.artiste.videos.indexOf(video)
            video.personne.map(function(item,i){
                if(item.id === id){
                    User.current.artiste.videos[videoIndex].personne.splice(i, 1)
                    
                    var arrayId = Array()
                    var data = Object.create(null)
                    User.current.artiste.videos[videoIndex].personne.map(x => arrayId.push(parseInt(x.id)))
                    data.personnes = arrayId
                    User.tag.video.sync(User.current.artiste.videos[videoIndex].id, data, jwt)
                    .then(function(){
                        resolve()
                    })
                    .catch(function(err){
                        console.log(err)
                        reject(err)
                    })
                    
                }
            })
            resolve()
        })
    },
}

module.exports = User
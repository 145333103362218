var m = require('mithril')
var utils = require('../helpers/Utils')
var model = require('../models/Setting')
var eventHelper = require('../helpers/EventHelper')
var JsonLang = require('../models/LocalData')
var removeAccountPopup = require('../components/PopupRemoveAccount')
var updateNotif = require('../components/UpdateWidgetNotif')
var user = require('../models/User')
var i18n = require('../models/TranslationModel')
var loader = require('../components/WidgetLoader')

let Setting = {
    oncreate : function(vnode){

        utils.scrollToTop(500, 0) // animation during in ms, timeout in ms

        var emailInput = vnode.dom.getElementsByClassName("input_email")[0]
        emailInput.value = user.current.user.email

        var telInput = vnode.dom.getElementsByClassName("input_phone")[0]
        telInput.value = user.current.user.phone.phone_number

        var selectInput = vnode.dom.getElementsByClassName("select-your-country-phone")[0]
        
        for(var i = 0; i < selectInput.childNodes.length; i++)
        {
            if(selectInput[i].value == user.current.user.phone.country_code_phone_number){
                selectInput[i].setAttribute("selected", "true");
            }
        }
    },
    getRemoveAccountPopup : function(){
        return eventHelper.removeAccountPopup.get() ? m(removeAccountPopup) : ''
    },
    getLoader : function(){
        return eventHelper.widgetLoader.get() ? m(loader) : ''
    },
    toggleLoader : function(){
        eventHelper.widgetLoader.set(!eventHelper.widgetLoader.get())
    },
    getUpdateNotif : function(){
        return eventHelper.updateWidgetNotif.get() ? m(updateNotif,{isAuto : true}) : ''
    },
    activeUpdateNotif : function(){
        eventHelper.updateWidgetNotif.set(true)
    },
    getUpdatePassNotif : function(){
        return eventHelper.updatePassWidgetNotif.get() ? m(updateNotif,{isAuto : true}) : ''
    },
    activeUpdatePassNotif : function(){
        eventHelper.updatePassWidgetNotif.set(true)
    },
    getSelectPhone: function(){
        return m('span', {class: 'select-input-container'} ,[
            m('select', {name : 'selectLang', class : 'select-your-country-phone',
            onchange : function(e){
                model.general.setCurrentCode(e.target.value)
                model.general.check.selectNumberLang(e.target.value)//TODO :display the widgetUpdatePopup if the promise is resolve
            }},[
                JsonLang.countries.map(function(x){
                    return m('option', {value : x.code}, x.country)
                })
            ]),
            m('i', {class:'fas fa-caret-down'})
        ])
    },
    getEditPasswordView : function(){
        return m
        ("div", {class: "box-shadow widget"},[//inscription
            Setting.getUpdatePassNotif(),
            m("h3", {class: "center-title"}, [
                m("span", i18n.cur.setting_w_pwd_title),
            ]),
            m("div", {class:"edit-password-form"},[
                m('form', { onsubmit: model.editPassword.Post, name:"edit-password-form"},[

                    // ACTUAL PASSWORD

                    m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.setting_w_pwd_current)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-key'})
                            ]),
                            m('input', {
                                type : 'password',
                                placeholder : '*********',
                                autocomplete : 'new-password',
                                class : 'input_password_current',
                                name : 'password',
                                onfocusout: function(e) {
                                    model.editPassword.check.password_current(e.target.value)
                                }
                            }),
                            model.editPassword.getInputStatesIndicator('password_current')
                        ]),
                        model.editPassword.getInputErrorMessage('password_current')
                    ]),

                    // NEW PASSWORD

                    m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.setting_w_pwd_new)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-key'})
                            ]),
                            m('input', {
                                type : 'password',
                                placeholder : '**************',
                                autocomplete : 'new-password',
                                class : 'input_password',
                                name : 'password',
                                onfocusout: function(e) {
                                    model.editPassword.check.password(e.target.value)
                                }
                            }),
                            model.editPassword.getInputStatesIndicator('password')
                        ]),
                        model.editPassword.getInputErrorMessage('password')
                    ]),

                    // CONFIRM NEW PASSWORD

                    m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.setting_w_pwd_new_conf)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-key'})
                            ]),
                            m('input', {
                                type : 'password',
                                placeholder : '**************',
                                autocomplete : 'new-password',
                                class : 'input_password_confirmation',
                                name : 'password',
                                onfocusout: function(e) {
                                    model.editPassword.check.password_confirmation(e.target.value)
                                }
                            }),
                            model.editPassword.getInputStatesIndicator('password_confirmation')
                        ]),
                        model.editPassword.getInputErrorMessage('password_confirmation')
                    ]),

                    // SUBMIT BUTTON

                    m('div', {class:"input-section submit-section"}, [
                        m('div', {class:"submit-button right"},[
                            m('input', {
                                id: 'register-input',
                                type: 'submit',
                                value: i18n.cur.setting_w_pwd_submit,
                                onclick: function(e){
                                    e.preventDefault()
                                    model.editPassword.post(e)
                                }
                            })
                        ])
                    ]),

                    // Password forms should have (optionally hidden) username fields for accessibility: (More info: https://goo.gl/9p2vKq)
                    
                ])
            ]),
            model.general.getAllBackendError(),
            Setting.getLoader()
        ])
    },
    getGeneralView : function(){
        return m
        ("div", {class: "box-shadow widget"},[
            Setting.getUpdateNotif(),
            m("h3", {class: "center-title"}, [
                m("span", i18n.cur.setting_general_data),
            ]),
            m("div", {class:"general-form"},[
                m('form', { onsubmit: model.general.Post, name:"general-form"},[

                    // ALT

                    /*m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.setting_w_gi_alt)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-user'})
                            ]),
                            m('input', {
                                type : 'text',
                                placeholder : i18n.cur.setting_w_gi_alt_placeholder,
                                class : 'input_syntaxe',
                                name : 'syntaxe',
                                onfocusout: function(e) {
                                    model.general.save.syntaxe(e.target.value)
                                }
                            }),
                            model.general.getInputStatesIndicator('syntaxe')
                        ]),
                        model.general.getInputErrorMessage('syntaxe')
                    ]),*/

                    // PHONE 

                    m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.setting_w_gi_num)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-phone-alt'})
                            ]),
                            Setting.getSelectPhone(),
                            m('input', {
                                type : 'tel',
                                placeholder : '600000000',
                                autocomplete : 'tel',
                                class : 'input_phone',
                                name : 'phone',
                                onfocusout: function(e) {
                                    model.general.check.phone_number(e.target.value)
                                }
                            }),
                            model.general.getInputStatesIndicator('phone_number')
                        ]),
                        model.general.getInputErrorMessage('phone_number')
                    ]),

                    // EMAIL

                    m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.setting_w_gi_email)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-at'})
                            ]),
                            m('input', {
                                type : 'text',
                                placeholder : 'claude.debussy@gmail.com',
                                autocomplete : 'email',
                                class : 'input_email',
                                name : 'email',
                                onfocusout: function(e) {
                                    model.general.check.email(e.target.value)
                                }
                            }),
                            model.general.getInputStatesIndicator('email')
                        ]),
                        model.general.getInputErrorMessage('email')
                    ]),

                    // ADRESSE 

                    /*m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.setting_w_gi_adress)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-map-marker-alt'})
                            ]),
                            m('input', {
                                type : 'text',
                                placeholder : i18n.cur.setting_w_gi_adress_placeholder,
                                class : 'input_address',
                                name : 'address',
                                onfocusout: function(e) {
                                    model.general.check.address(e.target.value)
                                }
                            }),
                            model.general.getInputStatesIndicator('address')
                        ]),
                        model.general.getInputErrorMessage('address')
                    ]),

                    // POSTAL CODE 

                    m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.setting_w_gi_zip_code)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-map-marker'})
                            ]),
                            m('input', {
                                type : 'text',
                                placeholder : i18n.cur.setting_w_gi_zip_code_placeholder,
                                class : 'input_postal_code',
                                name : 'address',
                                onfocusout: function(e) {
                                    model.general.check.postal_code(e.target.value)
                                }
                            }),
                            model.general.getInputStatesIndicator('postal_code')
                        ]),
                        model.general.getInputErrorMessage('postal_code')
                    ]),

                    // CITY 

                    m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.setting_w_gi_city)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-building'})
                            ]),
                            m('input', {
                                type : 'text',
                                placeholder : i18n.cur.setting_w_gi_city_placeholder,
                                class : 'input_city',
                                name : 'address',
                                onfocusout: function(e) {
                                    model.general.check.city(e.target.value)
                                }
                            }),
                            model.general.getInputStatesIndicator('city')
                        ]),
                        model.general.getInputErrorMessage('city')
                    ]),

                    // COUNTRY 

                    m('div', {class:"input-section"},[
                        m('div', {class:"title"},[
                            m('span', i18n.cur.setting_w_gi_country)
                        ]),
                        m('div', {class: 'input-item'},[
                            m('span', {class: 'left-ico'},[
                                m('i', {class: 'fas fa-flag'})
                            ]),
                            m('input', {
                                type : 'text',
                                placeholder : i18n.cur.setting_w_gi_country_placeholder,
                                class : 'input_country',
                                name : 'country',
                                onfocusout: function(e) {
                                    model.general.check.country(e.target.value)
                                }
                            }),
                        ]),
                    ]),*/


                ])
            ]),
            model.general.getAllBackendError(),
            Setting.getLoader()
        ])
    },
    getAccountManagementView : function(){
        return m
        ("div", {class: "box-shadow widget account-management"},[
            m("h3", {class: "center-title"}, [
                m("span", "Gestion de mon compte"),
            ]),
            m("div", {class:"button-container"}, [
                /*m("button", {class:"button-with-ico brown"}, [
                    m('span', {class: 'left-ico'},[
                        m('i', {class: 'fas fa-download'})
                    ]),
                    m("span", {class:"text-button"}, i18n.cur.setting_w_manage_button_my_data)
                ]),*/
                m("button", {
                    class:"button-with-ico danger",
                    onclick: function(){
                        eventHelper.removeAccountPopup.set(true)
                    }
                }, [
                    m('span', {class: 'left-ico'},[
                        m('i', {class: 'fas fa-user-slash'})
                    ]),
                    m("span", {class:"text-button"}, i18n.cur.setting_w_manage_button_rmv_acc)
                ])
            ]),
            model.general.getAllBackendError(),
            Setting.getLoader()
        ])
    },
    onbeforeremove: function(){
        //model.general.cleanAllForm()
    },
    post : function(){
        Setting.toggleLoader()
        model.general.post().then(function(res){
            console.log("it's ok")
            eventHelper.widgetLoader.setProgress(20)
            // FAKE LOADER
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(40)
            }, 500)
            setTimeout(function(){
                eventHelper.widgetLoader.setProgress(100)
            }, 1100)
            setTimeout(function(){
                Setting.toggleLoader()
            }, 1600)
        }).catch(function(err){
            console.log("it's not ok")
            Setting.toggleLoader()
        })
    },
    view: function() {
        return m("div", {class: "content masonry"},
            [
                m("div", {class:"col-50-d"},[
                    Setting.getGeneralView(),
                    Setting.getEditPasswordView(),
                ]),
                m("div", {class:"col-50-d"},[
                    Setting.getAccountManagementView(),
                ]),
                Setting.getRemoveAccountPopup()
            ])
    }
}

module.exports = Setting
var m = require('mithril')
var utils = require('../helpers/Utils')
var auth = require('../services/Auth')
var updateNotif = require('../components/UpdateWidgetNotif')

let select = {
    _researchItem : [], //fakeSearchItem
    all : [],
    _showInput : false,
    _showUpdateNotif : false,
    researchTimeOut : null,
    remove : () => null,
    oninit: function(vnode){
        select.vnode = vnode
        this.ukey = vnode.attrs.ukey
        this.all = vnode.attrs.all
        this.inputTitle = vnode.attrs.inputTitle
    },
    onupdate: function(e){
        //console.log(e)
    },
    view: function() {
        var that = this
        return m('div', {class:"input-section"},[
            m('div', {class:"title home"},[
                m('span', this.inputTitle)
            ]),
            m('div', {
                class: 'input-item tags-container multi-select-field area-tag',
                onclick : function(e){
                    e.preventDefault()
                },  
            }, [
                this.getAllTagsViews(),
            ]),
        ])
    },
    getAllTagsToAdd : function(){
        let tagContainer = select.vnode.dom.getElementsByClassName("area-tag")[0]
        let tagsCollection = tagContainer.getElementsByClassName("tag")
        var allTags = []
        for (var i = 0; i < tagsCollection.length; i++) {
            if(tagsCollection[i].classList.contains("selected")){
                var tag = {
                    id : tagsCollection[i].getAttribute('dataid'),
                    value : tagsCollection[i].getAttribute('datavalue'),
                    type : tagsCollection[i].getAttribute('dataentity'),
                }
                allTags.push(tag)
            }
        }
        return allTags
    },
    getAllTagsViews : function(){
        var that = this
        var c = 0
        this.all().forEach(function(item){
            //console.log(item, that.inputTitle)
            if(!item.__proto__.__proto__){
                c++;
            }
        })
        if(c > 0){
            return this.all().map(function(item) {
                return m("span", {
                    class: item.selected ? "tag selected" : "tag unselected",
                    dataId: item.id,
                    dataEntity: item.type,
                    datavalue : item.value,
                    title: item.value,
                    key: item.id + item.value,
                    onclick:function(e){
                        var target = undefined
                        if(e.target.classList.contains("tag")){
                            target = e.target
                        }else{
                            target = utils.findAncestor(e.target, "tag")
                        }
                        
                        if (target.classList.contains("selected")){
                            target.classList.remove("selected")
                            target.classList.add("unselected")
                        }else{
                            target.classList.remove("unselected")
                            target.classList.add("selected")
                        }
                    }
                },[
                    m('span', {
                        class: "text-tag-value"
                    }, item.value),
                ])
            })
        }else{
            return m("span", {class:"empty-mutli-select-text-container"}, "")
        }
    },
}


module.exports = select
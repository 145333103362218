var m = require('mithril')
var utils = require('../helpers/Utils')
var eventHelper = require('../helpers/EventHelper')
var auth = require('../services/Auth')
var entity = require('../models/EntityModel')

var model = {
    clearBuilder : function(){
        this.oeuvre.set(null)
        this.production.set(null)
        this.reprise.set(null)
        this.distribution.set(null)
    },
    oeuvre : {
        _current : [],
        set : function(item){
            model.production.set(null)
            if(item === null){
                this._current = []
            }else{
                this._current = [item]
            }
        },
        get : function(){
            return this._current
        },
        isSet : function(){
            return Array.isArray(this.get()) && this.get().length
        }
    },
    production : {
        _current : [],
        set : function(item){
            model.reprise.set(null)
            if(item === null){
                this._current = []
            }else{
                this._current = [item]
            }
        },
        get : function(){
            return this._current
        },
        isSet : function(){
            return Array.isArray(this.get()) && this.get().length
        }
    },
    reprise : {
        _current : [],
        set : function(item){
            model.distribution.set(null)
            if(item === null){
                this._current = []
            }else{
                this._current = [item]
            }
        },
        get : function(){
            return this._current
        },
        isSet : function(){
            return Array.isArray(this.get()) && this.get().length
        }
    },
    distribution : {
        _current : [],
        set : function(item){
            if(item === null){
                this._current = []
            }else{
                this._current = [item]
            }
        },
        get : function(){
            return this._current
        },
        isSet : function(){
            return Array.isArray(this.get()) && this.get().length
        }
    },
    getAllData : function() {
        var allTags = []
        if(this.oeuvre.isSet()){
            var data = model.oeuvre.get()
            var extractData = this.extractTags(data, 'oeuvre')
            extractData.map(function(x){
                allTags.push(x)
            })
        }
        if(this.production.isSet()){
            var data = model.production.get()
            var extractData = this.extractTags(data, 'production')
            extractData.map(function(x){
                allTags.push(x)
            })
        }
        if(this.reprise.isSet()){
            var data = model.reprise.get()
            var extractData = this.extractTags(data, 'reprise')
            extractData.map(function(x){
                allTags.push(x)
            })
        }
        if(this.distribution.isSet()){
            var data = model.distribution.get()
            var extractData = this.extractTags(data, 'distribution')
            extractData.map(function(x){
                !(x.value.includes("<br/>")) ? allTags.push(x) : ''
            })
        }
        return allTags
    },
    extractTags : function(data, type){
        var tags = []
        tags.push({id: data[0].all.additional.entity.id, value: data[0].all.additional.entity.name, type : type, selected : true})

        if(data[0].all.additional.hasOwnProperty('people')){
            data[0].all.additional.people.map(function(people){
                tags.push({id : people.id, value : people.name, type : 'people', selected : true})
            })
        }

        if(data[0].all.additional.hasOwnProperty('lieu')){
            tags.push({id : data[0].all.additional.lieu.id, value : data[0].all.additional.lieu.name, type : 'lieu', selected : true})
        }


        return tags
    },
}

module.exports = model
var m = require('mithril')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')

var PopupConfirm = {
    oninit : function(vnode){
        this.title = vnode.attrs.title
        this.acceptAction = vnode.attrs.acceptAction
        this.exitPopup = vnode.attrs.exitPopup
        this.backText = vnode.attrs.backText
        this.confirmText = vnode.attrs.confirmText
    },
    onbeforeremove : function(){
        /*var bgElement = document.getElementById('nav-background')
        bgElement.classList.add('fadeOut')*/
        var navElement = document.getElementById('plain-popup')
        navElement.classList.add('exit-fade')
        return Promise.all([
            utils.waitBeforeElementUpdate(navElement),
            /*utils.waitBeforeElementUpdate(bgElement)*/
        ])
    },
    view: function(ctrl) {
        var that = this
        return m("div", {id : "plain-popup"}, [
            m("div", {class:"popup-box"}, [
                m("div", {class:"popup-container"}, [
                    m("div", {class:"top-border danger"}),
                    m("div", {class:"header-popup confirm danger-color"},[
                        m("span", {class:"ico-info-container"}, [
                            m('i', {class: 'fas fa-exclamation-triangle'})
                        ]),
                        m("span", {class:"title-popup"}, this.title),
                        m("span", {class:"ico-exit-popup"}, [
                            m('i', {
                                class: 'fas fa-times',
                                onclick : function(){
                                    that.exitPopup()
                                }
                            })
                        ]),
                    ]),
                    m("div", {class:"footer-popup"}, [
                        m("div", {class:"button-container"}, [
                            m("button", {
                                class:"button-with-ico brown",
                                onclick: function(){
                                    that.exitPopup()
                                }
                            }, [
                                m("span", {class:"text-button"}, this.backText)
                            ]),
                            m("button", {
                                class:"button-with-ico danger",
                                onclick: function(){
                                    that.acceptAction()
                                }
                            }, [
                                m("span", {class:"text-button"}, this.confirmText)
                            ])
                        ])
                    ])
                ]),
            ]),
            m(PopupConfirm.Background, {exit : () => this.exitPopup()})
        ])
    }
}

PopupConfirm.Background = {
    oninit : function(vnode){
        this.exit = vnode.attrs.exit
    },
    view : function(ctrl){
        var that = this
        return m("div", {
            id: 'nav-background',
            class: 'fadeIn',
            onclick : function(){
                that.exit()
            }
        })
    }
}

module.exports = PopupConfirm

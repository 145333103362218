var recorder = {
    gumStream : undefined,
    recorder : undefined,
    input : undefined,
    encodeAfterRecord : true,

    AudioContext : window.AudioContext || window.webkitAudioContext,
    audioContext : undefined,

    start : function(){
        var that = this
        return new Promise(function(resolve, reject){

            var constraints = { audio: true, video:false }

            navigator.mediaDevices.getUserMedia(constraints).then(function(stream) {

                that.audioContext = new that.AudioContext();

                //assign to gumStream for later use
                that.gumStream = stream;
                
                /* use the stream */
                that.input = that.audioContext.createMediaStreamSource(stream);
                
                //stop the input from playing back through the speakers
                //input.connect(audioContext.destination)

                that.recorder = new WebAudioRecorder(that.input, {
                workerDir: "assets/prolyrix/js/lib/", // must end with slash
                encoding: "mp3",
                numChannels:2,
                });

                that.recorder.setOptions({
                    timeLimit:12,
                    encodeAfterRecord:that.encodeAfterRecord,
                    mp3: {bitRate: 160}
                });

                //start the recording process
                that.recorder.startRecording();
                resolve()

            }).catch(function(err) {
                reject(err)
            });
        })
        
    },
    stop: function() {
        var that = this
        return new Promise(function(resolve, reject){
        
            //stop microphone access
            that.gumStream.getAudioTracks()[0].stop();
            
            //tell the recorder to finish the recording (stop recording + encode the recorded audio)
            that.recorder.finishRecording();

            
            that.recorder.onComplete = function(recorder, blob) { 
                console.log("Encoding complete")
                var downloadLink = that.getDownloadLink(blob,recorder.encoding)
                var mp3File = new File([blob], "audio.mp3")
                var data = {
                    link : downloadLink,
                    file : mp3File
                }
                resolve(data)
            }

            console.log('Recording stopped');
        })
    },
    getDownloadLink : function(blob) {
        var url = URL.createObjectURL(blob);
        return url
    }
}

module.exports = recorder
var m = require('mithril')
var Global = require('../Global')
var User = require("../models/User")
var i18n = require('../models/TranslationModel')

var Nav = {
    add : function(icoClasses, href, text, blank){
        if(blank !== true){
            blank = false
        }
        return {
            ico : icoClasses,
            href : href,
            text : text,
            blank : blank
        }
    },
    getAll : function(){
        if(User.isLogged() && User.getStatus() === -1){
            return [
                this.add("fas fa-globe-europe", "/account/home", i18n.cur.left_nav_public_data),
                this.add("fas fa-cog", "/account/setting", i18n.cur.left_nav_settings),
                this.add("fas fa-eye", User.current.href, i18n.cur.left_nav_see_my_olyrix, true)
            ]
        }else{
            return [
                this.add("fas fa-user", "/login", i18n.cur.login_l_submit),
            ]
        }
    }
}

module.exports = Nav
var m = require('mithril')
var eventHelper = require('../helpers/EventHelper')
var utils = require('../helpers/Utils')
var user = require('../models/User')
var auth = require('../services/Auth')
var i18n = require('../models/TranslationModel')

var UserNav = {
    onbeforeremove : function(vnode){
        var userNav = vnode.dom.getElementsByClassName("usernav-widget")[0]
        userNav.classList.add("exit-usernav")
        var background = document.getElementById("usernav-background")
        background.classList.add('fadeOut')

        return utils.waitBeforeElementUpdate(vnode.dom)
    },
    close: function() {
        eventHelper.userNav.set(false)
    },
    view : function(){
        return m("div", {class: "right-user-nav"}, [
            m("div", {class: 'usernav-widget box-shadow'} ,[
                m(UserNav.getNav)
            ]),
            m(UserNav.background)
        ])
    }
}
UserNav.getNav = {
    view : function(){
        return m("nav", {class: "user-nav no-select"}, [
            m("ul", [
                m("li", {class:"nav-item"} ,[
                    m(m.route.Link, {
                        class:"link",
                        href:"/account/setting",
                        onclick : function(e){
                            UserNav.close()
                        }
                    },[
                        m("span", {class:"ico-container"}, [
                            m('i', {class:"fas fa-cog"})
                        ]),
                        m("span", {class:"text-nav-container"},[
                            m("span", {class:"text-nav"}, i18n.cur.left_nav_settings)
                        ])
                    ])
                ]),
                m("li", {class:"nav-item"} ,[
                    m("a", {
                        class:"link",
                        href: user.current.href,
                        target : "_blank",
                        onclick : function(e){
                            UserNav.close()
                        }
                    },[
                        m("span", {class:"ico-container"}, [
                            m('i', {class:"fas fa-eye"})
                        ]),
                        m("span", {class:"text-nav-container"},[
                            m("span", {class:"text-nav"}, i18n.cur.left_nav_see_my_olyrix)
                        ])
                    ])
                ]),
                m("li", {class:"nav-item"} ,[
                    m("a", {
                        class:"link",
                        href:"#",
                        onclick : function(e){
                            e.preventDefault()
                            // show the onboard
                        }
                    },[
                        m("span", {class:"ico-container"}, [
                            m('i', {class:"fas fa-question"})
                        ]),
                        m("span", {class:"text-nav-container"},[
                            m("span", {class:"text-nav"}, i18n.cur.top_nav_help)
                        ])
                    ])
                ]),
                m("li", {class:"nav-item logout-item"} ,[
                    m("a", {
                        class:"link",
                        href:"/login",
                        onclick : function(e){
                            e.preventDefault()
                            // here logout the user
                            user.logOut(auth.getJWT()).then(function(res){
                                auth.removeJWT()
                                UserNav.close()
                                m.route.set('/login')

                                setTimeout(() => {user.unset();console.log("user is unset")}, 250)
                            })

                        }
                    },[
                        m("span", {class:"ico-container"}, [
                            m('i', {class:"fas fa-sign-out-alt"})
                        ]),
                        m("span", {class:"text-nav-container"},[
                            m("span", {class:"text-nav"}, i18n.cur.top_nav_logout)
                        ])
                    ])
                ])
            ])
        ])
    }
}
UserNav.background = {
    view : function(ctrl){
        return m("div", {
            id: 'usernav-background',
            class: 'fadeIn',
            onclick : function(){
                eventHelper.userNav.set(false)
            }
        })
    }
}

module.exports = UserNav


var m = require('mithril')
var utils = require('../helpers/Utils')
var auth = require('../services/Auth')
var user = require('../models/User')
var global = require('../Global')


var model = {
    _formStates : {// state -1 = initial / state 0 = error / state 1 = valid
        email : {state : -1},
    },
    current : {},
    backendSuccessMessages : [],
    backendErrorsMessages : [],

    backendSuccessMessages2 : [],
    backendErrorsMessages2 : [],

    getBackend1: function(){
        var SuccessMessages = model.backendSuccessMessages
        var ErrorsMessages = model.backendErrorsMessages
        return model.getBackendResponse(SuccessMessages,ErrorsMessages)
    },
    getBackend2: function(){
        var SuccessMessages = model.backendSuccessMessages2
        var ErrorsMessages = model.backendErrorsMessages2
        return model.getBackendResponse(SuccessMessages,ErrorsMessages)
    },
    getBackendResponse : function(SuccessMessages, ErrorsMessages){
        var successMessageExist = Boolean(typeof SuccessMessages !== 'undefined' && SuccessMessages.length > 0)
        var errorsMessageExist = Boolean(typeof ErrorsMessages !== 'undefined' && ErrorsMessages.length > 0)
        var successView;
        var errorsView;
        if(successMessageExist){
            successView = model.getSuccessMessagesView(SuccessMessages)
        }
        if(errorsMessageExist){
            errorsView = model.getErrorsMessagesView(ErrorsMessages)
        }
        if(successMessageExist || errorsMessageExist){
            return m("div", {class:"response-message"}, [
                successMessageExist ? successView : '',
                errorsMessageExist ? errorsView : ''
            ])
        }else{
            return null
        }
    },
    postAction: function(){
        return new Promise(
            function(resolve, reject) {
                if(model.checkAllInput() && model.current.email !== user.current.user.email){
                    m.request({
                        method: 'POST',
                        url: "api/users/" + user.current.user.id + "/email",
                        body: model.current,
                        headers : {
                            'Content-Type' : 'application/json',
                            Authorization: "Bearer " + auth.getJWT(),
                            'X-CSRF-TOKEN': utils.getCsrf()
                        }
                    })
                    .then(function(res) {
                        model.setBackendSuccessMessages(["Nous venons de vous renvoyer l'email !"])
                        var signupState = model.getFormStates()
                        signupState.email.state = -1
                        model.setFormStates(signupState)
                        resolve()
                    })
                    .catch(function(err) {
                        //model.setBackendErrorsMessages(["Une erreur c'est produite lors de la modification de votre email"])
                        var signupState = model.getFormStates()
                        signupState.email.state = -1
                        model.setFormStates(signupState)
                        reject()
                    })
                }else{
                    reject()
                }
        })
    },
    needANewEmail: function(){
        return new Promise(
            function(resolve, reject) {
                m.request({
                    method: 'GET',
                    url: "api/users/" + user.current.user.id + "/resendActivationMail",
                    body: model.current,
                    headers : {
                        'Content-Type' : 'application/json',
                        Authorization: "Bearer " + auth.getJWT(),
                        'X-CSRF-TOKEN': utils.getCsrf()
                    },
                })
                .then(function(res) {
                    model.setBackendSuccessMessages2(["Nous venons de vous renvoyer l'email !"])
                    resolve(res)
                })
                .catch(function(err) {
                    reject(err)
                    console.log("error on resend email")
                })
            })
    },
    setBackendSuccessMessages : function(messages){
        model.backendErrorsMessages = []
        model.backendSuccessMessages = messages

    },
    setBackendErrorsMessages : function(messages){
        model.backendSuccessMessages = []
        model.backendErrorsMessages = messages
    },
    setBackendSuccessMessages2 : function(messages){
        model.backendErrorsMessages2 = []
        model.backendSuccessMessages2 = messages

    },
    setBackendErrorsMessages2 : function(messages){
        model.backendSuccessMessages2 = []
        model.backendErrorsMessages2 = messages
    },
    getSuccessMessagesView(messages){
        return m('div', [
            messages.map(function(x){
                return m("span", {class:"response-text success"}, x)
            })
        ])
    },
    getErrorsMessagesView(messages){
        return m('div', [
            messages.map(function(x){
                return m("span", {class:"response-text error"}, x)
            })
        ])
    },
    checkAllInput : function(){
        model.check.email(document.getElementById('input_email').value)
        return !model.haveError()
    },
    haveError: function(){
        var errorUccured = true

        if(model._formStates.email.state === 1){
            errorUccured = false
        }

        return errorUccured
    },
    setFormStates : function (newFormStates){
        model._formStates = newFormStates
    },
    getFormStates : function (){
        return model._formStates
    },
    getInputErrorMessage : function(stringEnum){
        var message = model.getInputStates(stringEnum).message
        if (typeof(message) === 'string'){
            return m("span", {class : "error-message"}, message)
        }
    },
    getInputStatesIndicator : function(stringEnum){
        var state = model.getInputStates(stringEnum).state
        if(state === 0){
            return m("span", {class : "input-indicator error"})
        }else if(state === 1){
            return m("span", {class : "input-indicator valid"})
        }
    },
    getInputStates : function(stringEnum){
        var o
        switch (stringEnum) {
            case 'email':
                o = model._formStates.email
            break;
            default:
                o = null
        }
        return o
    },
    check : {
        email : function(e){
            var signupState = model.getFormStates()
            if(model.check.validateLengthCarac(e, 5, 255)){// min-mac carac
                signupState.email.state = 0
                signupState.email.message = i18n.cur.error_count_charac.formatUnicorn({MinCharac : "5", MaxCharac : "255"})
            }else if(model.check.validateEmail(e)){
                signupState.email.state = 0
                signupState.email.message = i18n.cur.error_email_not_valid
            }else if(e === user.current.user.email){
                signupState.email.state = -1
                signupState.email.message = null
            }else{
                signupState.email.message = null
                model.current.email = e
                signupState.email.state = 1
            }
            model.setFormStates(signupState)
        },
        validateEmail(e) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return !(re.test(String(e).toLowerCase()))
        },
        validateLengthCarac: function(e, minCarac, maxCarac){
            var length = e.length
            return !(length >= minCarac && length <= maxCarac)
        },
    }
}

module.exports = model